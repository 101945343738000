import React from "react";


const PrivacyPolicyArea = () => {
    return (
        <>
            <section className="privacy-policy">	
                <div className="container">
                    <div className="privacy-policy-box">
                        <h1>Privacy Statement</h1>
                        <p>This Privacy Statement focuses on the collection, usage, and management of personal data when a user visits our website. We are dedicated to protecting the privacy of those who engage with our customers and co-workers.</p>
                        
                        <p>When used in this Privacy Notice, <strong>“we”</strong>, <strong>“us”</strong> and <strong>“our”</strong> refer to EzzEEx Info Tech Pvt. Ltd. Data safety is a top concern for EzzEEx Info Tech Pvt. Ltd., which has historically prioritized this issue. We are thus committed to being transparent about how we use the personal data we gather. The declaration describes how we treat the personal data we get and store about you through this website.</p>
                        
                        <h2>Responsible Authority</h2>
                        <p>Our Company is a registered business with the name EZZEEX INFOTECH PVT. LTD.  and The Corporate Identity Number of the company is PTC073414.</p>
                        
                        <h2>Consumer data privacy policy</h2>
                        <p>Information that should be provided by the Customer:</p>
                        
                        <h3>A. Information we collect</h3>
                        <ul>
                            <li>Transaction Details</li>
                            <li>Internet Protocol (IP) Address</li>
                            <li>For a full uniform resource locator (URL), click Information (URL).</li>
                        </ul>
                        
                        <h3>B. Information received from other sources</h3>
                        <ul>
                            <li>If you use one of our other websites or a service we provide, we may gather information about you. Obtaining details on a third party engaged in your project is also a possibility.</li>
                            <li>Details about social networking sites.</li>
                            <li>If you have used a social network account to sign in to our service (such as Facebook or Google), you will get the necessary authentication details.</li>
                            <li>You have access to the particular data you supplied to social networking sites, including your name, profile picture, and email address.</li>
                        </ul>
                        
                        <h3>C. Children's data</h3>
                        <ul>
                            <li>Our products and services are intended for adults over the age of 18, not <strong>minors.</strong></li>
                            <li>Sensitive data</li>
                            <li>When confirming identification, a small quantity of private information with biometric data is gathered.</li>
                        </ul>
                        
                        <h3>D.	Protecting your personal information</h3>
                        <ul>
                            <li>Services provided by EZZEEX INFO TECH PVT. LTD. is encrypted by Asymmetric encryption.</li>
                            <li>Our technical security team monitors servers for unexpected or malicious activity.</li>
                            <li>Our Company is educating staff to maintain the confidentiality of personal information and the privacy of customers regularly.</li>
                        </ul>
                        
                        <h3>E.	How we use your data</h3>
                        <ul>
                            <li>To comply with our contractual obligations.</li>
                            <li>To notify about changes in our service.</li>
                            <li>To improve our effectiveness and service guarantee.</li>
                            <li>To provide information about other products and services.</li>
                        </ul>
                        
                        <h3>F.	Disclosure of your information</h3>
                        <ul>
                            <li><strong>Our Affiliates:</strong> For administrative, business-related, marketing, and promotional purposes, we may disclose your information to our affiliates and subsidiaries.</li>
                            <li><strong>Third-Party Service Providers:</strong> We may disclose your information to third-party service providers who assist us in operating our company and the website, administering activities on our behalf, or who perform business, professional, or technical support functions for us. This may include the extent necessary or desirable to complete your transaction, fulfill or ship your order, or do other related tasks.</li>
                            <li><strong>Selected Advertisers:</strong> We occasionally collaborate with advertisers or other third parties to display ads that are relevant to your interests or to gather information for conversion monitoring (that is, what actions you take after you click on an ad). </li>
                            <li><strong>Legal Obligations; Safety :</strong> As authorized by law or as otherwise required by law, we may access and disclose your information to answer subpoenas, legal proceedings, government requests, and investigations, or in conjunction with an investigation on problems relating to public safety. To maintain the safety of our website, servers, network systems, and databases, we could release personal information. Additionally, if we feel there has been a breach of our Terms of Use, any other agreement or contract relating to our services, or the rights of any third party, we may release your information as appropriate.</li>
                            <li><strong>Sale or Transfer of Business or Assets:</strong> During the regular course of our business, we may sell or buy assets. Information we have gathered about you could be given to another organization if it buys us or any of our assets. Additionally, if we or another party file for bankruptcy or reorganization, such data may be viewed as one of our assets and sold or transferred to other parties. If such a sale or transfer takes place, we will make a good-faith effort to ensure that the new owner uses personal data obtained via our services in a way that complies with this Privacy Policy.</li>
                            <li><strong>Aggregate or Anonymous Non-Personal Information:</strong> For their marketing or other purposes, we may also share aggregate, anonymous, or de-identified non-personal information with third parties.</li>				
                        </ul>
                        
                        <h3>G.	We may share personal information with third parties</h3>
                        <ul>
                            <li>To evaluate financial and insurance risk</li>
                            <li>Debt failure and citation, guarantee, judicial instruction, or Law</li>
                            <li>When selling or buying business or assets</li>
                            <li>To our business partners, suppliers, and subcontractors</li>
                        </ul>
                        
                        <h3>H.	Sharing and storing your data</h3>
                        <ul>
                            <li>The economy of Europe can send it to the outside of the field and save it ("Eee"). You accept this transfer, save and process your data by sending it. </li>
                            <li>Take all the rational steps to ensure that your information is safe and treated by this policy. </li>
                            <li>To protect your data, we make sure that both ourselves and our scholars are followed appropriate technical, physical, and organizational safety standards. These companies also ensure that they receive data outside the EEA and have adequate contractual protection (for example, BCR, standard contract clause).</li>
                        </ul>
                        
                        <h3>I.	Utilization of credit card data</h3>
                        <p>Your credit card information refers to the financial details, such as your credit card number, that you provided to us when you filled out the order form or through our website. For your protection, we do not keep your credit card information in any of our databases or on our website.</p>
                        
                        <h3>J.	Profiling and automated decision making</h3>
                        <p>Some of your data, such as your residence and commerce history, are used to customize our service and will be used to discuss your needs perhaps. We can adopt the automatic decision (ADM) to improve your experience or combat financial crimes.</p>
                        
                        <h2>Data protection and retention</h2>
                        <p>To protect your personal information, EzzEEx Info Tech Pvt. Ltd. implements reasonable and necessary security methods and processes, such as administrative, physical security, and technology controls. EzzEEx Info Tech Pvt. Ltd. is bound by law to maintain personal and trading data reliably after account closure as regulated in the financial institution. We only have to access or handle it if necessary, only if necessary.</p>
                        
                        <h2>Changes in Privacy Policy</h2>
                        <p>Since the ultimate goal of EzzEEx Info Tech Pvt. Ltd. is to satisfy every single client, a revision policy has been carefully created. The terms of the revision policy are listed below and are subject to modification at any time.</p>
                        
                        <p>To keep up-to-date and comply with dynamic rules and laws, best practices, and changes in our personal information processing procedures, we may change or make major changes at any time without notice. Post an updated version of our privacy policy on the website. Therefore, you should check it regularly after regular intervals to stay informed about such changes.</p>
                        
                        <p>Customers have the right to ask for revisions if the finished product falls short of their expectations. We provide free revisions indefinitely. However, a few circumstances will be covered in further depth below.</p>
                        
                        <p>A client may reference a revision in two different ways in the work they have already received.</p>
                        
                        <p>The first approach does not include review marking. The customer can send the reserved document back to EzzEEx Info Tech Pvt. Ltd. and explain the revision through email. The assigned writer will make the necessary changes and reply with the updated text.</p>
                        
                        <p>The correction can be noted on the actual document in the second approach. The client can offer feedback on the areas that require revision.</p>
                        
                        <p>To save both your time and ours, we ask that you provide us with precise and concise instructions when placing the order. This will stop any misunderstandings or miscommunications between the two parties.</p>
                        
                    </div>
                </div>
            </section>
        </>
    )
}   
export default PrivacyPolicyArea;  