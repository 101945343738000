import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';




const CareerArea = () => {

	const [modalShow1, setmodalShow1] = useState(false);
  const [modalShow2, setmodalShow2] = useState(false);
  const [modalShow3, setmodalShow3] = useState(false);
  const [modalShow4, setmodalShow4] = useState(false);
  const [modalShow5, setmodalShow5] = useState(false);



	const [selectedFile, setSelectedFile] = useState(null);
    function handleFileChange(event) {
    setSelectedFile(event.target.files[0]);
  	}

    return (
        <>
          <section className="banner-area">	
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-content">
                            <div className="heading-label">Current Openings</div>
                            <h1>Career @EZZEEX</h1>
                            <p>We are Jaipur based content development company and offer one stop solution to meet every client’s needs. However, much more needs to be achieved as we want to do a lot more and for this purpose, we require far more skilled heads and hands. We prefer to hire people who are willing show their creativity, skills and ability to meet our clients’ needs. We have great working environment with long-term career.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-img-area text-align-right">
                            <img src="images/hiring-banner.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-box industry-sec mt100 pt-80">	
            <div className="container">
                <div className="custom-heading">
                    <div className="custom-heading-text">Open positions</div>
                </div>
                <div className="job-widget-area">
                    <div className="job-widget-inner">
                        <div className="job-card">
                            <div className="job-card-body">
                                <Button type="button" className="JCB-btn" onClick={() => setmodalShow1(true)}>
                                    <div className="JCB-heading">Digital Marketing Executive- SEO</div>
                                    <div className="JCB-footer">
                                        <div className="JCB-footer-left">
                                            <img src="images/experience-icon.svg" alt="" />
                                            <span>1-3</span>
                                        </div>
                                        <div className="JCB-footer-left">
                                            <img src="images/location-icon.svg" alt="" />
                                            <span>Jaipur</span>
                                        </div>
                                    </div>
                                </Button>

                                <Modal size="lg" show={modalShow1} onHide={() => setmodalShow1(false)}
                                    aria-labelledby="example-modal-sizes-title-sm" dialogClassName="hiringPopup">                                    
                                    <Modal.Body className="hiring-modal-body">
                                    <div className="hiring-modal-area">
                                        <div className="hiring-modal-head">
                                            <h3>Digital Marketing Executive – SEO</h3>
                                            <div className="HMH-info"><span>Full-Time</span> <span>(Jaipur)</span> <span>1-3 Experience</span></div>
                                            <Modal.Header closeButton></Modal.Header>
                                        </div>
                                        
                                        <div className="job-info-area">
                                            <h4>Digital Marketing Executive Responsibilities:</h4>
                                            <ul>
                                                <li>Formulating strategies to develop long-lasting digital connection with clients</li>
                                                <li>Planning concepts for maintaining ongoing company presence on social media platform</li>
                                                <li>Regular monitoring of company presence on digital platform</li>
                                                <li>Increasing brand awareness through launching optimized online advertisements</li>
                                                <li>Training and management of the team</li>
                                                <li>Measuring Company ROI and KPIs</li>
                                                <li>Preparing digital marketing budget</li>
                                                <li>Management and improvement of online content while considering on SEO and Google Analytics</li>
                                                <li>Monitoring the competitor strategies and design effective strategies for company to stand distinguish among the competitors</li>
                                            </ul>
                                            <div className="job-apply-form">
                                                <form action="">	
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/user-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Full Name*" /></div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/email-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="email" placeholder="Email Address*" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/call-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                 <Form.Control type="text" placeholder="Mobile Number" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/experience-form-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Experience (in years)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                    <Form.Control type="text" placeholder="Current Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Expected Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box FQA-upload">
                                                                <div className="upload-btn-wrapper">
                                                                <button className="btn"><img src="images/upload-icon.svg" alt="" /> Upload Resume/CV</button>
                                                                <Form.Control type="file" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-footer form-apply-btn">
                                                                <div className="FQA-footer-btn">
                                                                    <a href="" className="custom-btn">Apply Now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                </form>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            
                            <div className="job-card-body">
                                <Button type="button" className="JCB-btn" onClick={() => setmodalShow2(true)}>
                                    <div className="JCB-heading">Graphic Designer</div>
                                    <div className="JCB-footer">
                                        <div className="JCB-footer-left">
                                            <img src="images/experience-icon.svg" alt="" />
                                            <span>10+ years</span>
                                        </div>
                                        <div className="JCB-footer-left">
                                            <img src="images/location-icon.svg" alt="" />
                                            <span>Jaipur</span>
                                        </div>
                                    </div>
                                </Button>

                                <Modal size="lg" show={modalShow2} onHide={() => setmodalShow2(false)}
                                    aria-labelledby="example-modal-sizes-title-sm" dialogClassName="hiringPopup">                                    
                                    <Modal.Body className="hiring-modal-body">
                                    <div className="hiring-modal-area">
                                        <div className="hiring-modal-head">
                                            <h3>Graphic Designer</h3>
                                            <div className="HMH-info"><span>Full-Time</span> <span>(Jaipur)</span> <span>10+ Experience</span></div>
                                            <Modal.Header closeButton></Modal.Header>
                                        </div>
                                        
                                        <div className="job-info-area">
                                            <h4>Graphic Designer Responsibilities:</h4>
                                            <ul>
                                                <li>Conceptualizing visuals through utilizing relevant information and materials</li>
                                                <li>Preparing rough drafts and presenting ideas</li>
                                                <li>Developing images and layouts with the help of design software</li>
                                                <li>Utilization of different media platform to test graphics</li>
                                                <li>Design Illustrating through designing examples of art arrangement, type size and style</li>
                                                <li>Design Mobile and web app designs with latest trends and according to respective industry domain. Designing social media banners as per events or occasions</li>
                                                <li>Well-versed with the use of different software and tools: Adobe XD, Photoshop, Zeplin, Figma etc.</li>
                                            </ul>
                                            <div className="job-apply-form">
                                                <form action="">	
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/user-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Full Name*" /></div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/email-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="email" placeholder="Email Address*" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/call-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                 <Form.Control type="text" placeholder="Mobile Number" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/experience-form-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Experience (in years)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                    <Form.Control type="text" placeholder="Current Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Expected Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box FQA-upload">
                                                                <div className="upload-btn-wrapper">
                                                                <button className="btn"><img src="images/upload-icon.svg" alt="" /> Upload Resume/CV</button>
                                                                <Form.Control type="file" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-footer form-apply-btn">
                                                                <div className="FQA-footer-btn">
                                                                    <a href="" className="custom-btn">Apply Now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                </form>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            
                            <div className="job-card-body">
                                <button type="button" className="JCB-btn" onClick={() => setmodalShow3(true)}>
                                    <div className="JCB-heading">Business Development Executive</div>
                                    <div className="JCB-footer">
                                        <div className="JCB-footer-left">
                                            <img src="images/experience-icon.svg" alt="" />
                                            <span>1-5</span>
                                        </div>
                                        <div className="JCB-footer-left">
                                            <img src="images/location-icon.svg" alt="" />
                                            <span>Jaipur</span>
                                        </div>
                                    </div>
                                </button>

                                <Modal size="lg" show={modalShow3} onHide={() => setmodalShow3(false)}
                                    aria-labelledby="example-modal-sizes-title-sm" dialogClassName="hiringPopup">                                    
                                    <Modal.Body className="hiring-modal-body">
                                    <div className="hiring-modal-area">
                                        <div className="hiring-modal-head">
                                            <h3>Business Development Executive</h3>
                                            <div className="HMH-info"><span>Full-Time</span> <span>(Jaipur)</span> <span>1-5 Experience</span></div>
                                            <Modal.Header closeButton></Modal.Header>
                                        </div>
                                        
                                        <div className="job-info-area">
                                            <h4>Business Development Executive Responsibilities:</h4>
                                            <ul>
                                                <li>Increasing company sales through sourcing new clients (direct contact, word-of-mouth, collaborating with the marketing department)</li>
                                                <li>Influencing and suggesting existing clients to avail upgrades or added servings</li>
                                                <li>Familiarizing the customers with the complete services offered by the company</li>
                                                <li>Attending networking activities to increase connection with prospective clients</li>
                                                <li>Negotiating with clients to offer them services in best market price</li>
                                                <li>Developing technical and social skills in the staff members to enhance company sales</li>
                                                <li>Improving the company service offerings according to the clients' feedback and implementing necessarychanges</li>
                                            </ul>
                                            <div className="job-apply-form">
                                                <form action="">	
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/user-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Full Name*" /></div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/email-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="email" placeholder="Email Address*" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/call-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                 <Form.Control type="text" placeholder="Mobile Number" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/experience-form-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Experience (in years)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                    <Form.Control type="text" placeholder="Current Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Expected Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box FQA-upload">
                                                                <div className="upload-btn-wrapper">
                                                                <button className="btn"><img src="images/upload-icon.svg" alt="" /> Upload Resume/CV</button>
                                                                <Form.Control type="file" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-footer form-apply-btn">
                                                                <div className="FQA-footer-btn">
                                                                    <a href="" className="custom-btn">Apply Now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                </form>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            
                            <div className="job-card-body">
                                <button type="button" className="JCB-btn" onClick={() => setmodalShow4(true)}>
                                    <div className="JCB-heading">Content Writer</div>
                                    <div className="JCB-footer">
                                        <div className="JCB-footer-left">
                                            <img src="images/experience-icon.svg" alt="" />
                                            <span>2-7</span>
                                        </div>
                                        <div className="JCB-footer-left">
                                            <img src="images/location-icon.svg" alt="" />
                                            <span>Jaipur</span>
                                        </div>
                                    </div>
                                </button>

                                <Modal size="lg" show={modalShow4} onHide={() => setmodalShow4(false)}
                                    aria-labelledby="example-modal-sizes-title-sm" dialogClassName="hiringPopup">                                    
                                    <Modal.Body className="hiring-modal-body">
                                    <div className="hiring-modal-area">
                                        <div className="hiring-modal-head">
                                            <h3>Content Writer</h3>
                                            <div className="HMH-info"><span>Full-Time</span> <span>(Jaipur)</span> <span>2-7 Experience</span></div>
                                            <Modal.Header closeButton></Modal.Header>
                                        </div>
                                        
                                        <div className="job-info-area">
                                            <h4>Content Writer Responsibilities:</h4>
                                            <ul>
                                                <li>Executing in-depth research on the topics related to industry to create original content</li>
                                                <li>Writing content for company website, blogs, articles, social media, product descriptions, and many more</li>
                                                <li>Supporting marketing team in content development for advertising campaigns</li>
                                                <li>Content proofreading to remove errors and inconsistencies</li>
                                                <li>Editing and writing the existing content in a lucrative manner</li>
                                                <li>Implementing keyword research and using SEO best practices to enhance traffic on company website</li>
                                                <li>Creating persuasive headlines and body to capture the attention of target market audience</li>
                                                <li>Conducting research on identifying customers’ needs and suggesting the services to address gaps in the current content</li>
                                            </ul>
                                            <div className="job-apply-form">
                                                <form action="">	
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/user-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Full Name*" /></div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/email-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="email" placeholder="Email Address*" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/call-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                 <Form.Control type="text" placeholder="Mobile Number" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/experience-form-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Experience (in years)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                    <Form.Control type="text" placeholder="Current Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Expected Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box FQA-upload">
                                                                <div className="upload-btn-wrapper">
                                                                <button className="btn"><img src="images/upload-icon.svg" alt="" /> Upload Resume/CV</button>
                                                                <Form.Control type="file" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-footer form-apply-btn">
                                                                <div className="FQA-footer-btn">
                                                                    <a href="" className="custom-btn">Apply Now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                </form>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            
                            <div className="job-card-body">
                                <button type="button" className="JCB-btn" onClick={() => setmodalShow5(true)}>
                                    <div className="JCB-heading">MIS Executive</div>
                                    <div className="JCB-footer">
                                        <div className="JCB-footer-left">
                                            <img src="images/experience-icon.svg" alt="" />
                                            <span>2-10</span>
                                        </div>
                                        <div className="JCB-footer-left">
                                            <img src="images/location-icon.svg" alt="" />
                                            <span>Jaipur</span>
                                        </div>
                                    </div>
                                </button>

                                <Modal size="lg" show={modalShow5} onHide={() => setmodalShow5(false)}
                                    aria-labelledby="example-modal-sizes-title-sm" dialogClassName="hiringPopup">                                    
                                    <Modal.Body className="hiring-modal-body">
                                    <div className="hiring-modal-area">
                                        <div className="hiring-modal-head">
                                            <h3>MIS Executive</h3>
                                            <div className="HMH-info"><span>Full-Time</span> <span>(Jaipur)</span> <span>2-10 Experience</span></div>
                                            <Modal.Header closeButton></Modal.Header>
                                        </div>
                                        
                                        <div className="job-info-area">
                                            <h4>MIS Executive Responsibilities:</h4>
                                            <ul>
                                                <li>Designing, monitoring and troubleshooting IT systems</li>
                                                <li>Interpretation of briefs to develop IT systems while meeting all specifications and cost requirements</li>
                                                <li>Developing job descriptions for new job positions and helping with interview and recruitment processes</li>
                                                <li>Software management</li>
                                                <li>Supervising digital security while time to time updating all anti-viruses and firewalls</li>
                                                <li>Direct the development of websites and ensuring user data protection</li>
                                                <li>Analyze existing operations, protocols and processes and accordingly implementing improvement in plans</li>
                                            </ul>
                                            <div className="job-apply-form">
                                                <form action="">	
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/user-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Full Name*" /></div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/email-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="email" placeholder="Email Address*" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                    <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/call-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                 <Form.Control type="text" placeholder="Mobile Number" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/experience-form-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Experience (in years)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                    <Form.Control type="text" placeholder="Current Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box">
                                                                <div className="FQAFB-form-icon"><img src="images/dollar-icon.svg" alt="" /></div>
                                                                <div className="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Expected Salary (in LPA)" /></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-form-box FQA-upload">
                                                                <div className="upload-btn-wrapper">
                                                                <button className="btn"><img src="images/upload-icon.svg" alt="" /> Upload Resume/CV</button>
                                                                <Form.Control type="file" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="FQA-footer form-apply-btn">
                                                                <div className="FQA-footer-btn">
                                                                    <a href="" className="custom-btn">Apply Now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                </form>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                        
                    
                    </div>
                </div>
            </div>
        </section>
            


        </>
    )
}   
export default CareerArea;  