import React, { useEffect } from "react";
import {NavLink} from 'react-router-dom';



import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'






const AboutUsArea = () => {
    const handleClick = () => {
        window.scrollTo(0,0);
    }

    useEffect(() => {
        function handleMouseMove(event) {
          document.querySelectorAll(".shape-1").forEach((shift) => {
            const position = shift.getAttribute("value");
            const x = (window.innerWidth - event.pageX * position) / 90;
            const y = (window.innerHeight - event.pageY * position) / 90;
    
            shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
          });
        }
    
        document.addEventListener("mousemove", handleMouseMove);
    
        return () => {
          document.removeEventListener("mousemove", handleMouseMove);
        };
      }, []);
      


    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Our Core Values</div>
                                <h1>EZZEEX Experts Lets Success Be Your Luck forever!</h1>
                                <p>EZZEEX is web & app development Company that will offer solution to your business and will give it new heights.</p>
                                
                                <div className="banner-btn-area">
                                    <NavLink to="/contact" onClick={handleClick} className="custom-btn btn-primary">Contact Us</NavLink>
                                    <a href="#Free_quote" className="arrow-btn"><img src="images/btn-arrow.svg" alt="" /> Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area">
                                <img src="images/about-page-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />

            <section className="whoWe-are-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left">
                                <h2>Who Are We?</h2>
                                <h3>We are a digital and branding Company that uses creative strategy and great design to catalyze the driving force in your business.</h3>
                                <p>EZZEEX has a team of brilliant web and app developers who have potential to deliver on time projects with top-notch quality standards. We serve global brands and offer them unique designs with superior digital products.</p>
                                <div className="WWA-left-highlight">
                                    <div className="WWAL-highlight-box">
                                        <div className="WWAL-highlight-img">
                                            <img src="images/our-mission-icon.svg" alt="" />
                                        </div>
                                        <div className="WWAL-highlight-cont">
                                            <h4>Our Mission</h4>
                                            <p>To offer seamless user experience to our clients across all digital platforms and devices</p>
                                        </div>
                                    </div>

                                    <div className="WWAL-highlight-box">
                                        <div className="WWAL-highlight-img">
                                            <img src="images/our-value-icon.svg" alt="" />
                                        </div>
                                        <div className="WWAL-highlight-cont">
                                            <h4>Our Values</h4>
                                            <p>Our value is to offer high-end web & app development services with integrity, fairness, accountability and team work.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right multiImage-column">
                                <img className="WWA-right-img" src="images/who-are-we1.jpg" alt="" />
                                <img className="WWA-right-img2" src="images/who-are-we2.jpg" alt="" />
                                <div className="shape-group">
                                    <ul>
                                        <li className="shape-1" value="3"><img src="images/round-dots-black.svg" alt="" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what-do-area">	
                <div className="container">
                    <div className="custom-heading">
                        <label>Our Services</label>
                        <div className="custom-heading-text">We strive to build your Digital Presence</div>
                        <p>We offer you the facility of Website and App localization with complete application design and management services. It includes entire development process from ideation to concept development to project delivery and ongoing support.</p>
                    </div>
                    
                    <div className="what-do-inner">
                        <div className="what-do-box">
                            <div className="what-doBox-img"><img src="images/what-we-do-icon1.svg" alt="" /></div>
                            <h3>Highly Experienced Staff</h3>
                            <p>Our highly experienced staff members are dedicated to offer advanced mobile app solutions according to the contemporary Digital Era.</p>
                        </div>
                        
                        <div className="what-do-box">
                            <div className="what-doBox-img"><img src="images/what-we-do-icon2.svg" alt="" /></div>
                            <h3>Innovative Designs</h3>
                            <p>EZZEEX has the potential to offer innovative designs to meet every need whether it is a consumer oriented app or transformative enterprise-class solution.</p>
                        </div>
                        
                        <div className="what-do-box">
                            <div className="what-doBox-img"><img src="images/what-we-do-icon3.svg" alt="" /></div>
                            <h3>Customized Strategies</h3>
                            <p>Our customized services are dedicated to offer feature-rich native iOS / Android, cross-platform or Progressive Web Apps to create the design more expressive.</p>
                        </div>
                                                    
                        
                        <div className="what-do-box">
                            <div className="what-doBox-img"><img src="images/what-we-do-icon4.svg" alt="" /></div>
                            <h3>Result-Driven Solutions</h3>
                            <p>We have gained enormous experience in offering digital result-driven solutions from startups to enterprises across multiple industries.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-work-sec">
                <div className="container">
                    <div className="custom-heading">
                        <label>How We Work?</label>
                        <div className="custom-heading-text">EZZEEX is providing the Best Quality Services</div>
                        <p>We offer the facility of Native, Hybrid and Progressive web & app development. Use of modern technologies enables us to establish the presence of our clients on almost every latest device.</p>
                    </div>
                    
                    <div className="how-work-area">
                        <div className="how-work-row">
                            <div className="how-work-box">
                                <div className="HWB-work-img">
                                    <img src="images/howWork-analysis.svg" alt="" />
                                </div>
                                <h3>Analysis</h3>
                                <p>Our team first analyse your personal requirement.</p>
                                <div className="HWB-arrow"><img src="images/howWork-arrow1.svg" alt="" /></div>
                            </div>

                            <div className="how-work-box">
                                <div className="HWB-work-img">
                                    <img src="images/howWork-design.svg" alt="" />
                                </div>
                                <h3>Design</h3>
                                <p>According to the requirement, we plan to develop unique and customized app for you.</p>
                                <div className="HWB-arrow"><img src="images/howWork-arrow2.svg" alt="" /></div>
                            </div>

                            <div className="how-work-box">
                                <div className="HWB-work-img">
                                    <img src="images/howWork-development.svg" alt="" />
                                </div>
                                <h3>Development</h3>
                                <p>Under development stage, we design the app that can best meet your need.</p>
                                <div className="HWB-arrow HWB-arrowBottom"><img src="images/howWork-arrow3.svg" alt="" /></div>
                            </div>
                        </div>
                        
                        <div className="how-work-row HWR-two">
                            <div className="how-work-box">
                                <div className="HWB-work-img">
                                    <img src="images/howWork-testing.svg" alt="" />
                                </div>
                                <h3>Testing</h3>
                                <p>We provide you facility to test the app and let us know about required changes.</p>
                                <div className="HWB-arrow"><img src="images/howWork-arrow4.svg" alt="" /></div>
                            </div>

                            <div className="how-work-box">
                                <div className="HWB-work-img">
                                    <img src="images/howWork-launch.svg" alt="" />
                                </div>
                                <h3>Lanuch</h3>
                                <p>After amending all the changes, we launch the app.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />


        </>
    )
}   
export default AboutUsArea;  