import React, { useEffect } from "react";
import {NavLink} from 'react-router-dom';


import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'

import { FiCheckCircle } from "react-icons/fi";



const IndustryArea = () => {
    const handleClick = () => {
        window.scrollTo(0,0);
    }

    useEffect(() => {
        function handleMouseMove(event) {
          document.querySelectorAll(".shape-1").forEach((shift) => {
            const position = shift.getAttribute("value");
            const x = (window.innerWidth - event.pageX * position) / 90;
            const y = (window.innerHeight - event.pageY * position) / 90;
    
            shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
          });
        }
    
        document.addEventListener("mousemove", handleMouseMove);
    
        return () => {
          document.removeEventListener("mousemove", handleMouseMove);
        };
      }, []);
      


    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Our Versatility</div>
                                <h1>Industry Specific Solutions</h1>
                                <p>EZZEEX is complete IT Solution service provider Indian company. We have experienced team members who are expert in their field and are capable to offer industry specific solutions. EZZEEX is focused towards delivering robust, scalable, and reliable solutions to the clients at the global platform.</p>
                                
                                <div className="banner-btn-area">
                                    <NavLink to="/contact" onClick={handleClick} className="custom-btn btn-primary">Contact Us</NavLink>
                                    <a href="#Free_quote" className="arrow-btn"><img src="images/btn-arrow.svg" alt="" /> Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area">
                                <img src="images/industry-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />

            <section className="text-area">	
                <div className="container">
                    <div className="custom-heading">
                        <label>Divided by Industries</label>
                        <div className="custom-heading-text">Industries we serve</div>
                        <p>EZZEEX is capable to offer top-notch mobile app development and website development facility to different industries according to their specific industry and personalized business needs. We have dedicated experts for front-end, back-end and UI/UX designs. Our content creators are focused towards offering the content that can assist you to attract your target market audience. We are highly focused to provide you IT solutions that can increase footfall on your mobile app and website and can enable you to convert leads into sales. </p>
                    </div>
                </div>
            </section>

            <section className="bg-box industry-sec mt100">	
                <div className="container">
                    <div className="DIS-inner-area">
                        <div className="DIS-inner-box" id="automotive_sec">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/automotive-img.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Automotive Industry</label>
                                            <div className="custom-heading-text">Automotive</div>
                                        </div>
                                        <p>We deliver IT solutions, digital interactive, internships, and many more services that can help in unlocking the true potential of your automotive industry business. We are committed to creating quality automotive apps for both iOS and Android to help the industry evolve with modern digital trends and take your business to the next level.</p>
                                        
                                        <p>Our services could be valuable to a wide range of businesses and individuals in the automotive industry, helping them to make more informed decisions and manage their assets more effectively.</p>
                                        
                                        <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Scalable, cost-effective, and value intensive solution </li>
                                            <li><FiCheckCircle className="yellowCheck" /> SaaS-based platforms</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Informed business decisions</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Lucrative content</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="energy_utilities_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Energy & Utilities</label>
                                            <div className="custom-heading-text">Energy & Utilities</div>
                                            <p>Our team of experts develops and delivers innovative digital solutions to Energy & Utilities industry that helps them to create new opportunities for their customers. We will help you to showcase your products and services to the target market audience in an intuitive manner. We will offer you drive operational excellence, unlock complexity, and create new business opportunities through our BPO and KPO services.</p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Web Content / Brand / Blog / SEO content writing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Online Presence/ Business Writing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Programming assistance and Code writing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> BPO / KPO services</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/energy-utilities-img.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="education_box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/education-img.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Education Industry</label>
                                            <div className="custom-heading-text">Education</div>
                                        </div>
                                        <p>In today's digital age, the education industry is continually evolving and leveraging technology to enhance teaching and learning experiences. At our IT service, we are dedicated to supporting educational institutions by providing comprehensive IT solutions tailored to their unique needs. </p>
                                        
                                        <p>Our expertise in the education industry enables us to offer a range of services that address key challenges faced by schools, colleges, and universities. We provide robust infrastructure solutions, including network setup, server management, and cloud integration, ensuring reliable and secure IT systems for academic institutions. </p>
                                        
                                        <p>Moreover, we specialize in educational software development, designing and implementing learning management systems (LMS), student information systems (SIS), and e-learning platforms. These solutions facilitate seamless communication, content delivery, and assessment management, enabling educators to deliver engaging and interactive learning experiences.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="retail_box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Retail Industry</label>
                                            <div className="custom-heading-text">Retail</div>
                                            <p>The retail industry can leverage IT services for inventory management, customer relationship management, point-of-sale systems, and analytics tools to gather insights into customer behavior. We help retailers to effectively communicate their brand message and differentiate themselves from their competition. We also provide services like digital optimization to improve their online visibility, engage with customers through social media, and improve the user experience on their e-commerce website. </p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Writing and Editing Services </li>
                                            <li><FiCheckCircle className="yellowCheck" /> Compelling Product Descriptions</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Search Engine Optimization (Seo)</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Intuitive Website Design</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/retail-img.svg" alt="" /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="banking_box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/banking-img.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Banking Industry</label>
                                            <div className="custom-heading-text">Banking</div>
                                        </div>
                                        <p>We help the banking sector industries to create and maintain their online presence, including developing and maintaining their website, building online banking portals and applications, and integrating payment gateways for online transactions. They can leverage our IT services to improve their operational efficiency, security, and customer experience</p>
                                        
                                        <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Managing data centers, network infrastructure, and cybersecurity</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Implementing technologies such as mobile banking, ATMs, and online banking platforms</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Customer service, back-office operations and technology support to third-party vendors Compelling content,  effective marketing campaigns</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="government_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Government Body</label>
                                            <div className="custom-heading-text">Government</div>
                                            <p>The government sectors use our services for developing and maintaining their websites, building online portals and applications for citizen services, and integrating payment gateways for online transactions, improving operational efficiency, security, and citizen services. This includes managing data centers, network infrastructure, and cyber security, as well as implementing technologies such as digital ID systems, e-government services, and public safety systems. </p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Clear and concise content</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Public Announcements / Press Releases / Social Media Posts</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Integrative payment gateways</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Effective communication with the citizens</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/goverment-img.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="life_science_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/life-science-img.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Life Science Industry</label>
                                            <div className="custom-heading-text">Life Science</div>
                                        </div>
                                        <p>The life science industry uses our writing and editing services to create clear and accurate scientific communications, including research papers, clinical trial reports, and regulatory documents. This helps life science companies to effectively communicate their research findings and adhere to regulatory requirements.</p>
                                        
                                        <p>This industry uses our digital optimization services to improve their online visibility, engage with researchers and healthcare providers through social media, and provide personalized and targeted marketing campaigns. </p>
                                        
                                        <p>Our IT services help improve their operational efficiency, data management, and research capabilities. This includes managing data centers, network infrastructure, and cyber security, as well as implementing technologies such as cloud computing, laboratory information management systems, and electronic data capture systems.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="telecommunications_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Telecommunications Industry</label>
                                            <div className="custom-heading-text">Telecommunications</div>
                                            <p>This industry utilizes our services to improve its operational efficiency, network management, and customer experience. We offer robust network security, collaboration, network automation, cloud computing, BPO and KPO services. Apart from this, we accelerate developing and maintaining telecom websites, building online portals and applications for customer service and support, and integrating payment gateways for online transactions. </p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Smooth running of operations</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Uninterrupted services </li>
                                            <li><FiCheckCircle className="yellowCheck" /> Outsourcing non-core activities </li>
                                            <li><FiCheckCircle className="yellowCheck" /> Digital optimization </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/Telecommunications-img.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="consumer_good_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/Consumer-Good-img.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Consumer Good</label>
                                            <div className="custom-heading-text">Consumer Good</div>
                                        </div>
                                        <p>The consumer goods industry uses our web development services to create and maintain their e-commerce websites, which can be used to market their products to potential customers. This can help in increasing their online presence and reach to a wider audience. </p>
                                        
                                        <p>We also outsource non-core activities such as customer service, data entry, and administrative tasks to third-party service providers for reducing costs, improving efficiency, and increasing company productivity. </p>
                                        
                                        <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Improvement in online visibility</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Search engine rankings / Social media presence</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Increase in customer attraction and brand awareness</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Online tutoring services to train employees </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="healthcare_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Healthcare Industry</label>
                                            <div className="custom-heading-text">Healthcare</div>
                                            <p>The healthcare industry avails our digital optimization services to enhance online visibility, search engine rankings, and social media presence. This can help in attracting more customers and increasing brand awareness through providing information about medical conditions and services like appointment scheduling and telemedicine to strengthens patient education and accessibility to healthcare services</p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Web development services to create and maintain websites</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Appointment scheduling and telemedicine</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Web Content / Brand / Blog / SEO content writing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Online tutoring services </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/healthcare-img.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="manufacturing_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/manufacturing-img.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Manufacturing industry</label>
                                            <div className="custom-heading-text">Manufacturing</div>
                                        </div>
                                        <p>The manufacturing industry uses our web development services to create and maintain their websites, which can be used to showcase their products, provide information about their services, attract potential customers, and build on their market presence.</p>
                                        
                                        <p>This can help in ensuring the smooth running of operations and providing uninterrupted services to your customers. </p>
                                        
                                        <p>Our writing and editing services create high-quality content for your websites, marketing materials, and product manuals. This can help in engaging customers and providing them valuable information. This can help in improving the quality of services provided to customers and enhancing the overall performance of the company. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="retail_area">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Travel industry</label>
                                            <div className="custom-heading-text">Travel</div>
                                            <p>We create engaging travel content such as blog posts, travel guides, and social media posts with our writing services. This will help your travel company attract more visitors to their websites and increase engagement on social media. We provide digital optimization to improve the visibility and ranking of travel websites in search engine results pages (SERPs). </p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> More organic traffic</li>
                                            <li><FiCheckCircle className="yellowCheck" /> User-friendly and responsive website design </li>
                                            <li><FiCheckCircle className="yellowCheck" /> Online travel booking platforms </li>
                                            <li><FiCheckCircle className="yellowCheck" /> Customized booking system / CRM tool to manage customer data</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/retai-img.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        


            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />


        </>
    )
}   
export default IndustryArea;  