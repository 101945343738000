import React from 'react';
import CountUp from 'react-countup';


const CompanyCounterSmallArea = () => {

    return (
        <>
          <section className="company-progress-section companyProgress-two">	
            <div className="container">
                <div className="company-progressArea">
                    <div className="CPA-progress-inner CPA-rpogress-three">
                        <div className="CPA-progress-box">
                            <div className="CPA-progress-mid">
                                <div className="CPA-progress-flex">
                                    <div className="CPA-progressBox-img">
                                        <img src="images/year-inBusiness-iconWhite.svg" alt="" />
                                    </div>
                                    <div className="CPA-progressBox-content number_counter plus-counter">
                                        <CountUp end={12} className='className="counter-value' /> <label>Year</label>
                                        <p>in Business</p>
                                    </div>					
                                </div>
                            </div>
                        </div>
                        
                        <div className="CPA-progress-box">
                            <div className="CPA-progress-mid">
                                <div className="CPA-progress-flex">
                                    <div className="CPA-progressBox-img">
                                        <img src="images/team-iconWhite.svg" alt="" />
                                    </div>
                                    <div className="CPA-progressBox-content number_counter plus-counter">
                                        <CountUp end={15} className='className="counter-value' /> <label>Team</label>
                                        <p>Member</p>
                                    </div>					
                                </div>
                            </div>
                        </div>
                        
                        <div className="CPA-progress-box">
                            <div className="CPA-progress-mid">
                                <div className="CPA-progress-flex">
                                    <div className="CPA-progressBox-img">
                                        <img src="images/happy-client-iconWhite.svg" alt="" />
                                    </div>
                                    <div className="CPA-progressBox-content number_counter">
                                        <CountUp end={9829} className='className="counter-value' />
                                        <p>Happy Client</p>
                                    </div>					
                                </div>
                            </div>
                        </div>
                        
                        <div className="CPA-progress-box">
                            <div className="CPA-progress-mid">
                                <div className="CPA-progress-flex">
                                    <div className="CPA-progressBox-img">
                                        <img src="images/successful-project-iconWhite.svg" alt="" />
                                    </div>
                                    <div className="CPA-progressBox-content number_counter plus-counter">
                                        <CountUp end={12000} className='className="counter-value' />
                                        <p>Successful <br/>Project</p>
                                    </div>					
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        </>
    )
}   




export default CompanyCounterSmallArea;   