import React, { useState } from 'react';
import {NavLink} from 'react-router-dom';

import { HashLink } from 'react-router-hash-link';

import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';


function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <button
      type="button" className="mobileMenuArrow"  onClick={decoratedOnClick} > {children}
    </button>
  );
}



const Header = () => {
  const handleClick = () => {
      window.scrollTo(0,0);
  }   

  const targetElements = ['section-1', 'section-2', 'section-3']

  const [showDropdown, setShowDropdown] = useState(false);

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    });   
  }

  const [MobileMenu, setMobileMenu] = useState(false);
  const handleClose = () => setMobileMenu(false);
  const handleShow = () => setMobileMenu(true);
  
    
    return (
      <>
        <div className="custom-navbar">
          <div className="container">
            <div className="header-wrapper">
                <div className="header-left">
                  <div className="logo">
                    <NavLink to="/"  onClick={handleClick}>
                      <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" />
                    </NavLink>
                  </div>
                </div>

                <div className="header-right">
                  <nav className="mainmenunav">
                    <ul className="mainmenu">
                      <li className="has-droupdown">
                        <NavLink to="" onClick={handleClick}>Company <span></span></NavLink>
                        <ul className="submenu">
                          <li><NavLink to="aboutUs" onClick={handleClick}>About us</NavLink></li>
                          <li><NavLink to="career" onClick={handleClick}>Careers</NavLink></li>
                          <li><NavLink to="" onClick={handleClick}>Blog</NavLink></li>
                        </ul>
                      </li>                      
                          
                      <li className="has-droupdown megaMenu">
                        <NavLink to="">Service <span></span></NavLink>
                        <ul className="submenu">
                          <li>
                            <div className="mega-menu-container">
                              <div className="row">
                                <div className="col-4">
                                  <div className="mega-menu-box">                                  
                                    <h4 className="mega-menu-heading"><NavLink to="itSolution" onClick={handleClick}>IT Solution</NavLink></h4>
                                    <ul className="mega-menu-list">
                                      <li>                                      
                                        <NavLink to="webDevelopment" onClick={handleClick}>Web Development</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="customerSoftwareAppDevelopmentArea" onClick={handleClick}>Custom Software & App Development</NavLink>
                                      </li>
                                      <li>                                      
                                        <NavLink to="uiUxDesign" onClick={handleClick}>UI/UX Design</NavLink>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">                                    
                                      <NavLink to="digitalInteractive" onClick={handleClick}>Digital Interactive</NavLink>
                                    </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                        <HashLink smooth to="/digitalInteractive#SEO_tab" scroll={el => scrollWithOffset(el, 120)}>
                                          Digital Interactive
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/digitalInteractive#socialMedia_tab" scroll={el => scrollWithOffset(el, 120)}>
                                          Social Media optimization
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/digitalInteractive#onlineReputation_tab" scroll={el => scrollWithOffset(el, 120)}>
                                          Online Reputation Management
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/digitalInteractive#conversionRate_tab" scroll={el => scrollWithOffset(el, 120)}>
                                          Conversion Rate Optimization
                                        </HashLink>
                                      </li>
                                    </ul>
                                  </div>
                                  
                                </div>
                                <div className="col-4">
                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">
                                      <NavLink to="outsourcingServices" onClick={handleClick}>Outsourcing Services</NavLink>
                                    </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                        <label>BPO</label>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/outsourcingServices#Inbound_Outbound" scroll={el => scrollWithOffset(el, 200)}>
                                          Inbound & Outbound
                                        </HashLink>
                                      </li>
                                      <li>
                                        <label>KPO</label>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/outsourcingServices#Process_Outsourcing" scroll={el => scrollWithOffset(el, 200)}>
                                          Process Outsourcing
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/outsourcingServices#Back_office_Outsourcing" scroll={el => scrollWithOffset(el, 200)}>
                                          Back office Outsourcing
                                        </HashLink>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">
                                      <NavLink to="writingAndEditing" onClick={handleClick}>Writing and Editing</NavLink>
                                    </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                        <HashLink smooth to="/writingAndEditing#web_brand_writing" scroll={el => scrollWithOffset(el, 120)}>
                                        Web, Brand, Blog & SEO Content Writing
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/writingAndEditing#social_media_writing" scroll={el => scrollWithOffset(el, 120)}>
                                          Social Media & CV/Profile Writing
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/writingAndEditing#editing_proofreading_writing" scroll={el => scrollWithOffset(el, 120)}>
                                          Editing and Proofreading
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/writingAndEditing#copyrighting_writing" scroll={el => scrollWithOffset(el, 120)}>
                                        Copyrighting
                                        </HashLink>
                                      </li>
                                    </ul>
                                  </div>

                                </div>

                                <div className="col-4">
                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">
                                      <NavLink to="learningIntership" onClick={handleClick}>Learning and Internship</NavLink>
                                    </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                        <NavLink to="learningIntership" onClick={handleClick}>Certification of Full Stack Development</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="learningIntership" onClick={handleClick}>Internship on Development Courses</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="learningIntership" onClick={handleClick}>Python Certification Course</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="learningIntership" onClick={handleClick}>MATLAB & Simulink</NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                  
                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">
                                      <NavLink to="onlineTutoring" onClick={handleClick}>Online Tutoring</NavLink>
                                      </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                         <NavLink to="onlineTutoring" onClick={handleClick}>Online Study</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="onlineTutoring" onClick={handleClick}>Online Teaching</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="onlineTutoring" onClick={handleClick}>Language Learning</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="onlineTutoring" onClick={handleClick}>Corporate Training</NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li className="has-droupdown megaMenu">
                        <NavLink to="industry" onClick={handleClick}>Industries <span></span></NavLink>
                        <ul className="submenu">
                          <li>
                          <div className="mega-menu-container">
                            <div className="row">
                              
                              <div className="col-4">
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#automotive_sec" scroll={el => scrollWithOffset(el, 120)}>
                                    Automotive
                                    </HashLink>
                                  </h4>
                                  <p>Efficient solutions for your automotive technology needs.</p>
                                </div>

                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#energy_utilities_area" scroll={el => scrollWithOffset(el, 120)}>
                                    Energy & utilities
                                    </HashLink>
                                  </h4>
                                  <p>Empowering your industry with advanced technology.</p>
                                </div>
                                
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#education_box" scroll={el => scrollWithOffset(el, 120)}>
                                    Education
                                    </HashLink>
                                  </h4>
                                  <p>Enhancing learning experiences through innovative technology solutions.</p>
                                </div>
                                
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#retail_box" scroll={el => scrollWithOffset(el, 120)}>
                                    Retail
                                    </HashLink>
                                  </h4>
                                  <p>Driving growth and efficiency in the modern marketplace.</p>
                                </div>
                              </div>
                             
                              <div className="col-4">
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#banking_box" scroll={el => scrollWithOffset(el, 120)}>
                                    Banking
                                    </HashLink>
                                  </h4>
                                  <p>Driving growth and efficiency in the modern marketplace.</p>
                                </div>

                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#government_area" scroll={el => scrollWithOffset(el, 120)}>
                                    Government
                                    </HashLink>
                                  </h4>
                                  <p>Empowering public sector organizations with efficient digital infrastructure.</p>
                                </div>
                                
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#life_science_area" scroll={el => scrollWithOffset(el, 120)}>
                                    Life Science
                                    </HashLink>
                                  </h4>
                                  <p>Advancing research and innovation in life sciences industry.</p>
                                </div>
                                
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#telecommunications_area" scroll={el => scrollWithOffset(el, 120)}>
                                    Tececommunications
                                    </HashLink>
                                  </h4>
                                  <p>Enabling seamless connectivity and communication for telecom providers.</p>
                                </div>
                              </div>

                              <div className="col-4">
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#consumer_good_area" scroll={el => scrollWithOffset(el, 120)}>
                                    Consumer goods
                                    </HashLink>
                                  </h4>
                                  <p>Driving digital transformation for consumer-focused businesses.</p>
                                </div>

                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#healthcare_area" scroll={el => scrollWithOffset(el, 120)}>
                                    Healthcare
                                    </HashLink>
                                  </h4>
                                  <p>Transforming healthcare through innovative technology and data-driven solutions.</p>
                                </div>
                                
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#manufacturing_area" scroll={el => scrollWithOffset(el, 120)}>
                                    Manufactureing
                                    </HashLink>
                                  </h4>
                                  <p>Enhancing productivity and innovation in the manufacturing industry.</p>
                                </div>
                                
                                <div className="mega-menu-box">
                                  <h4 className="mega-menu-heading">
                                    <HashLink smooth to="/industry#retail_area" scroll={el => scrollWithOffset(el, 120)}>
                                    Travel
                                    </HashLink>
                                  </h4>
                                  <p>Streamlining operations and enhancing customer experiences in travel industry.</p>
                                </div>
                              </div>

                            </div>
                          </div>
                          </li>
                        </ul>
                      </li>
                          
                      <li>
                        <NavLink to="hireDeveloper" onClick={handleClick}>Hire Developers</NavLink>
                      </li>

                      <li>
                        <NavLink to="contact" onClick={handleClick}>Contact</NavLink>
                      </li>

                      <li>
                        <a href="#Free_quote" className="custom-btn">Get a free quote</a>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="header-rightMobile">
                  <Button className="custom-btn" onClick={handleShow}>
                    <img src={`${process.env.PUBLIC_URL}/images/menu-icon.svg`} alt="" />
                  </Button>    
                </div>

                <Offcanvas show={MobileMenu} onHide={handleClose}>
                  <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body>
                        <Accordion>
                          <Card className="mobileMenulist">
                            <Card.Header>
                              <NavLink to="" onClick={handleClick}>Company <span></span></NavLink>
                              <CustomToggle eventKey="0"><img src={`${process.env.PUBLIC_URL}/images/down-arrow.svg`} alt="" /></CustomToggle>
                            </Card.Header>   

                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <ul>
                                  <li><NavLink to="aboutUs" onClick={handleClick}>About us</NavLink></li>
                                  <li><NavLink to="career" onClick={handleClick}>Careers</NavLink></li>
                                  <li><NavLink to="" onClick={handleClick}>Blog</NavLink></li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mobileMenulist">
                            <Card.Header>
                              <NavLink to="">Service <span></span></NavLink>
                              <CustomToggle eventKey="1"><img src={`${process.env.PUBLIC_URL}/images/down-arrow.svg`} alt="" /></CustomToggle>
                            </Card.Header>   

                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                              <div className="row">
                                <div className="col-12">
                                  <div className="mega-menu-box">                                  
                                    <h4 className="mega-menu-heading"><NavLink to="itSolution" onClick={handleClick}>IT Solution</NavLink></h4>
                                    <ul className="mega-menu-list">
                                      <li>                                      
                                        <NavLink to="webDevelopment" onClick={handleClick}>Web Development</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="customerSoftwareAppDevelopmentArea" onClick={handleClick}>Custom Software & App Development</NavLink>
                                      </li>
                                      <li>                                      
                                        <NavLink to="uiUxDesign" onClick={handleClick}>UI/UX Design</NavLink>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">                                    
                                      <NavLink to="digitalInteractive" onClick={handleClick}>Digital Interactive</NavLink>
                                    </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                        <HashLink smooth to="/digitalInteractive#SEO_tab" scroll={el => scrollWithOffset(el, 120)}>
                                          Digital Interactive
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/digitalInteractive#socialMedia_tab" scroll={el => scrollWithOffset(el, 120)}>
                                          Social Media optimization
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/digitalInteractive#onlineReputation_tab" scroll={el => scrollWithOffset(el, 120)}>
                                          Online Reputation Management
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/digitalInteractive#conversionRate_tab" scroll={el => scrollWithOffset(el, 120)}>
                                          Conversion Rate Optimization
                                        </HashLink>
                                      </li>
                                    </ul>
                                  </div>
                                  
                                </div>
                                <div className="col-12">
                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">
                                      <NavLink to="outsourcingServices" onClick={handleClick}>Outsourcing Services</NavLink>
                                    </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                        <label>BPO</label>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/outsourcingServices#Inbound_Outbound" scroll={el => scrollWithOffset(el, 200)}>
                                          Inbound & Outbound
                                        </HashLink>
                                      </li>
                                      <li>
                                        <label>KPO</label>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/outsourcingServices#Process_Outsourcing" scroll={el => scrollWithOffset(el, 200)}>
                                          Process Outsourcing
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/outsourcingServices#Back_office_Outsourcing" scroll={el => scrollWithOffset(el, 200)}>
                                          Back office Outsourcing
                                        </HashLink>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">
                                      <NavLink to="writingAndEditing" onClick={handleClick}>Writing and Editing</NavLink>
                                    </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                        <HashLink smooth to="/writingAndEditing#web_brand_writing" scroll={el => scrollWithOffset(el, 120)}>
                                        Web, Brand, Blog & SEO Content Writing
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/writingAndEditing#social_media_writing" scroll={el => scrollWithOffset(el, 120)}>
                                          Social Media & CV/Profile Writing
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/writingAndEditing#editing_proofreading_writing" scroll={el => scrollWithOffset(el, 120)}>
                                          Editing and Proofreading
                                        </HashLink>
                                      </li>
                                      <li>
                                        <HashLink smooth to="/writingAndEditing#copyrighting_writing" scroll={el => scrollWithOffset(el, 120)}>
                                        Copyrighting
                                        </HashLink>
                                      </li>
                                    </ul>
                                  </div>

                                </div>

                                <div className="col-12">
                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">
                                      <NavLink to="learningIntership" onClick={handleClick}>Learning and Internship</NavLink>
                                    </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                        <NavLink to="learningIntership" onClick={handleClick}>Certification of Full Stack Development</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="learningIntership" onClick={handleClick}>Internship on Development Courses</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="learningIntership" onClick={handleClick}>Python Certification Course</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="learningIntership" onClick={handleClick}>MATLAB & Simulink</NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                  
                                  <div className="mega-menu-box">
                                    <h4 className="mega-menu-heading">
                                      <NavLink to="onlineTutoring" onClick={handleClick}>Online Tutoring</NavLink>
                                      </h4>
                                    <ul className="mega-menu-list">
                                      <li>
                                         <NavLink to="onlineTutoring" onClick={handleClick}>Online Study</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="onlineTutoring" onClick={handleClick}>Online Teaching</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="onlineTutoring" onClick={handleClick}>Language Learning</NavLink>
                                      </li>
                                      <li>
                                        <NavLink to="onlineTutoring" onClick={handleClick}>Corporate Training</NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mobileMenulist">
                            <Card.Header>
                            <NavLink to="industry" onClick={handleClick}>Industries <span></span></NavLink>
                              <CustomToggle eventKey="2"><img src={`${process.env.PUBLIC_URL}/images/down-arrow.svg`} alt="" /></CustomToggle>
                            </Card.Header>   

                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#automotive_sec" scroll={el => scrollWithOffset(el, 120)}>
                                        Automotive
                                        </HashLink>
                                      </h4>
                                      <p>Efficient solutions for your automotive technology needs.</p>
                                    </div>

                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#energy_utilities_area" scroll={el => scrollWithOffset(el, 120)}>
                                        Energy & utilities
                                        </HashLink>
                                      </h4>
                                      <p>Empowering your industry with advanced technology.</p>
                                    </div>
                                    
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#education_box" scroll={el => scrollWithOffset(el, 120)}>
                                        Education
                                        </HashLink>
                                      </h4>
                                      <p>Enhancing learning experiences through innovative technology solutions.</p>
                                    </div>
                                    
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#retail_box" scroll={el => scrollWithOffset(el, 120)}>
                                        Retail
                                        </HashLink>
                                      </h4>
                                      <p>Driving growth and efficiency in the modern marketplace.</p>
                                    </div>
                                  </div>
                                
                                  <div className="col-12">
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#banking_box" scroll={el => scrollWithOffset(el, 120)}>
                                        Banking
                                        </HashLink>
                                      </h4>
                                      <p>Secure, scalable, and innovative technology for financial institutions.</p>
                                    </div>

                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#government_area" scroll={el => scrollWithOffset(el, 120)}>
                                        Government
                                        </HashLink>
                                      </h4>
                                      <p>Empowering public sector organizations with efficient digital infrastructure.</p>
                                    </div>
                                    
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#life_science_area" scroll={el => scrollWithOffset(el, 120)}>
                                        Life Science
                                        </HashLink>
                                      </h4>
                                      <p>Advancing research and innovation in life sciences industry.</p>
                                    </div>
                                    
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#telecommunications_area" scroll={el => scrollWithOffset(el, 120)}>
                                        Tececommunications
                                        </HashLink>
                                      </h4>
                                      <p>Enabling seamless connectivity and communication for telecom providers.</p>
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#consumer_good_area" scroll={el => scrollWithOffset(el, 120)}>
                                        Consumer goods
                                        </HashLink>
                                      </h4>
                                      <p>Driving digital transformation for consumer-focused businesses.</p>
                                    </div>

                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#healthcare_area" scroll={el => scrollWithOffset(el, 120)}>
                                        Healthcare
                                        </HashLink>
                                      </h4>
                                      <p>Transforming healthcare through innovative technology and data-driven solutions.</p>
                                    </div>
                                    
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#manufacturing_area" scroll={el => scrollWithOffset(el, 120)}>
                                        Manufactureing
                                        </HashLink>
                                      </h4>
                                      <p>Enhancing productivity and innovation in the manufacturing industry.</p>
                                    </div>
                                    
                                    <div className="mega-menu-box">
                                      <h4 className="mega-menu-heading">
                                        <HashLink smooth to="/industry#retail_area" scroll={el => scrollWithOffset(el, 120)}>
                                        Travel
                                        </HashLink>
                                      </h4>
                                      <p>Streamlining operations and enhancing customer experiences in travel industry.</p>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="mobileMenulist">
                            <Card.Header>
                              <NavLink to="hireDeveloper" onClick={handleClick}>Hire Developers</NavLink>
                            </Card.Header>   
                          </Card>

                          <Card className="mobileMenulist">
                            <Card.Header>
                              <NavLink to="contact" onClick={handleClick}>Contact</NavLink>
                            </Card.Header>   
                          </Card>
                        </Accordion>

                    </Offcanvas.Body>
                </Offcanvas>
            </div>
          </div>
        </div>

      </>
        )
}   
export default Header;  