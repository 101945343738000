import React, { useEffect } from "react";



import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'
import { FiCheckCircle } from "react-icons/fi";





const WritingAndEditingArea = () => {
    useEffect(() => {
        function handleMouseMove(event) {
          document.querySelectorAll(".shape-1").forEach((shift) => {
            const position = shift.getAttribute("value");
            const x = (window.innerWidth - event.pageX * position) / 90;
            const y = (window.innerHeight - event.pageY * position) / 90;
    
            shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
          });
        }
    
        document.addEventListener("mousemove", handleMouseMove);
    
        return () => {
          document.removeEventListener("mousemove", handleMouseMove);
        };
      }, []);
      


    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">The world’s best writers</div>
                                <h1>World's NO.1 Best Content Writing &amp; Editing Service Provider</h1>
                                <p>We have a team of expert content writers who are ready to assist you with high- quality, affordable, and unique white label content. We also provide the services of revising the professionally written content to improve your content quality while examining overall language, comprehension and formatting.</p>
                                
                                <div className="banner-btn-area">
                                    <a href="#Free_quote" className="custom-btn btn-primary">Get a Free Quote</a>
                                    <a href="https://thewritingservices.com/" className="arrow-btn" target="_blank"><img src="images/btn-arrow.svg" alt="" /> WWW.THEWRITINGSERVICE.COM</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right">
                                <img src="images/writing-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />


            <section className="what-do-area">	
                <div className="container">
                    <div className="custom-heading">
                        <label>It’s What We Do</label>
                        <div className="custom-heading-text">We strive to build your Digital Presence</div>
                        <p>We offer you the facility of Website and App localization with complete application design and management services. It includes entire development process from ideation to concept development to project delivery and ongoing support.</p>
                    </div>
                    
                    <div className="what-do-inner">
                        <div className="what-do-box">
                            <div className="what-doBox-img"><img src="images/writing-quickFeature1.svg" alt="" /></div>
                            <h3>Exceptional Academic Research &amp; Writing Service</h3>
                            <p>To deliver high quality content, we believe in the basic theory, i.e. "Dedication &amp; Discipline". It helps our
            team to deliver A+ Quality Academic Research Content.</p>
                        </div>
                        
                        <div className="what-do-box">
                            <div className="what-doBox-img"><img src="images/writing-quickFeature2.svg" alt="" /></div>
                            <h3>Web, Brand, Blog &amp; SEO Content Development Service</h3>
                            <p>We are consistently delivering impactful content to our clients that are assisting them to create their business voice, boost SEO and attract huge number of traffic on their website.</p>
                        </div>
                        
                        <div className="what-do-box">
                            <div className="what-doBox-img"><img src="images/writing-quickFeature3.svg" alt="" /></div>
                            <h3>Online Presence &amp; CV/Profile Writing Service</h3>
                            <p>We assist you to create creative attractive CV profile so that your online presence can be influenced.</p>
                        </div>                                                    
                        
                        <div className="what-do-box">
                            <div className="what-doBox-img"><img src="images/writing-quickFeature4.svg" alt="" /></div>
                            <h3>Programming Help and Code Writing Service</h3>
                            <p>Our Programming Expert will provide you quick assistance and will support you in getting homework help and in designing the best programming assignment.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whoWe-are-area mt140">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left">
                                <h2>Website editing services</h2>
                                <p>To attract consumers’ attention, it is essential to focus towards the quality of the website. The content should be written in Web-friendly writing and should have easy-to-follow flow as these aspects encourage return visits. We understand the importance of effective content for the success of your business.</p>
                                <div className="WWA-left-highlight">
                                    <div className="WWAL-highlight-box">
                                        <div className="WWAL-highlight-img">
                                            <img src="images/hire-developer-budget-icon.svg" alt="" />
                                        </div>
                                        <div className="WWAL-highlight-cont">
                                            <h4>Best writers</h4>
                                            <p>Our Writers will assist you with specialized content for blogs, reports, website, advertisement, promotional content, and many more.</p>
                                        </div>
                                    </div>

                                    <div className="WWAL-highlight-box">
                                        <div className="WWAL-highlight-img">
                                            <img src="images/hire-developer-team-icon.svg" alt="" />
                                        </div>
                                        <div className="WWAL-highlight-cont">
                                            <h4>Loved by customers</h4>
                                            <p>Our Content editors strengthen your content in a manner that your customers prefer to spend more time on your website.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-img" src="images/writing-editing2.jpg" alt="" />
                                <img className="WWA-right-img2" src="images/writing-editing1.jpg" alt="" />
                                <div className="shape-group">
                                    <ul>
                                        <li className="shape-1" value="3"><img src="images/round-dots-black.svg" alt="" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-service-area card-two mt140">
                <div className="container">		
                    <div className="OS-box-area writing-featureSec">
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/writing-feature1.svg" alt="" /></div>
                            <div className="OSB-box-heading">Rigorous Quality Checks</div>
                            <div className="OSB-box-text">We have a habit of adhering to a quality control process which includes editing, proofreading, examining and using appropriate references, sticking to a given word limit and finishing before deadlines.</div>
                        </div>	
                        
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/writing-feature2.svg" alt="" /></div>
                            <div className="OSB-box-heading">Extensive Guarantees</div>
                            <div className="OSB-box-text">The foundation of our huge client support comes from the fact that we promise 100% guarantee of handing over novel and personalized content. If at any point we fail to keep our word, we offer 100% refund.</div>
                        </div>
                        
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/writing-feature3.svg" alt="" /></div>
                            <div className="OSB-box-heading">Fast Resolutions</div>
                            <div className="OSB-box-text">Our services are made-to-order. With such intricacies, things are probable to go wrong. We aim to resolve them promptly. We have a team dedicated for quality check and to overlook that every creation is tailored in accordance to the requirements of our clients.</div>
                        </div>
                        
                        <div className="our-service-box">
                                <div className="OSB-box-img"><img src="images/writing-feature4.svg" alt="" /></div>
                                <div className="OSB-box-heading">Unique back-end system</div>
                                <div className="OSB-box-text">Key to any successful relationship is communication so as to keep a track of our client’s requisitions and communicate with them effectively. We have a thoroughly customized order and account management system.</div>
                                <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                            </div>	
                            
                            <div className="our-service-box">
                                <div className="OSB-box-img"><img src="images/writing-feature5.svg" alt="" /></div>
                                <div className="OSB-box-heading">Customized Writing Services</div>
                                <div className="OSB-box-text">Nowadays, content is king so you need the expertise of professional editors and writers. Whether you are required to create a website from scratch or required to edit an existing website, we are here to help you.</div>
                                <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                            </div>
                            
                            <div className="our-service-box">
                                <div className="OSB-box-img"><img src="images/writing-feature6.svg" alt="" /></div>
                                <div className="OSB-box-heading">Qualified writers</div>
                                <div className="OSB-box-text">Our services require a great deal of discipline expertise, to make sure that the content is authentic and well-written. Therefore, we only hire qualified professionals with a specialization or multiple specializations.</div>
                            </div>
                    </div>
                    
                </div>
            </section>

            <section className="development-team-service mt100">	
                <div className="container">
                    <div className="DIS-inner-area" id="web_brand_writing">
                        <div className="DIS-inner-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/web-content-img.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Web, Brand, Blog &amp; SEO Content Writing quality control process</label>
                                            <div className="custom-heading-text">Web, Brand, Blog &amp; SEO Content Writing quality control process</div>
                                        </div>
                                        <p>Quality Control is highly necessary as Quality content is the foundation of digital marketing. Our Professionals are aware about the importance of Quality Control and they give concern towards proofreading before publishing anything online as they know that content has the potential to make or break the brand's reputation.</p>
                                        
                                        <p>We offer high quality and specialized content for webpage, blogs, reports and promotional content. Our quality control team also offers content editing facility which involves the correction of minor to major mistakes.</p>
                                        
                                        <p>Internet offers you the comfort to easily avail our quality proofreading service. Our expert editors will offer you the service of content writing without hassle. We offer editing and proofreading services after going through your each service and organizational processes which enable us to provide us the best editing quality.</p>
                                        
                                        <div className="banner-btn-area">
                                            <a href="https://thewritingservices.com/" className="arrow-btn" target="_blank"><img src="images/btn-arrow.svg" alt="" /> WWW.THEWRITINGSERVICE.COM</a>
                                        </div>
                                    </div>
                                </div>
                            </div>				
                        </div>
                    </div>
                    
                    <div className="DIS-inner-area" id="social_media_writing">
                        <div className="DIS-inner-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>How Content Writing Services Can Assist You</label>
                                            <div className="custom-heading-text">How Content Writing Services Can Assist You</div>
                                            <p>Availing the services of content writing can assist you to create lucrative content and to design effective marketing campaigns that can enable you to create strong relationship with your customers. It will allow you to share consistent information to your customers and will increase your business visibility.</p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> SEO Writing &amp; Editing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Webpage Writing &amp; Editing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Blog Writing &amp; Editing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Article Writing &amp; Editing</li>
                                            </ul>
                                            
                                            <div className="banner-btn-area">
                                                <a href="https://thewritingservices.com/" className="arrow-btn" target="_blank"><img src="images/btn-arrow.svg" alt="" /> WWW.THEWRITINGSERVICE.COM</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/social-content-img.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
                    
                    <div className="DIS-inner-area" id="editing_proofreading_writing">
                        <div className="DIS-inner-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/writing-editing-img.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Editing and Proofreading</label>
                                            <div className="custom-heading-text">Goal Oriented Content</div>
                                        </div>
                                        <p>We offer our services at Competitive Price while focusing towards offering the value to the
            customers on every penny that they invested in our company. We tailor our content in a manner that
            it can best fit to your business and can meet your brand-specific needs.</p>
                                        
                                        <p>We offer Customized Content Writing Solutions to our Clients that are designed to meet their content
            need effectively and can assist them to enhance the potential of their business.</p>
                                        
                                        <p>Our Experts have the potential to provide the best textual and graphical content to our clients for
            their websites. Our writers design compelling and engaging content that can exactly define the
            objective and vision of your website. Moreover, Persuasive and Engaging blog will captivate your
            customers and our informative and relevant blogs will drive more traffic on your digital platform.</p>
                                        
                                        <div className="banner-btn-area">
                                            <a href="https://thewritingservices.com/" className="arrow-btn" target="_blank"><img src="images/btn-arrow.svg" alt="" /> WWW.THEWRITINGSERVICE.COM</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
                    
                    <div className="DIS-inner-area" id="copyrighting_writing">
                        <div className="DIS-inner-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Copyrighting</label>
                                            <div className="custom-heading-text">Enhancing the Value of the Content</div>
                                            <p>Our experts offer content editing services to create them as the best version of itself. The edited version
            provided by us is crystal clear and error-free. Our editors are capable to get rid of all grammatical &amp;
            logical issues and also remove factual blunders to ensure everything effective and cohesive in the final
            draft.</p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Video Script / E-Mail Writing / Marketing Content</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Case Studies / Buyer’s Guide / E-Books / Academic Content</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Social Media Content / Website Content / Blog Writing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Content Editing / Proofreading</li>
                                            </ul>
                                            <div className="banner-btn-area">
                                                <a href="https://thewritingservices.com/" className="arrow-btn" target="_blank"><img src="images/btn-arrow.svg" alt="" /> WWW.THEWRITINGSERVICE.COM</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/copywrite-content-img.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>	
                </div>
            </section>

            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />


        </>
    )
}   
export default WritingAndEditingArea;  