import React, { useState } from 'react';
import Slider from "react-slick";


import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";






const TestimonialArea = () => {
    const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();


  var settings = {
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]    
  }



    return (
        <>
        <section className="testimonial-area">	
            <div className="container">
                <div className="slider testimonial-nav">                    
                    <Slider asNavFor={nav1} ref={(slider2) => setNav2(slider2)} slidesToShow={5} swipeToSlide={true} focusOnSelect={true} {...settings}>
                        <div><div className="testimonial-img"><img src="images/testimonial-1.png" alt="" /></div></div>
                        <div><div className="testimonial-img"><img src="images/testimonial-2.png" alt="" /></div></div>
                        <div><div className="testimonial-img"><img src="images/testimonial-3.png" alt="" /></div></div>
                        <div><div className="testimonial-img"><img src="images/testimonial-4.png" alt="" /></div></div>
                        <div><div className="testimonial-img"><img src="images/testimonial-5.png" alt="" /></div></div>
                    </Slider>

                </div>
                
                <div className="slider testimonial-single">
                    <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                    <div>
                        <div className="testimonial-contBox">
                            <div className="custom-heading">
                                <label>Testimonial</label>
                            </div>
                            <p><i className="las la-quote-left"></i> They have been proactive in identifying and resolving any issues that arise, which allows us to focus on our core business. Their customer service is top-notch.<i className="las la-quote-right"></i></p>
                            <h4>Evechi</h4>
                            <h5>Wellington Region, New Zealand</h5>
                        </div>
                    </div>

                    <div>
                        <div className="testimonial-contBox">
                            <div className="custom-heading">
                                <label>Testimonial</label>
                            </div>
                            <p><i className="las la-quote-left"></i> When we were looking for a company to help us develop custom software for our business, we were impressed with it's track record of success. <i className="las la-quote-right"></i></p>
                            <h4>Bobbi Lintner</h4>
                            <h5>Yachiyo, Chiba, Japan</h5>
                        </div>
                    </div>

                    <div>
                        <div className="testimonial-contBox">
                            <div className="custom-heading">
                                <label>Testimonial</label>
                            </div>
                            <p><i className="las la-quote-left"></i> Their team is incredibly knowledgeable and helped us put a comprehensive security plan in place to protect our sensitive data. <i className="las la-quote-right"></i></p>
                            <h4>Maria Nakai</h4>
                            <h5>Dubai, United Arab Emirates</h5>
                        </div>
                    </div>

                    <div>
                        <div className="testimonial-contBox">
                            <div className="custom-heading">
                                <label>Testimonial</label>
                            </div>
                            <p><i className="las la-quote-left"></i> They're always quick to respond to any issues and have helped us prevent several potential problems before they could cause major disruptions. We highly recommend them to any business looking for a dependable IT service provider <i className="las la-quote-right"></i></p>
                            <h4>Adrian Davis</h4>
                            <h5>Calgary, Alberta, Canada</h5>
                        </div>
                    </div>
                    
                    <div>
                        <div className="testimonial-contBox">
                            <div className="custom-heading">
                                <label>Testimonial</label>
                            </div>
                            <p><i className="las la-quote-left"></i> Their team is extremely knowledgeable and professional and always goes above and beyond to meet our needs. They are always willing to go the extra mile! <i className="las la-quote-right"></i></p>
                            <h4>Raul Pardo</h4>
                            <h5>Watsonville, California, United States</h5>
                        </div>
                    </div>
                    </Slider>
                </div>
                    
            </div>
        </section>


        </>
    )

}   

export default TestimonialArea; 



