import React, { useEffect } from "react";
import {NavLink} from 'react-router-dom';


import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'
import { FiCheckCircle } from "react-icons/fi";





const OnlineTutoringArea = () => {
    const handleClick = () => {
        window.scrollTo(0,0);
    }

    useEffect(() => {
        function handleMouseMove(event) {
          document.querySelectorAll(".shape-1").forEach((shift) => {
            const position = shift.getAttribute("value");
            const x = (window.innerWidth - event.pageX * position) / 90;
            const y = (window.innerHeight - event.pageY * position) / 90;
    
            shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
          });
        }
    
        document.addEventListener("mousemove", handleMouseMove);
    
        return () => {
          document.removeEventListener("mousemove", handleMouseMove);
        };
      }, []);
      


    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Our Versatility</div>
                                <h1>A Good Education is Always a Base of a Bright Future</h1>
                                <p>For good education, it is essential to have expert tutor. Our Expert Tutors make the learning process so easy that you start loving it. They promote student success with the help of extensive data, exceptional support and effective methodologies.</p>
                                
                                <div className="banner-btn-area">
                                <NavLink to="/contact" onClick={handleClick} className="custom-btn btn-primary">Contact Us</NavLink>
                                    <a href="http://www.studentcabin.com/" className="arrow-btn" target="_blank"><img src="images/btn-arrow.svg" alt="" />WWW.STUDENTCABIN.COM</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right">
                                <img src="images/tutor-page-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />

            <section className="whoWe-are-area mt140">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left">
                                <h2>Better Learning. Better Results</h2>
                                <h3>Online education platform that fits for everyone</h3>
                                <p>Our Online Tutorial platform offers the facility to the students of accessing the professional teachers at anytime and anywhere. Online tutoring minimizes wastage of time and effort and offers safe and secure online learning environment.</p>
                                <p>Our interactive sessions will make learning process easy and effective.</p>
                                <div className="check-list mt-5">
                                    <ul>
                                    <li><FiCheckCircle className="yellowCheck" /> Availability of Online tutoring anywhere, anytime</li>
                                    <li><FiCheckCircle className="yellowCheck" /> 1:1 learning experience</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Attractive&nbsp;online classroom design to accelerate learning</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Student support at the click of a button</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-img" src="images/online-tutot-img2.jpg" alt="" />
                                <img className="WWA-right-img2" src="images/online-tutot-img1.jpg" alt="" />
                                <div className="leaning-trusted">Courses available for verified and top tutors</div>
                                <div className="shape-group">
                                    <ul>
                                        <li className="shape-1" value="3"><img src="images/round-dots-black.svg" alt="" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solution-sec mt140">	
                <div className="container">
                    <div className="solution-area">
                        <div className="solution-inner">
                            <div className="row">
                                <div className="col-8">
                                    <div className="solution-inner-heading">
                                        <div className="WWA-left">
                                            <div className="custom-heading left-heading">
                                                <label>Lorem Ipsum</label>
                                                <div className="custom-heading-text">Online Tutoring</div>
                                            </div>								
                                            <p>Our Online Tutoring Platform offers the support that you require to reach your full potential. We offer reliable and scalable service i.e. flexible</p>
                                            <div className="banner-btn-area">
                                                <a href="http://www.studentcabin.com/" className="arrow-btn mt-0" target="_blank"><img src="images/btn-arrow.svg" alt="" />WWW.STUDENTCABIN.COM</a>
                                            </div>
                                        </div>
                                    </div>	
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="solution-inner-box">
                                        <a href="">
                                            <div className="SIB-solution-img">
                                                <img src="images/all-courses-icon.svg" alt="" />
                                            </div>
                                            <div className="SIB-solution-content">
                                                <div className="SIB-solutionHead">Offering All types of Courses</div>
                                                <p>We have thousands of experts who have expertise in different subjects and even in different languages. It helps us to offer all types of courses to you.</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="solution-inner-box">
                                        <a href="">
                                            <div className="SIB-solution-img">
                                                <img src="images/online-consultation-icon.svg" alt="" />
                                            </div>
                                            <div className="SIB-solution-content">
                                                <div className="SIB-solutionHead">Online consultation for all</div>
                                                <p>You can Request us for a Demo as we have Online consultation facility for all.</p>
                                            </div>
                                        </a>
                                    </div>	
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="solution-inner-box">
                                        <a href="">
                                            <div className="SIB-solution-img">
                                                <img src="images/best-result-icon.svg" alt="" />
                                            </div>
                                            <div className="SIB-solution-content">
                                                <div className="SIB-solutionHead">Best results guranteed</div>
                                                <p>Our Experts are committed to support and accelerate the learning process to provide you the best student outcomes.</p>
                                            </div>
                                        </a>
                                    </div>	
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="solution-inner-box">
                                        <a href="">
                                            <div className="SIB-solution-img">
                                                <img src="images/verifies-tutor-icon.svg" alt="" />
                                            </div>
                                            <div className="SIB-solution-content">
                                                <div className="SIB-solutionHead">All verified tutors for you</div>
                                                <p>Our all Tutors are verified and have great experience, knowledge and Master’s degree in their particular niche.</p>
                                            </div>
                                        </a>
                                    </div>	
                                </div>
                            </div>				
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-box mt100">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right text-align-left">
                                <img className="WWA-right-imgdefault" src="images/tutor-help.svg" alt="" />
                            </div>	
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left pl-3">
                                <div className="custom-heading left-heading">
                                    <label>Lorem Ipsum</label>
                                    <div className="custom-heading-text">Instant study help</div>
                                </div>
                                <p>We offer you the facility of selecting tutors from our large list. You can use different filters available to narrow down your search and find the perfect fit easily.</p>
                                <div className="check-list mt-5">
                                    <ul>
                                    <li><FiCheckCircle className="yellowCheck" /> Learn Anytime</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Affordable Price</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Expert Help</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Structured Learning</li>
                                    </ul>
                                </div>
                                <div className="banner-btn-area">
                                    <a href="http://www.studentcabin.com/" className="arrow-btn mt-0" target="_blank"><img src="images/btn-arrow.svg" alt="" />WWW.STUDENTCABIN.COM</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="digital-marketing-services">		
                        <div className="DMS-servicesInner">						
                            <div className="DMS-service-area">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="DMS-service-box">
                                            <div className="DMSS-box-heading">What is Online Tutoring?</div>
                                            <div className="DMSS-box-text">Online tutoring website provides you an online educator who conducts sessions over the internet and help students to enhance their knowledge and abilities in a specific subject from the comfort of their home. It offers you personalized learning environment.</div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 col-md-12">
                                        <div className="DMS-service-box">
                                            <div className="DMSS-box-heading">Why do we use it?</div>
                                            <div className="DMSS-box-text">Online Tutoring facility helps the students to avail the services of expert tutors while sitting in the comfort of home. These services can even avail by the students who live in rural areas and have less or no opportunity for learning. If you are working personality and has no time for taking offline class, then also there is best opportunity for you to take tuitions at any point of time.</div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 col-md-12">
                                        <div className="DMS-service-box">
                                            <div className="DMSS-box-heading">Where can I get some?</div>
                                            <div className="DMSS-box-text">You can easily avail this service from EZZEEX website. You just need to fill the form below and our expert will contact you soon and will take information about your requirements and recommend you subjects and tutors that can best fit to your requirements.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>    

            <section className="whoWe-are-area mt140">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left product-service-box p-0">
                                <h2>Exam Preparation</h2>
                                <h3>Your Exams are near and you are Worried about it??</h3>
                                <p>Leave your worry on us as we are here to assist you to get good marks and become topper in the class. Our top priority is to clear each and every concept so that learning becomes fun for the students.</p>
                                <ul>
                                <li><FiCheckCircle className="yellowCheck" /> Facility of Assignment Review</li>
                                <li><FiCheckCircle className="yellowCheck" /> Homework Help</li>
                                <li><FiCheckCircle className="yellowCheck" /> Support at a Click of Button</li>
                                <li><FiCheckCircle className="yellowCheck" /> Assist to Reach at Full Potential</li>
                                </ul>
                                <div className="banner-btn-area">
                                    <a href="http://www.studentcabin.com/" className="arrow-btn mt-0" target="_blank"><img src="images/btn-arrow.svg" alt="" />WWW.STUDENTCABIN.COM</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-imgdefault" src="images/exam-preparation.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="OS-box-area mt100">
                        <div className="our-service-box">
                            <div className="OSB-box-heading">We Guarantee 100% Satisfaction</div>
                            <div className="OSB-box-text">We offer you trial before initiating the program and if you feel unsatisfied with the trial lesson, then also need not to worry as we will provide you a free replacement of the lesson with another tutor or you can also ask for full refund.</div>
                        </div>
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-heading">One-to-One Tutoring</div>
                            <div className="OSB-box-text">Our Experts are keen to offer you the facility of One-to- One Tutoring to you to create personalized
    learning environment where you can study Anytime, Anywhere and can resolve your unlimited queries.</div>
                        </div>
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-heading">Wide Range of Subjects</div>
                            <div className="OSB-box-text">We have Expert Tutors from diverse fields that enable us to deal in more than 250+ Subjects.</div>
                        </div>
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-heading">Post-Session Feedback</div>
                            <div className="OSB-box-text">After every session, we take feedback from our students to get acknowledged about how the session
    was and to get awareness about the areas where changes are required.</div>
                        </div>
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-heading">Multimodal Support</div>
                            <div className="OSB-box-text">We use multiple channels for making the learning process effective. We use Text, voice and video
    options so that the preferences of different learners can be accommodated.</div>
                        </div>			
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-heading">Collaborative Platform</div>
                            <div className="OSB-box-text">We provide you Collaborative learning Platform through range of interfaces and learning tools.</div>
                        </div>
                    </div>
                </div>
            </section>   


            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />


        </>
    )
}   
export default OnlineTutoringArea;  