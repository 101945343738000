import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser'


import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HireDeveloperArea = () => {

    const [phoneNumer, setPhoneno] = useState("")
    const handleClick = () => {
        window.scrollTo(0,0);
    }
    const { register, formState: { errors } } = useForm();
    console.log("errors", errors)
    const Emailjs = async(e) => {
        e.preventDefault()
		emailjs.sendForm('service_9it01ho', 'template_5vhun9p', e.target, '4asUevuMKgm5E7Pwf')
        .then((result) => {
			notify()
			e.target.reset();
            console.log(result, "Successfully mail send of hire developer");
        }, (error) => {
            console.log(error.text);
        })
    };



    const [modalShow1, setmodalShow1] = useState(false);
    const [modalShow2, setmodalShow2] = useState(false);

    useEffect(() => {
        function handleMouseMove(event) {
          document.querySelectorAll(".shape-1").forEach((shift) => {
            const position = shift.getAttribute("value");
            const x = (window.innerWidth - event.pageX * position) / 90;
            const y = (window.innerHeight - event.pageY * position) / 90;
    
            shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
          });
        }
    
        document.addEventListener("mousemove", handleMouseMove);
    
        return () => {
          document.removeEventListener("mousemove", handleMouseMove);
        };
      }, []);

const notify = () => toast("Mail has been sent successfully!");

    return (
        <>
            <ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>

            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Easy to hire</div>
                                <h1>Recruit your ideal team with us</h1>
                                <p>Built your vision with Ezzeex. Get a team that will develop custom solutions for you. Well-rounded solutions are the key to build any product that you want.</p>
                                <p>Find a team of talented individuals to help you drive your company's technology initiatives forward. Build innovative solutions, design technology infrastructures and implement new software & systems to support your business operations.</p>
                                
                                <div className="banner-btn-area">
                                <Button type="button" className="custom-btn btn-primary" onClick={() => setmodalShow1(true)}>
                                    Hire Developer                                    
                                </Button>

                                <Modal size="lg" show={modalShow1} onHide={() => setmodalShow1(false)}
                                    aria-labelledby="example-modal-sizes-title-sm" dialogClassName="hiringPopup">                                    
                                    <Modal.Body className="hiring-modal-body">
                                    <div class="hiring-modal-area hiringDeveloper-modal">
                                        <div class="hiring-modal-head">
                                            <h3>Hire Developer</h3>                                           
                                            <Modal.Header closeButton></Modal.Header>
                                        </div>
                                        
                                        <div class="job-info-area">
                                            <h4>Hire Developer</h4>
                                            <p>By Hiring our team, you can rest assured that you will have access to the finest talent in the industry. Our experts belong to the top 1% and possess a wealth of knowledge and expertise. They bring their exceptional skills to each project, guaranteeing that your business achieves its goals and soars to new levels of success.</p>
                                            <div class="job-apply-form">
                                                <form onSubmit={(e) => Emailjs(e)}>	
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-12">
                                                            <div class="FQA-form-box">
                                                                <div class="FQAFB-form-icon"><img src="images/user-icon.svg" alt="" /></div>
                                                                <div class="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Client Name*" {...register("name")} required/></div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-md-12">
                                                            <div class="FQA-form-box">
                                                                <div class="FQAFB-form-icon"><img src="images/email-icon.svg" alt="" /></div>
                                                                <div class="FQAFB-form-input">
                                                                <Form.Control type="email" placeholder="Email Address*" {...register("email")} required/></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                    <div class="col-lg-6 col-md-12">
                                                            <div class="FQA-form-box">
                                                                <div class="FQAFB-form-icon"><img src="images/call-icon.svg" alt="" /></div>
                                                                <div class="FQAFB-form-input">
                                                                 <Form.Control type="text" placeholder="Mobile Number" {...register("Phone")} required/></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-12">
                                                            <div class="FQA-form-box">
                                                                <div class="FQAFB-form-icon"><img src="images/country-icon.svg" alt="" /></div>
                                                                <div class="FQAFB-form-input">
                                                                <Form.Control type="text" placeholder="Country" {...register("Country")} required/></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="FQA-form-box">
                                                                <div class="FQAFB-form-icon">
                                                                    <img src="images/grid-icon.svg" alt="" />
                                                                </div>
                                                                <div class="FQAFB-form-select">
                                                                    <select {...register("Interest")} required>
                                                                        <option>Intrested in*</option>
                                                                        <option>Web Development</option><option>Mobile Development</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="FQA-form-box FQA-form-textarea">
                                                                <div class="FQAFB-form-icon">
                                                                    <img src="images/message-icon.svg" alt="" />
                                                                </div>
                                                                <div class="FQAFB-formTextarea">
                                                                    <textarea placeholder="Messages*" class="form-control" {...register("messages")} required></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-lg-12 mb-4">
                                                            <div class="FQA-footer form-apply-btn">
                                                                <div class="FQA-footer-btn">
                                                                   
																	<button type="submit" class="custom-btn">Apply Now</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                </form>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </Modal.Body>
                                </Modal>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right">
                                <img src="images/hire-developer-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />

            <section className="whoWe-are-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left">
                                <h2>Why us? Because we unify diversity.</h2>
                                <h3>We are an experienced and leading company in the technical domain. Collaborate with us and accomplish all your goals with the best IT team!</h3>
                                <p>Our experience in the market has helped us understand the importance of happy customers and growth. To achieve this in today’s hi-tech world, a mixed bag of multi-talented people is required.</p>
                                <p>With us, you get to work with a unit which is diverse enough to handle clients and integrate the latest technology.</p>
                                <div className="WWA-left-highlight">
                                    <div className="WWAL-highlight-box">
                                        <div className="WWAL-highlight-img">
                                            <img src="images/hire-developer-budget-icon.svg" alt="" />
                                        </div>
                                        <div className="WWAL-highlight-cont">
                                            <h4>Handpicked team after extensive assessment</h4>
                                        </div>
                                    </div>

                                    <div className="WWAL-highlight-box">
                                        <div className="WWAL-highlight-img">
                                            <img src="images/hire-developer-team-icon.svg" alt="" />
                                        </div>
                                        <div className="WWAL-highlight-cont">
                                            <h4>Well-connected web communication</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-img" src="images/why-HireDeveloper-1.jpg" alt="" />
                                <img className="WWA-right-img2" src="images/why-HireDeveloper-2.jpg" alt="" />
                                <div className="shape-group">
                                    <ul>
                                        <li className="shape-1" value="3"><img src="images/round-dots-black.svg" alt="" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-box development-team-service mt100">	
                <div className="container">
                    <div className="custom-heading">
                        <label>There are many variations</label>
                        <div className="custom-heading-text">1 Team 1 Vision</div>
                        <p>One team, one vision is simple but powerful Motto which is followed by our team. This has kept up at the top of our game.</p>
                        <p>For achieving any goal one has to have the right kind of support and this is only possible when a team is wholeheartedly working with you.</p>
                    </div>
                    
                    <div className="appDev-offer-area">
                        <div className="appDev-offer-inner">
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/hire-IT-Staff-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    {/* <h4>IT Staff Augmentation</h4> */}
                                    <p>A well-functioning IT infrastructure is important for a business because it is responsible for maintaining and managing the technology infrastructure that supports the organization's operations and goals. They ensure that the technology systems are reliable, secure, available to all users, improve productivity, communication and decision-making. Additionally, they help in identifying and implementing new technology solutions that can improve the efficiency and effectiveness of the business.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/hire-dedicated-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    {/* <h4>Dedicated Development Team</h4> */}
                                    <p>A hand-picked IT team can be a good option because it allows for the selection of individuals with specific skills, experience, and personalities that align with the needs and goals of the organization. This can lead to a more cohesive and effective team that is better able to address the unique challenges and opportunities facing the business. Hand-picked team members also allow for creating a diverse team with various perspectives and ideas, which can lead to more innovative and effective solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whoWe-are-area mt100">	
                <div className="container">
                    <div className="custom-heading">
                        <label>Advantages of Hiring</label>
                        <div className="custom-heading-text">Achieve unparalleled success with the team of EzzEEx</div>
                        <p>In the current technical map, adapting is the mantra of success. With the line-up of experienced industry professionals, smash through all your goals. Trust us, as we bring you the best talent for your vision.</p>
                    </div>
                    
                    <div className="advantage-area">
                        <div className="advantage-area-inner">
                            <div className="advantage-area-box">
                                <ul>
                                    <li>
                                        <div className="advantage-area-img">
                                            <img src="images/advantage-devloper-icon1.svg" alt="" />
                                            <span>Get the expertise of decades at your fingertips</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="advantage-area-img">
                                            <img src="images/advantage-devloper-icon6.svg" alt="" />
                                            <span>Collaborate with bright minds of the future</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="advantage-area-img">
                                            <img src="images/advantage-devloper-icon3.svg" alt="" />
                                            <span>Technical wizards who are readily available</span>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            
                            <div className="advantage-area-box AAB-advantage-mid">
                                <div className="AAB-advantageMid-img"><img src="images/advantage-hire-developer.svg" alt="" /></div>
                            </div>
                            
                            <div className="advantage-area-box">
                                <ul>
                                    <li>
                                        <div className="advantage-area-img">
                                            <img src="images/advantage-devloper-icon5.svg" alt="" />
                                            <span>Skill sets of all the team members’ are applied and enhanced</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="advantage-area-img">
                                            <img src="images/advantage-devloper-icon4.svg" alt="" />
                                            <span>Being on time is a key fundamental</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="advantage-area-img">
                                            <img src="images/advantage-devloper-icon2.svg" alt="" />
                                            <span>Handpicked team for a smooth workflow</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                
                
                
            </section>

            <section className="bg-box mt100">	
                <div className="container">
                    <div className="custom-heading">
                        <label>Technology Stack</label>
                        <div className="custom-heading-text">We serve in diverse technical verticals</div>
                        <p>Over the years, we have collaborated with multiple experts and companies and delivered cutting-edge results. It has not only helped us and our client grows, but it has also assisted to expand our area of expertise.</p>
                    </div>
                    
                    <div className="itSolution-platforms-area">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="FrontDeveloper-tab">
                            <Row>
                                <Col sm={12}>
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="FrontDeveloper-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/technology-stack-frontend-developer.svg" alt="" />
                                                </div>
                                                <p>Frontend</p>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="backendDev-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/technology-stack-backend-developer.svg" alt="" />
                                                </div>
                                                <p>Backend</p>
                                            </div> 
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="cloudDev-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/technology-stack-cloud.svg" alt="" />
                                                </div>
                                                <p>Cloud and DevOps</p>
                                            </div>  
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="mobility-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/technology-stack-mobility.svg" alt="" />
                                                </div>
                                                <p>Mobility</p>
                                            </div>  
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="DataBase-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/technology-stack-database.svg" alt="" />
                                                </div>
                                                <p>Database</p>
                                            </div> 
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="Testing-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/technology-stack-testing.svg" alt="" />
                                                </div>
                                                <p>Testing</p>
                                            </div> 
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="Other-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/technology-stack-other.svg" alt="" />
                                                </div>
                                                <p>Others</p>
                                            </div> 
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="FrontDeveloper-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">First impression is a key! Let us help you in creating an attractive and unique Frontend experience. From eye-grabbing visuals to smooth access, this will get your product on the front row.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/wordpress-icon.png" alt="" /></div>
                                                        <p>Woocommerce</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/magento-icon.png" alt="" /></div>
                                                        <p>Magento</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/drupal-icon.png" alt="" /></div>
                                                        <p>Drupal</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/joomla-icon.png" alt="" /></div>
                                                        <p>Joomla</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/opencart-icon.png" alt="" /></div>
                                                        <p>Opencart</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/prestashop-icon.png" alt="" /></div>
                                                        <p>PrestaShop</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="backendDev-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">To build anything that would survive for a long period, one needs to have a powerful base. Our team will make sure that the three legs of the back end which are the server, app and database are working in sync.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/cake-php.png" alt="" /></div>
                                                        <p>CakePHP</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/yii-icon.png" alt="" /></div>
                                                        <p>Yii</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/zend-icon.png" alt="" /></div>
                                                        <p>Zend</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/codeigniter-icon.png" alt="" /></div>
                                                        <p>Codeigniter</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/symfony-icon.png" alt="" /></div>
                                                        <p>Symfony</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/laravel-icon.png" alt="" /></div>
                                                        <p>Laravel</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="cloudDev-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">We understand that no tech is complete without the inclusion of Cloud and DevOps at the current time. Be assured that the best resources will be retrieved from the internet through web-based tools and applications to make the product life cycle shorter and easier.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/reactjs-icon.png" alt="" /></div>
                                                        <p>ReactJS</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/angularjs-icon.png" alt="" /></div>
                                                        <p>AngularJS</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/nodejs-icon.png" alt="" /></div>
                                                        <p>Node.js</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/jQuery.png" alt="" /></div>
                                                        <p>jQuery</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="mobility-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">Be assured that all products and deliverables will be high on the scale of mobility as the mode of consuming media has diversified. Experts make sure that everyone can access your product at the touch of their finger. All screen sizes and software updates are included.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/html5-icon.png" alt="" /></div>
                                                        <p>HTML5</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/css3-icon.png" alt="" /></div>
                                                        <p>CSS3</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/bootstrap-icon.png" alt="" /></div>
                                                        <p>Bootstrap</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="DataBase-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">Data is king. In today's field, if one knows how to leverage data properly then they are already 4 steps ahead! With our dedicated team be ready to move forward at a lightning speed.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/html5-icon.png" alt="" /></div>
                                                        <p>HTML5</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/css3-icon.png" alt="" /></div>
                                                        <p>CSS3</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/bootstrap-icon.png" alt="" /></div>
                                                        <p>Bootstrap</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Testing-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">A good professional understands the value of testing. Every product requires vigorous testing before being put out in the market. The professionals understand its value; hence they leave ample time beforehand. We make sure that no errors go forward to the public.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/html5-icon.png" alt="" /></div>
                                                        <p>HTML5</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/css3-icon.png" alt="" /></div>
                                                        <p>CSS3</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/bootstrap-icon.png" alt="" /></div>
                                                        <p>Bootstrap</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Other-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">Many other important services are included. They are required a small amount of action but their significance is equally important. A talented team understand this. You will be amazed when areas like network security, backup, recovery and integration are handled seamlessly.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/html5-icon.png" alt="" /></div>
                                                        <p>HTML5</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/css3-icon.png" alt="" /></div>
                                                        <p>CSS3</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/bootstrap-icon.png" alt="" /></div>
                                                        <p>Bootstrap</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>	
                </div>
            </section>	

            <section className="whoWe-are-area mt100">	
                <div className="container">
                    <div className="custom-heading">
                        <label>Flexible Engagement Model</label>
                        <div className="custom-heading-text">Work with your dream team today!</div>
                        <p>What is a dream team? It is a group of individuals who work together and develop creative solutions. Do you need a team that will give your vision shape and pull you out of knee-deep situations?</p>
                        <p>Then get in touch with us! We will provide you a team of professionals who are perfect for your job!</p>
                    </div>
                    
                    <div className="hire-step-area">
                        <div className="hire-step-box">
                            <div className="hire-step-dot"></div>
                            
                            <div className="hire-step-content">
                                <div className="HSC-step-index">1</div>
                                <div className="HSC-step-title">Understanding your requirement</div>
                                <p>Our first step is focused on understanding your goals. We must start in the right direction.</p>
                                <p>All needs and goals will be discussed in detail so that the best set of teams can be brought together.</p>
                            </div>
                            
                            <div className="hire-step-img">
                                <img src="images/hire-step-1.svg" alt="" />
                            </div>
                        </div>
                        
                        <div className="hire-step-box">
                            <div className="hire-step-dot"></div>
                            
                            <div className="hire-step-content">
                                <div className="HSC-step-index">2</div>
                                <div className="HSC-step-title">Evaluation</div>
                                <p>After determining the specific IT tasks and skills required for your project or organization, now we focus on finding the professionals.</p>
                                <p>We gauge the scope of work, timelines, deliverables, and payment terms with the person for hire beforehand to avoid any issues in the future.</p>
                            </div>
                            
                            <div className="hire-step-img">
                                <img src="images/hire-step-evaluation.svg" alt="" />
                            </div>
                        </div>
                        
                        <div className="hire-step-box">
                            <div className="hire-step-dot"></div>
                            
                            <div className="hire-step-content">
                                <div className="HSC-step-index">3</div>
                                <div className="HSC-step-title">The technical precursor</div>
                                <p>Before the final selection is done, the team list is sent for an overview. This helps in having an oversight of what kind of team you are going to collaborate.</p>
                            </div>
                            
                            <div className="hire-step-img">
                                <img src="images/hire-step-2.svg" alt="" />
                            </div>
                        </div>
                        
                        <div className="hire-step-box">
                            <div className="hire-step-dot"></div>
                            <div className="hire-step-dot HST-bottom-dot"></div>
                            
                            <div className="hire-step-content">
                                <div className="HSC-step-index">4</div>
                                <div className="HSC-step-title">Hello Onboard!</div>
                                <p>After the final review, a dedicated team is decided who will work on your project. They will begin the process of integrating with your organization.</p>
                                
                                <div className="banner-btn-area">
                                        <Button type="button" className="custom-btn btn-primary" onClick={() => setmodalShow1(true)}>
                                        Hire Developer                                    
                                    </Button>
                                </div>
                            </div>
                            
                            <div className="hire-step-img">
                                <img src="images/hire-step-3.svg" alt="" />
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </section>

            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />
            
        </>
    )
}   
export default HireDeveloperArea;  