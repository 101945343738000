import React, { useEffect } from "react";



import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'
import { FiCheckCircle } from "react-icons/fi";

const UiUxDesign = () => {
    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Craft your Digital Future with</div>
                                <h1>We transform your craziest ideas into perfect designs</h1>
                                <p>EZZEEX is one of the leading business solution service providers in India that offers best and cost-effective IT solutions through our wide-range products.</p>
                                
                                <div className="banner-btn-area">
                                    <a href="#Free_quote" className="custom-btn btn-primary">Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right">
                                <img src="images/ui-ux-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="UiUx-explain-area">	
                <div className="container">
                    <div className="UiUx-explain-mid">
                        <div className="UiUx-text1">UI Design</div>
                        <div className="UiUx-text2">UX Design</div>
                        <div className="UiUx-text3">Empathy, Collaboration, Design Thinking, Prototyping</div>
                        <div className="UiUx-text4">Colour Theory, Typography, Deign Patterns, Animation</div>
                        <div className="UiUx-text5">Product Strategy, User Research, Information, Testing & Iteration</div>
                    </div>
                </div>
            </section>


            <section className="whoWe-are-area UiUx-build-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left product-service-box p-0">
                                <div className="custom-heading left-heading">
                                    <label>Our Web Development</label>
                                    <div className="custom-heading-text">We build UI / UX design as per the user’s viewpoint & offerings</div>
                                    <p>We have a team of the most talented and highly-experienced programmers and designers who are passionate about the digital technologies and innovations and provide the designs while understanding user’s point of view.</p>
                                </div>
                                <ul>
                                <li><FiCheckCircle className="yellowCheck" /> Custom Web Design / Mobile App Design</li>
                                <li><FiCheckCircle className="yellowCheck" /> Corporate Branding & Graphics Design</li>
                                <li><FiCheckCircle className="yellowCheck" /> Front End Development / Portal Development</li>
                                <li><FiCheckCircle className="yellowCheck" /> Wireframing/ Wearables App Design</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-imgdefault" src="images/ui-ux-build-img.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />

            <section className="our-service-area card-two">
                <div className="container">
                    <div className="custom-heading">
                        <label>Our Services</label>
                        <div className="custom-heading-text">We Are Offering All Kinds of UI/UX Solution</div>
                        <p>EZZEEX is a reputable and responsible web solution provider and our team well understands the significance of UI/UX solution for the success of your business. Our UI/UX Solution will assist you to improve your customer satisfaction and will finally drive company sales.</p>
                    </div>
                    
                    <div className="OS-box-area">                            
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-1.svg" alt="" /></div>
                            <div className="OSB-box-heading">User Research</div>
                            <div className="OSB-box-text">Before initiating towards designing, our professionals conduct detailed qualitative research about the user and market perspective to better understand multiple perspectives and can develop intuitive and scalable design.  </div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>                   
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-2.svg" alt="" /></div>
                            <div className="OSB-box-heading">User Roadmap Design</div>
                            <div className="OSB-box-text">Our team designs User Roadmap that enables you to gain high-level overview about the plans and goals related to the product user experience and user interface so that the usability of the website or app can be kept on a top priority.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>                   
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-2.svg" alt="" /></div>
                            <div className="OSB-box-heading">Information Architecture</div>
                            <div className="OSB-box-text">We use the best tools and methodologies that can remain appropriate to deliver information. At the same time, it should look organize and can manage the content of your website and applications effectively.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>                    
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-3.svg" alt="" /></div>
                            <div className="OSB-box-heading">Wireframe Designing</div>
                            <div className="OSB-box-text">Our team designs wireframes to present you an outline of mobile or web app to explain the content, structure and functionality that is going to be used for your app and software page development.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>                    
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-4.svg" alt="" /></div>
                            <div className="OSB-box-heading">Prototyping</div>
                            <div className="OSB-box-text">Prototyping helps to structure the app and website and reveals what will be on the pages so that you can get the opportunity at the early-stage to test the functionality of UI/UX. </div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>                    
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-5.svg" alt="" /></div>
                            <div className="OSB-box-heading">Visual Design</div>
                            <div className="OSB-box-text">We focus to make the user interface of your website or applications that remain not only highly responsive but also visually appealing too and can attract the attention of potential customers.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>                                
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-6.svg" alt="" /></div>
                            <div className="OSB-box-heading">Usability Testing</div>
                            <div className="OSB-box-text">We utilize advanced testing methodologies to assure that the UI/UX solutions remain highly-responsive and fully-functional. We also test proactive safety and performance optimization strategies.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>                    
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-5.svg" alt="" /></div>
                            <div className="OSB-box-heading">Heuristic Review</div>
                            <div className="OSB-box-text">We bring our best efforts to creatively utilize your ideas to design clear and fully functional website or app. We focus on the core principles of UI / UX design that help to improve the functionality and allows delivering cross-platform compatible designs.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>                                
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-6.svg" alt="" /></div>
                            <div className="OSB-box-heading">Production Support</div>
                            <div className="OSB-box-text">We help your web applications to stay relevant by adapting and addressing changing business requirements. </div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>
                                                    
                    </div>
                    
                </div>
            </section>

            <section className="whoWe-are-area left-imgBox UiUX-strategy-box">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-imgdefault" src="images/ui-ux-strategy-img.png" alt="" />
                            </div>	
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left">
                                <div className="custom-heading left-heading">
                                    <label>Our Web Development</label>
                                    <div className="custom-heading-text">We build UI / UX design as per the user’s viewpoint & offerings</div>
                                </div>
                                <p>To understand the user’s viewpoint, we initiate the designing work with market research and then utilize mixture of technology and art to create lucrative UI / UX design that can captivate your customers with elegance. We also focus towards style of visuals, sounds, contents and interactions to provide effective solutions. Concern towards developing creative vision with the modern technology renders a complete user satisfaction experience.</p>
                                <p>Our team imagines, creates and designs UI / UX products that have the potential to address industry-specific needs. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />
            
        </>
    )
}   
export default UiUxDesign;  