import React, { useEffect } from "react";



import CompanyCounterArea from './Common/companyCounter'
import TestimonialArea from './Common/testimonial'
import { FiCheckCircle } from "react-icons/fi";





const LearningIntershipArea = () => {
    useEffect(() => {
        function handleMouseMove(event) {
          document.querySelectorAll(".shape-1").forEach((shift) => {
            const position = shift.getAttribute("value");
            const x = (window.innerWidth - event.pageX * position) / 90;
            const y = (window.innerHeight - event.pageY * position) / 90;
    
            shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
          });
        }
    
        document.addEventListener("mousemove", handleMouseMove);
    
        return () => {
          document.removeEventListener("mousemove", handleMouseMove);
        };
      }, []);
      


    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Learn without limits</div>
                                <h1>Find Your Best Courses to Develop your skills</h1>
                                <p>We have variety of courses available from which you can select course that can best fit to you. We have designed the courses in a manner that you will become able to develop skills and will become job-ready. It will help you to start your career on the right foot!</p>
                                
                                <div className="banner-btn-area">
                                    <a href="#Free_quote" className="custom-btn btn-primary">Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right">
                                <img src="images/learning-intership-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-TWO-area mt140">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="STA-box">
                                <div className="STA-box-img">
                                    <img src="images/learn-skills-icon.svg" alt="" />
                                </div>
                                <div className="STA-box-cont">
                                    <h5>Learn Skills</h5>
                                    <p>with unlimited courses</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-12">
                            <div className="STA-box">
                                <div className="STA-box-img">
                                    <img src="images/expert-teachers-icon.svg" alt="" />
                                </div>
                                <div className="STA-box-cont">
                                    <h5>Expert Teachers</h5>
                                    <p>Best &amp; highly qualified</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-12">
                            <div className="STA-box">
                                <div className="STA-box-img">
                                    <img src="images/certificates-icon.svg" alt="" />
                                </div>
                                <div className="STA-box-cont">
                                    <h5>Certificates</h5>
                                    <p>value all over the world</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whoWe-are-area mt140">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left">
                                <h2>Best Online Courses From EZZEEX</h2>
                                <p>You can join our online classes to get unbeatable advantage for your career development. We are home for the fastest-growing companies that seek talent pool. Current Employers prefer to hire those employees who are experienced with new way of working.  We guarantee you ideal internship program that can align with not only your career field but also with your location, timeframe and comfort.</p>
                                <div className="check-list">
                                    <ul>
                                    <li><FiCheckCircle className="yellowCheck" /> Digital Marketing</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Full Stake Development</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Backend Development</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Data Science With R, Python, SQL & Tableau</li>	
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-img" src="images/learning-ezzeex2.jpg" alt="" />
                                <img className="WWA-right-img2" src="images/learning-ezzeex1.jpg" alt="" />
                                <div className="leaning-trusted">Trusted by 8000+ Students</div>
                                <div className="shape-group">
                                    <ul>
                                        <li className="shape-1" value="3"><img src="images/round-dots-black.svg" alt="" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="choose-us-sec mt140">	
                <div className="container">	
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="choose-us-heading">
                                <span>Why Choose EZZEEX Learning?</span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-12">
                            <div className="choose-us-box">
                                <div className="CUB-box-img"><img src="images/Superior-Curriculum.svg" alt="" /></div>
                                <div className="CUB-box-heading">Superior Curriculum</div>
                                <div className="CUB-box-pera">Our Learning & Internship Programs are designed in a manner that it is worthy for students, graduates and career switchers to expand their professional skills.</div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-12">
                            <div className="choose-us-box">
                                <div className="CUB-box-img"><img src="images/Expert-Tutors.svg" alt="" /></div>
                                <div className="CUB-box-heading">Expert Tutors</div>
                                <div className="CUB-box-pera">Our Expert tutors will help you to pursuing your dream career and will enable you to stand distinguish in the crowd. </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="choose-us-box">
                                <div className="CUB-box-img"><img src="images/Inspiring-Surroundings.svg" alt="" /></div>
                                <div className="CUB-box-heading">Inspiring Surroundings</div>
                                <div className="CUB-box-pera">Our Experts will suggest you the career paths based on your interests that will inspire you towards the attainment of your professional goal.</div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-12">
                            <div className="choose-us-box">
                                <div className="CUB-box-img"><img src="images/Experience-Lifetime.svg" alt="" /></div>
                                <div className="CUB-box-heading">Experience of a lifetime</div>
                                <div className="CUB-box-pera">Our Expert Tutors are dedicated to build your professional skills matched to your desired career ambitions at the comfort of your own home.</div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-12">
                            <div className="choose-us-box">
                                <div className="CUB-box-img"><img src="images/Exceptional-Support.svg" alt="" /></div>
                                <div className="CUB-box-heading">Exceptional Support System</div>
                                <div className="CUB-box-pera">We will guarantee you the success in your career through our exceptional support system. </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="professional-certificates-sec mt100 mb100">
                <div className="container">
                    <div className="custom-heading">
                        <label>Professional Certificates</label>
                        <div className="custom-heading-text">Get job-ready for an in-demand career</div>
                        <p>During the online courses, our tutors give tasks to the students that are actually the replica of original official work that assists the students to get ready for professional career. </p>
                    </div>
                    
                    <div className="PCS-area">
                        <div className="PCSA-cer-box">
                            <div className="PCSA-cer-img">
                                <img src="images/learning-progress1.jpg" alt="" />							
                            </div>						
                            <div className="PCSA-cer-title">
                                <div className="PCSA-cer-logo"><img src="images/learning-google-logo.jpg" alt="" /></div>
                                Google Data Analytics
                            </div>
                            <div className="PCSA-cer-company">Google</div>
                        </div>
                            
                        <div className="PCSA-cer-box">
                            <div className="PCSA-cer-img">
                                <img src="images/learning-progress2.jpg" alt="" />                    
                            </div>						
                            <div className="PCSA-cer-title">
                                <div className="PCSA-cer-logo"><img src="images/learning-meta-logo.png" alt="" /></div>
                                Meta Social Media Marketing
                            </div>
                            <div className="PCSA-cer-company">Meta</div>
                        </div>
                            
                        <div className="PCSA-cer-box">
                            <div className="PCSA-cer-img">
                                <img src="images/learning-progress3.jpg" alt="" />                    
                            </div>						
                            <div className="PCSA-cer-title">
                                <div className="PCSA-cer-logo"><img src="images/learning-ibm-logo.png" alt="" /></div>
                                IBM Cybersecurity Analyst
                            </div>
                            <div className="PCSA-cer-company">IBM</div>
                        </div>
                            
                        <div className="PCSA-cer-box">
                            <div className="PCSA-cer-img">
                                <img src="images/learning-progress4.jpg" alt="" />                    
                            </div>						
                            <div className="PCSA-cer-title">
                                <div className="PCSA-cer-logo"><img src="images/learning-google-logo.jpg" alt="" /></div>
                                Google UX Design
                            </div>
                            <div className="PCSA-cer-company">Google</div>
                        </div>
                    </div>
                    
                    <div className="PCS-area">
                        <div className="PCSA-cer-box">
                            <div className="PCSA-cer-img">
                                <img src="images/learning-progress4.jpg" alt="" />                    
                            </div>						
                            <div className="PCSA-cer-title">
                                <div className="PCSA-cer-logo"><img src="images/learning-google-logo.jpg" alt="" /></div>
                                Google UX Design
                            </div>
                            <div className="PCSA-cer-company">Google</div>
                        </div>
                        
                        <div className="PCSA-cer-box">
                            <div className="PCSA-cer-img">
                                <img src="images/learning-progress3.jpg" alt="" />                    
                            </div>						
                            <div className="PCSA-cer-title">
                                <div className="PCSA-cer-logo"><img src="images/learning-ibm-logo.png" alt="" /></div>
                                IBM Cybersecurity Analyst
                            </div>
                            <div className="PCSA-cer-company">IBM</div>
                        </div>
                            
                        <div className="PCSA-cer-box">
                            <div className="PCSA-cer-img">
                                <img src="images/learning-progress2.jpg" alt="" />                    
                            </div>						
                            <div className="PCSA-cer-title">
                                <div className="PCSA-cer-logo"><img src="images/learning-meta-logo.png" alt="" /></div>
                                Meta Social Media Marketing
                            </div>
                            <div className="PCSA-cer-company">Meta</div>
                        </div>				
                        
                            
                        <div className="PCSA-cer-box">
                            <div className="PCSA-cer-img">
                                <img src="images/learning-progress1.jpg" alt="" />							
                            </div>						
                            <div className="PCSA-cer-title">
                                <div className="PCSA-cer-logo"><img src="images/learning-google-logo.jpg" alt="" /></div>
                                Google Data Analytics
                            </div>
                            <div className="PCSA-cer-company">Google</div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterArea />

            <section className="development-team-service mt100">	
                <div className="container">
                    <div className="DIS-inner-area">
                        <div className="DIS-inner-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/learning-col-img1.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Lorem Ipsum dummy</label>
                                            <div className="custom-heading-text">Launch your IT career with valuable industry experience.</div>
                                        </div>
                                        <p>Our internship program is specifically designed to provide freshers and IT professionals with valuable industry experience and pave the way for a successful career in the ever-evolving field of technology. </p>
                                        
                                        <p>We offer a structured and comprehensive internship curriculum that focuses on skill development, hands-on training, and exposure to real-world projects. As an intern, you will have the opportunity to work alongside experienced professionals, collaborate on challenging assignments, and gain practical insights into various IT domains. Whether you are a recent graduate or looking to enhance your skills, our internship program will provide you with the necessary foundation to excel in the competitive IT industry and unlock exciting career opportunities.</p>
                                    </div>
                                </div>
                            </div>				
                        </div>
                    </div>
                    
                    <div className="DIS-inner-area">
                        <div className="DIS-inner-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Social Media &amp; CV/Profile Writing</label>
                                            <div className="custom-heading-text">Learn Directly From Experts</div>
                                            <p>Our Experts will help you to gain the skills that are essential to become successful in a particular
            niche. They will also teach you relevant tools that are necessary to accomplish day to day tasks in
            your chosen field. Our Learning &amp; Internship Programs can be joined by:</p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Students</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Graduates</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Education Partners</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Host Companies</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/learning-col-img2.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>			
                </div>
            </section>           


            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />


        </>
    )
}   
export default LearningIntershipArea;  