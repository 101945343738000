import React from "react";



import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'
import { FiCheckCircle } from "react-icons/fi";

const CustomerSoftwareAppDevelopmentArea = () => {
    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Capabilities</div>
                                <h1>Mobile App Development Services</h1>
                                <p>We deliver mobile app development services that will help you to create your brand positioning in domestic, local and global market. We build superior digital products so that seamless user experiences can be enabled across all digital platforms as well as devices. We offer full cycle of application design while involving integration and management services.</p>
                                
                                <div className="banner-btn-area">
                                    <a href="#Free_quote" className="custom-btn btn-primary">Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right">
                                <img src="images/app-development-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whoWe-are-area UiUx-build-area appService-main mt100">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="app-devService-area">
                                <div className="app-devService-inner">
                                    <div className="app-devBox">
                                        <img src="images/app-ios-icon.svg" alt="" />						
                                    </div>
                                    <div className="app-devBox">
                                        <img src="images/app-android-icon.svg" alt="" />						
                                    </div>
                                    <div className="app-devBox">
                                        <img src="images/app-swift-icon.svg" alt="" />						
                                    </div>
                                    <div className="app-devBox">
                                        <img src="images/app-react-native-icon.svg" alt="" />						
                                    </div>
                                    <div className="app-devBox">
                                        <img src="images/app-xamarin-icon.svg" alt="" />						
                                    </div>
                                    <div className="app-devBox">
                                        <img src="images/app-flutter-icon.svg" alt="" />						
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left">
                                <div className="custom-heading left-heading">
                                    <label>Lorem Ipsum</label>
                                    <div className="custom-heading-text">Expertise with Major Mobile App Development Technologies</div>
                                </div>
                                <p>We have expertise with major mobile app development technologies with expressive and feature-rich designs. It will help to delight your users with expressive and feature-rich technologies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whoWe-are-area mt100">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left product-service-box p-0">
                                <div className="custom-heading left-heading">
                                    <label>What is Lorem Ipsum?</label>
                                    <div className="custom-heading-text">How Significant Is Mobile App Development for Your Business?</div>
                                    <p>Mobile app is a great way to influence customer loyalty and persuade sales and growth of your business. Mobile app offers a platform to the consumers that support them to make the purchase of products or services easier. Mobile app increases the convenience and comfort of purchasing for the consumers that further helps to boost revenue generation. Mobile app improves brand advocacy and referral rates.</p>
                                    
                                    <p>Moreover, it offers the platform for encourage two-way communication between you and your consumers and also supports to showcase launch of new product range in real time.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-imgdefault" src="images/app-significant-mobile.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what-do-area mt100">	
                <div className="container">
                    <div className="custom-heading">
                        <label>What Industries Do Our</label>
                        <div className="custom-heading-text">Mobile Application Development Experts Cover?</div>
                        <p>We offer our service for the businesses that are dealing in different industries. Our high-end mobile app development services can be availed by anyone.</p>
                    </div>
                    
                    <div className="appDev-offer-area appDev2">
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-Ewallet-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Enterprise Mobile Apps</h4>
                                    <p>We offer complete scalable and functional mobile application for customers, employees and partners.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-food-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Mobile Game Apps</h4>
                                    <p>Mobile Game Apps remains supportive to create best user interface and deployability.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-education-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Sports Mobile Apps</h4>
                                    <p>We will help you to design Sports Mobile App that can offer the facility of live screening, ticketing, chat, newsfeed, etc.</p>
                                </div>
                            </div>
                            
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-ecommerce-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Social Mobile Apps</h4>
                                    <p>Our Social Mobile App will help you to stay connected with your target audience.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-travel-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Custom Mobile Apps</h4>
                                    <p>We know that every business has its distinct user base and unique need. So, we custom made it to make it fit to your brand.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-healthcare-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>E-Learning Mobile Apps</h4>
                                    <p>Our interactive, personalized, and responsive eLearning Mobile App ensures to offer best user experience.</p>
                                </div>
                            </div>
                            
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-realEstate-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Ecommerce Mobile Apps</h4>
                                    <p>In our Ecommerce Mobile Apps, we offer features from browsing to saving in wishlist, adding into the cart, buying products, and different payment modes.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-entertainment-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Entertainment Mobile Apps</h4>
                                    <p>We specialize in entertainment mobile app development, creating engaging and immersive experiences that captivate users and bring entertainment to their fingertips.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-insurance-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Food and Beverages Apps</h4>
                                    <p>We offer the facility of tracking, online payment and many other best user experience features.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-realEstate-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Real Estate Apps</h4>
                                    <p>We offer the facility of effective filters and categories, listings and database, etc.</p>
                                </div>
                            </div>
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/app-insurance-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Insurance</h4>
                                    <p>We offer admin panel, policy details, payment gateway, file and claims and many more aspects related to insurance.</p>
                                </div>
                            </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />

            <section className="whoWe-are-area left-imgBox UiUX-strategy-box">	
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-imgdefault" src="images/native-ios-development.svg" alt="" />
                            </div>	
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left product-service-box p-0">
                                <div className="custom-heading left-heading">
                                    <label>Our mobile tech stack</label>
                                    <div className="custom-heading-text">Native iOS app development</div>
                                </div>
                                <p>We excel in native iOS app development, crafting high-performance and user-friendly applications that harness the full potential of Apple's iOS platform to deliver exceptional experiences to users.</p>
                                <ul>
                                <li><FiCheckCircle className="yellowCheck" /> Packed Native iOS Mobile Applications</li>
                                <li><FiCheckCircle className="yellowCheck" /> Native and iOS Cross-Platform Solutions</li>
                                <li><FiCheckCircle className="yellowCheck" /> Progressive iOS Web App Development</li>
                                <li><FiCheckCircle className="yellowCheck" /> Hybrid iOS Mobile App Development</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mb-5">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left product-service-box p-0">
                                <div className="custom-heading left-heading">
                                    <label>Our mobile tech stack</label>
                                    <div className="custom-heading-text">Native Android app development</div>
                                    <p>The most popular target devices are smartphones, hence we develop native Android app. We create mobile application that is customized and dedicated to Android. Native android applications are built for specific operating system that offers higher user engagement as compare to hybrid apps.</p>
                                    <ul>
                                    <li><FiCheckCircle className="yellowCheck" /> Packed Native Android Mobile Applications</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Native and Android Cross-Platform Solutions</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Progressive Android Web App Development</li>
                                    <li><FiCheckCircle className="yellowCheck" /> Hybrid Android Mobile App Development</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-imgdefault" src="images/native-android-development.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />
            
        </>
    )
}   
export default CustomerSoftwareAppDevelopmentArea;  