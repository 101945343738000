import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import {NavLink} from 'react-router-dom';   

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

const PreviousBtn = (props) => {
    // console.log(props);
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src="images/left-arrow.svg" />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src="images/right-arrow.svg" />
      </div>
    );
  };


const FooterArea = () => {
    const innerref = useRef()
    const [phoneNumer, setPhoneno] = useState("")
    const handleClick = () => {
        window.scrollTo(0,0);
    }
    const { register, formState: { errors }, watch, handleSubmit, reset } = useForm(
        {mode:"onSubmit"}
    );
    console.log(errors, "errors")
    const onSubmit = (data) => {
        console.log("data", data, innerref.current)

        emailjs.sendForm('service_9it01ho', 'template_fx3lxdm', innerref.current , '4asUevuMKgm5E7Pwf')
        .then((result) => {
			notify()
			reset()
            console.log(result, "Successfully mail send");
        }, (error) => {
            console.log(error.text);
        })
    }
    useEffect(()=> {
        console.log(watch())
    }, [watch()])
    
    const notify = () => toast("Mail has been sent successfully!");
    
    const settings = {
        arrows : true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,       
        prevArrow: <PreviousBtn />,
        nextArrow: <NextBtn />,
      };

    return (
        <>
		<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
           <section className="footer-quote-sec" id="Free_quote">	
                <div className="custom-heading">
                    <label>Ready to Serve</label>
                    <div className="custom-heading-text">Ready to build the future together?</div>
                    <p>Relationship with the client is our top priority. A good bond is followed by the desired outcome.</p>
                </div>
                
                <div className="footer-quote-inner">	
                    <div className="container">				
                        <div className="footer-quote-area">
                            <div className="FQA-quote-img"><img src="images/quote-img.svg" alt="" /></div>
                            <div className="row">
                                <div className="col-5">
                                    <div className="FQA-quote-left">
                                        <ul>	
                                            <li>
                                                <div className="FQAquote-left-icon">
                                                    <img src="images/call-email-icon.svg" alt="" />
                                                </div>
                                                <div className="FQAquote-left-text">
                                                    <div className="FAQquote-LT-heading">GENERAL ENQUIRIES</div>
                                                    <div className="FAQquote-LT-email">0141-4821396 | Help@ezzeex.com</div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="FQAquote-left-icon">
                                                    <img src="images/call-email-icon.svg" alt="" />
                                                </div>
                                                <div className="FQAquote-left-text">
                                                    <div className="FAQquote-LT-heading">BUSINESS ENQUIRES</div>
                                                    <div className="FAQquote-LT-email">+91 93519 47088 | Biz@ezzeex.com</div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="FQAquote-left-icon">
                                                    <img src="images/call-email-icon.svg" alt="" />
                                                </div>
                                                <div className="FQAquote-left-text">
                                                    <div className="FAQquote-LT-heading">RECRUITMENTS</div>
                                                    <div className="FAQquote-LT-email">+91 82229-62226 | Hrd@ezzeex.com</div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="FQAquote-left-icon">
                                                    <img src="images/call-email-icon.svg" alt="" />
                                                </div>
                                                <div className="FQAquote-left-text">
                                                    <div className="FAQquote-LT-heading">INTERNATIONAL</div>
                                                    <div className="FAQquote-LT-email">+1 (424) 999-8118 | admin@ezzeex.com </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="FQA-quote-right">
                                    <form onSubmit={handleSubmit(onSubmit)} ref={innerref}>
                        
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className={`FQA-form-box ${errors?.name?.message ? "customErrordiv" : ""}`}>
                                                        <div className="FQAFB-form-icon"><img src="images/user-icon.svg" alt="" /></div>
                                                        <div className={`FQAFB-form-input`}>
                                                            <input type="text" placeholder="Full Name"  className={`form-control ${errors?.name?.message ? "is-invalid customError" : ""}`} {...register("name", {required:"name is required field"})} id="name" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className={`FQA-form-box ${errors?.country?.message ? "customErrordiv" : ""}`}>
                                                        <div className="FQAFB-form-icon"><img src="images/grid-icon.svg" alt="" /></div>
                                                        <div className="FQAFB-form-select">
                                                            <select {...register("country", {required:"Country is a required field"})} id="country" className={` ${errors?.country?.message ? "is-invalid customError" : ""}`} >
                                                            <option value="">Select Country*</option>
                                                            <option value="Afghanistan">Afghanistan</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="American Samoa">American Samoa</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Anguilla">Anguilla</option>
                                                            <option value="Antartica">Antarctica</option>
                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Aruba">Aruba</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh">Bangladesh</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Bermuda">Bermuda</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Bouvet Island">Bouvet Island</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Cape Verde">Cape Verde</option>
                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                            <option value="Central African Republic">Central African Republic</option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Christmas Island">Christmas Island</option>
                                                            <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Congo">Congo</option>
                                                            <option value="Congo">Congo, the Democratic Republic of the</option>
                                                            <option value="Cook Islands">Cook Islands</option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                                            <option value="Croatia">Croatia (Hrvatska)</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czech Republic">Czech Republic</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                            <option value="East Timor">East Timor</option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="France">France</option>
                                                            <option value="France Metropolitan">France, Metropolitan</option>
                                                            <option value="French Guiana">French Guiana</option>
                                                            <option value="French Polynesia">French Polynesia</option>
                                                            <option value="French Southern Territories">French Southern Territories</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Gibraltar">Gibraltar</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Greenland">Greenland</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                            <option value="Guam">Guam</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                                            <option value="Holy See">Holy See (Vatican City State)</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hong Kong">Hong Kong</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="India">India</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Iran">Iran (Islamic Republic of)</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                                            <option value="Korea">Korea, Republic of</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Lao">Lao People's Democratic Republic</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Lebanon">Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Macau">Macau</option>
                                                            <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Martinique">Martinique</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mayotte">Mayotte</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Micronesia">Micronesia, Federated States of</option>
                                                            <option value="Moldova">Moldova, Republic of</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Montserrat">Montserrat</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Namibia">Namibia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherlands">Netherlands</option>
                                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option value="New Caledonia">New Caledonia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="Niue">Niue</option>
                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Palau">Palau</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Philippines">Philippines</option>
                                                            <option value="Pitcairn">Pitcairn</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Reunion">Reunion</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russia">Russian Federation</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
                                                            <option value="Saint LUCIA">Saint LUCIA</option>
                                                            <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option> 
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                                            <option value="Span">Spain</option>
                                                            <option value="SriLanka">Sri Lanka</option>
                                                            <option value="St. Helena">St. Helena</option>
                                                            <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                                            <option value="Swaziland">Swaziland</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syria">Syrian Arab Republic</option>
                                                            <option value="Taiwan">Taiwan, Province of China</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Tanzania">Tanzania, United Republic of</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tokelau">Tokelau</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="United States">United States</option>
                                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                            <option value="Uruguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Vietnam">Viet Nam</option>
                                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                                            <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                                            <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                                            <option value="Western Sahara">Western Sahara</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Serbia">Serbia</option>
                                                            <option value="Zambia">Zambia</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
											
											<div className="col-6">
                                                    <div className={`FQA-form-box ${errors?.email?.message ? "customErrordiv" : ""}`}>
                                                        <div className="FQAFB-form-icon"><img src="images/email-icon.svg" alt="" /></div>
                                                        <div className="FQAFB-form-input">
                                                            <input  name="email" id="email" placeholder="Email ID" {...register("email", {required:"Email is a required field"})} className={`form-control ${errors?.email?.message ? "is-invalid customError" : ""}`}/></div>
                                                    </div>
                                                </div>
                                           
                                                <div className="col-6">
                                                    <div className={`FQA-form-box ${errors?.whatsapp?.message ? "customErrordiv" : ""}`}>
                                                        <div className="FQAFB-form-icon"><img src="images/email-icon.svg" alt="" /></div>
                                                        <div className="FQAFB-form-input">
                                                            <input  name="whatsapp" id="whatsapp" placeholder="Skype ID/ WhatsApp*" {...register("whatsapp", {required:"Skype ID/ WhatsApp is a required field"})} className={`form-control ${errors?.whatsapp?.message ? "is-invalid customError" : ""}`}/></div>
                                                    </div>
                                                </div>
												 </div>
												 

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className={`FQA-form-box  FQA-form-number ${errors?.phone?.message ? "customErrordiv" : ""}`} >
                                                    <PhoneInput country={'us'}  onChange={(value)=> setPhoneno(value)}  inputProps={{name: 'phone',id: 'phone'}}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className={`FQA-form-box ${errors?.Interest?.message ? "customErrordiv" : ""}`} >
                                                        <div className="FQAFB-form-icon"><img src="images/grid-icon.svg" alt="" /></div>
                                                        <div className="FQAFB-form-select">
                                                            <select {...register("Interest", {required:"Interest is a required field"})} className={`form-control ${errors?.Interest?.message ? "is-invalid customError" : ""}`}>
                                                                <option value="" style={{color:"red"}}>Intrested in*</option>
                                                                <option value="Web Development">Web Development</option>
                                                                <option value="App Development">App Development</option>
                                                                <option value="Web Development">Dedicated IT Team</option>
                                                                <option value="Dedicated IT Team">Digital Marketing</option>
                                                                <option value="Contennt Writing">Contennt Writing</option>
                                                                <option value="Intership">Intership</option>
                                                                <option value="Other IT Needs">Other IT Needs</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12">
                                                    <div className="FQA-form-box FQA-upload">
                                                        <div className="upload-btn-wrapper">
                                                        <button className="btn"><img src="images/upload-icon.svg" alt="" /> Upload a file</button>
                                                        <input type="file" name="myfile" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className={`FQA-form-box  FQA-form-textarea ${errors?.messages?.message ? "customErrordiv" : ""}`}>
                                                        <div className="FQAFB-form-icon">
                                                        <div class="g-recaptcha" data-sitekey="6LdYIZAmAAAAAGwCZZZPD0p-seegxIAvjOdddot8"></div>
                                                        </div>
                                                        <div className="FQAFB-formTextarea">
                                                            <textarea placeholder="Messages*" {...register("messages", {required:"is a required field"})} className={`form-control ${errors?.messages?.message ? "is-invalid customError" : ""}`}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="FQA-footer">
                                                <div className="FQA-footer-captcha"></div>
                                                <div className="FQA-footer-btn">
                                                    <button type="submit" className="custom-btn">Send your inquiry</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                
            <footer className="footer-area">	
                <div className="container">
                    <div className="footer-address-scroll">                    
                        <div className="slick-slider Address_scroll">
                            <Slider {...settings}>
                                <div>
                                    <div className="address-box">
                                        <div className="adress-box-flag"><img src="images/india-flag.png" alt="" /></div>
                                        <div className="adress-box-name">Jaipur (India)</div>
                                        <div className="adress-box-text">E-31, 11c, : M-03, Shree Amar Heights, DCM, Ajmer Road,</div>
                                        <div className="adress-box-text">Jaipur, Rajasthan 302019</div>
                                        <div className="adress-box-phone">+91 82229-62226</div>
                                    </div>
                                </div>

                                <div>
                                    <div className="address-box">
                                        <div className="adress-box-flag"><img src="images/india-flag.png" alt="" /></div>
                                        <div className="adress-box-name">Lucknow (India)</div>
                                        <div className="adress-box-text">10th floor, Skyline plaza, 1005, Near, Lulu Mall Rd, Golf City, Ansal</div>
                                        <div className="adress-box-text">Lucknow, Uttar Pradesh 226030</div>
                                        <div className="adress-box-phone">+91 6376 037 719</div>
                                    </div>
                                </div>

                                <div>
                                    <div className="address-box">
                                        <div className="adress-box-flag"><img src="images/sweden-flag.png" alt="" /></div>
                                        <div className="adress-box-name">Johanneshov (Sweden)</div>
                                        <div className="adress-box-text">Per Lindeströms väg 80 lgh 1001 121 46 Johanneshov </div>
                                        <div className="adress-box-text">Stockholm Sweden</div>
                                        <div className="adress-box-phone">+64 9-889 9412</div>
                                    </div>
                                </div>

                                <div>
                                    <div className="address-box">
                                        <div className="adress-box-flag"><img src="images/uk-flag.png" alt="" /></div>
                                        <div className="adress-box-name">West London (UK)</div>
                                        <div className="adress-box-text">Feltham , Zone 6, West London,</div>
                                        <div className="adress-box-text">United Kingdom - TW13</div>
                                        <div className="adress-box-phone">+44 7440 344375</div>
                                    </div>
                                </div>
                            </Slider>
                        	
                        </div>
                    </div>
                    
                    <div className="footer-quick-links">
                        <div className="row">
                            <div className="col-5">
                                <div className="footer-quick-info">
                                    <div className="FQI-logo"><img src="images/logo-white.svg" alt="" /></div>
                                    <p>EzzEEx is an IT service company that provides a wide range of services related to the field of information technology to other businesses and organizations.</p>
                                    <span>“We Chase Quality <br/>Quantity Chases Us”</span>
                                </div>
                            </div>
                            
                            <div className="col-3">
                                <div className="footer-quick-links">
                                    <h4>Quick Link</h4>
                                    <ul>
                                        <li><NavLink to="/" onClick={handleClick}>Home</NavLink></li>
                                        <li><NavLink to="aboutUs" onClick={handleClick}>About Us</NavLink></li>
                                        <li><NavLink to="" onClick={handleClick}>Service</NavLink></li>
                                        <li><NavLink to="hireDeveloper" onClick={handleClick}>Hire Developers</NavLink></li>
                                        <li><NavLink to="career" onClick={handleClick}>Career</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="col-4">
                                <div className="footer-quick-links">
                                    <h4>Browse Ezzexx</h4>
                                    <ul>
                                        <li><NavLink to="contact" onClick={handleClick}>Contact Us</NavLink></li>
                                        <li><NavLink to="https://www.ezzeex.com/ezzeexPanel/login.php" target='_blank'>Team Login</NavLink></li>
                                    </ul>
                                    <div className="socail-links">
                                        <ul>
                                            <li><a href="https://www.facebook.com/EzzEEx/" target="
                                            "><FaFacebookF /></a></li>
                                            <li><a href="https://twitter.com/ezzeex" target="
                                            "><FaTwitter /></a></li>
                                            <li><a href="https://www.linkedin.com/company/ezzeex/ " target="
                                            "><FaLinkedinIn /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>		
                </div>
            </footer>
                
            <div className="copyright-area">
                <div className="container">
                    <div className="copyright-inner">
                        <div className="copyright-text">EzzEEx Copyright © 2012-2022 : EzzEEx InfoTech Private Limited All Right Reserved</div>
                        <div className="copyright-link">
                            <ul>
                                <li><NavLink to="privacyPolicy" onClick={handleClick}>Privacy Policy</NavLink></li>
                                <li><NavLink to="termOfUse" onClick={handleClick}>Terms of Use</NavLink></li>
                            </ul>
                        </div>
                    </div>	
                </div>	
            </div>	
        </>
    )
}   


export default FooterArea;