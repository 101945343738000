import React, { useEffect } from "react";


import TestimonialArea from './Common/testimonial'
import { FiCheckCircle } from "react-icons/fi";

const DigitalInteractive = () => {
    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">EZZEex Digital Solution</div>
                                <h1>India's Top Rated Digital Marketing Agency</h1>
                                <p>We are the leading Digital Marketing Agency in India and have 12 Years experience in this field. We have well-trained team who are capable to drive more traffic, increase your company sales, and to speed up your business growth. Utilization of latest technologies and search algorithms and many other tools help us to design the best strategies that can suit your personalized business needs effectively.</p>
                                
                                <div className="banner-btn-area">
                                    <a href="#Free_quote" className="custom-btn btn-primary">Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right">
                                <img src="images/digital-market-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-box mt100 digital-WeDelivery">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-imgdefault" src="images/digital-marketing-about.svg" alt="" />
                            </div>	
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left pl-3">
                                <div className="custom-heading left-heading">
                                    <label>Multi-Platform Expertise</label>
                                    <div className="custom-heading-text">Driving Growth Through Strategic Digital Service</div>
                                </div>
                                <p>Our digital marketing services are tailored to drive results, focusing on increasing leads, boosting sales, and maximizing revenue. Through strategic campaigns, targeted advertising, and data-driven strategies, we help businesses achieve their growth objectives in the digital landscape.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="digital-marketing-services">		
                        <div className="DMS-servicesInner">		
                            <div className="custom-heading">
                                <label>Popular Service</label>
                                <div className="custom-heading-text">Our Digital Marketing Services</div>
                                <p>We turn digital into revenue driver while fuelling its with the data and expert-led marketing services. It helps to blend tech and talent to offer you effective Internet marketing services.</p>
                            </div>
                            
                            <div className="DMS-service-area">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="DMS-service-box">
                                            <div className="DMSS-box-img"><img src="images/DM-service-icon1.svg" alt="" /></div>
                                            <div className="DMSS-box-heading">Personalized support &amp; SEO service</div>
                                            <div className="DMSS-box-text">Our Professional SEO Service will create your ranking in less than 30 Days.</div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 col-md-12">
                                        <div className="DMS-service-box">
                                            <div className="DMSS-box-img"><img src="images/DM-service-icon2.svg" alt="" /></div>
                                            <div className="DMSS-box-heading">Competitor research &amp; analysis</div>
                                            <div className="DMSS-box-text">We conduct comprehensive research &amp; analysis of competitors to develop stronger digital strategies.</div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 col-md-12">
                                        <div className="DMS-service-box">
                                            <div className="DMSS-box-img"><img src="images/DM-service-icon3.svg" alt="" /></div>
                                            <div className="DMSS-box-heading">Global online marketing solutions</div>
                                            <div className="DMSS-box-text">Ezzeex team has experience and knowledge to provide lucrative global online marketing solutions.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="digital-interactive-sec mt100">	
                <div className="container">
                    <div className="custom-heading">
                        <label>Agency In India</label>
                        <div className="custom-heading-text">Goal-Focused Digital Marketing Agency In India</div>
                        <p>We believe that all the businesses are different so how their goals could be the same. It is true that every business’s interest lie in increasing traffic, conversions and However, each strategy works differently for different company. Hence, our experts focus towards the goal of our individual client and designs strategies accordingly.</p>
                    </div>
                    
                    <div className="DIS-inner-area">
                        <div className="DIS-inner-box" id="SEO_tab">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/seo-banner.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Search engine optimization</label>
                                            <div className="custom-heading-text">Expert SEO Company In India With 1000+ SEO Projects</div>
                                        </div>
                                        <p>We know that every business has unique need and goals so instead of offering pre-designed SEO solution package, we develop custom made strategies that are specially designed while catering your specific business needs. We design adequate digital marketing strategies that can bring your desired results.</p>
                                        
                                        <p>We are dealing in Digital Marketing Industry from last 12 Years and have immense knowledge and experience of creating Strong Online Presence.</p>
                                        
                                        <p>Every business has a desire to reach at the top of SERP (Search Engine Results Page), however it is not possible for everyone, only those become able to reach at that position, who genuinely deserve it. Our SEO Expert team will help you to rank your website at the top through utilizing preferred keywords.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="socialMedia_tab">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 order2">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/social-media-banner.svg" alt="" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Social Media optimization</label>
                                            <div className="custom-heading-text">The Easiest Social Media Optimization Techniques to Try Now</div>
                                            <p>Under Social media optimization (SMO), social media networks are used to manage and nurture organization's online presence. SMO is digital marketing strategy that supports to increase brand awareness and showcase new products and services to the mass population. It connects you with your customers through creating your business reach on numerous incredible social platforms.</p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Facebook Marketing/ Twitter Marketing/ Youtube Marketing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Google Plus Marketing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Content Marketing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Video Marketing</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="onlineReputation_tab">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-right text-align-left">
                                        <img className="WWA-right-imgdefault" src="images/online-reputation-banner.svg" alt="" />
                                    </div>	
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Online Reputation Management</label>
                                            <div className="custom-heading-text">Online Reputation Management: What It Is &amp; Why You Need It</div>
                                        </div>
                                        <p>Online Reputation Management is used by the organisation to structure their brand reputation on online platform through effective digital marketing strategies. It helps to displace misleading content and uploading trending content while making the content visible through internet algorithms.</p>
                                        
                                        <p>To create positive online reputation, it is essential to provide correct solutions to the users for their queries.</p>
                                        
                                        <p>Online Reputation Management gives you an opportunity to encounter negative feedback and responding them gracefully and to promote consumer positive experience. Both these aspects help to provide insight on increasing brand awareness. It helps brand to build credibility and to show the customers that they care about them.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="DIS-inner-box" id="conversionRate_tab">
                            <div className="row">
                                 <div className="col-lg-6 col-md-12 order2">
                                    <div className="WWA-right">
                                        <img className="WWA-right-imgdefault" src="images/conversion-rate-banner.svg" alt="" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="WWA-left product-service-box p-0">
                                        <div className="custom-heading left-heading">
                                            <label>Lorem ipsum</label>
                                            <div className="custom-heading-text">Conversion Rate Optimization Techniques</div>
                                            <p>Conversion Rate Optimization (CRO) is used by the organizations to increasing the conversion percentage from their website and mobile app. It will help you to continuously analyze your website or mobile app performance metrics to generate more sales. We support you in increasing your CRO with the help of effective digital marketing strategies to create high-converting sales funnel.</p>
                                            <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Conversion Research</li>
                                            <li><FiCheckCircle className="yellowCheck" /> A/B Testing</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Website Persuasion</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Personalization</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>

            <section className="bg-box whoWe-are-area mt100 pb-100">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left">
                                <h2>Most Trusted Digital Marketing Company India</h2>
                                <h3>One-Stop Solution To Boost Your Business!</h3>
                                <p>We surprise you with our proficient resources that will shape your business and will give a growth that you are dreaming for!! Our Team uses Top-grade Services that is essential to make your digital marketing strategies interactive and to make your strong online presence among your competitors. Under our assistance, your business will touch the sky.</p>
                                <div className="WWA-left-highlight">
                                    <div className="WWAL-highlight-box">
                                        <div className="WWAL-highlight-img">
                                            <img src="images/best-solution-icon.svg" alt="" />
                                        </div>
                                        <div className="WWAL-highlight-cont">
                                            <h4>All-In-One Solution</h4>
                                            <p>All-In-One Solution designed according to the customized need of your business, goals and KPIs.</p>
                                        </div>
                                    </div>

                                    <div className="WWAL-highlight-box">
                                        <div className="WWAL-highlight-img">
                                            <img src="images/bottom-growth-icon.svg" alt="" />
                                        </div>
                                        <div className="WWAL-highlight-cont">
                                            <h4>Bottom-Line Growth</h4>
                                            <p>To turn the Traffic into revenue, we provide custom Internet marking services.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <div className="digital-trusted-area">
                                    <div className="digital-trusted-img">
                                        <img src="images/digital-trusted-company.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />
            
        </>
    )
}   
export default DigitalInteractive;  