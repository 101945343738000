import React from 'react';
import {  BrowserRouter, Routes, Route }   
from 'react-router-dom';  


import 'bootstrap/dist/css/bootstrap.min.css';  



import FooterArea from './Components/Common/Footer'
import Header from './Components/Common/Header'

import HomeComponets from './Components/HomeMain'
import AboutUsArea from './Components/aboutUs'
import CareerArea from './Components/career'
import HireDeveloperArea from './Components/HireDeveloper'
import ContactArea from './Components/Contact'
import PrivacyPolicyArea from './Components/privacyPolicy'
import TermOfUseArea from './Components/termOfUse'

import ItSolutionArea from './Components/itSolution'
import WebDevelopmentArea from './Components/webDevelopment'
import CustomerSoftwareAppDevelopmentArea from './Components/customerSoftwareAppDevelopment'
import UiUxDesign from './Components/uiUxDesign'
import DigitalInteractive from './Components/digitalInteractive'
import OutsourcingServicesArea from './Components/outsourcingServices'
import WritingAndEditingArea from './Components/writingAndEditing'
import LearningIntershipArea from './Components/learningIntership'
import OnlineTutoringArea from './Components/onlineTutoring'
import IndustryArea from './Components/industry'




import './App.css';
import './responsive.css';



function App() {
  return (
    <>
      <BrowserRouter basename="">  
          <Header />
            <Routes>
                <Route exact  path="/" element={<HomeComponets />} />
                <Route exact  path="aboutUs" element={<AboutUsArea />} />
                <Route exact  path="career" element={<CareerArea />} />

                <Route exact  path="itSolution" element={<ItSolutionArea />} />
                <Route exact  path="webDevelopment" element={<WebDevelopmentArea />} />
                <Route exact  path="customerSoftwareAppDevelopmentArea" element={<CustomerSoftwareAppDevelopmentArea />} />
                <Route exact  path="uiUxDesign" element={<UiUxDesign />} />
                <Route exact  path="digitalInteractive" element={<DigitalInteractive />} />
                <Route exact  path="outsourcingServices" element={<OutsourcingServicesArea />} />
                <Route exact  path="writingAndEditing" element={<WritingAndEditingArea />} />
                <Route exact  path="learningIntership" element={<LearningIntershipArea />} />
                <Route exact  path="onlineTutoring" element={<OnlineTutoringArea />} />
                <Route exact  path="industry" element={<IndustryArea />} />

                <Route exact  path="hireDeveloper" element={<HireDeveloperArea />} />
                <Route exact  path="contact" element={<ContactArea />} />
                <Route exact  path="privacyPolicy" element={<PrivacyPolicyArea />} />
                <Route exact  path="termOfUse" element={<TermOfUseArea />} />
            </Routes>
          
          {Location.pathname !== '/contact' && <FooterArea />}
        </BrowserRouter>
    </>
  );
}

export default App;
