import React from "react";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';



import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'
import { FiCheckCircle } from "react-icons/fi";

const WebDevelopmentArea = () => {



    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Craft your Digital Future with</div>
                                <h1>We transform your craziest ideas into perfect designs</h1>
                                <p>We offer web application development services that can help you bridge the gap between what you expected and what is delivered to you. So, the actual functional solution that you receives remain similar to your envision. </p>
                                
                                <div className="banner-btn-area">
                                    <a href="#Free_quote" className="custom-btn btn-primary">Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right mt-4">
                                <img src="images/web-developmentPage-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what-do-area it-platformSec">	
                <div className="container">
                    <div className="custom-heading">
                        <label>WE ARE EXPERT</label>
                        <div className="custom-heading-text">Significant Website Development Platforms</div>
                        <p>We have team of expert professionals who utilizes latest IT development technologies and are capable to implement different services for different industries according to your specified need. </p>
                    </div>
                    
                    <div className="itSolution-platforms-area">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="FrontDeveloper-tab">
                            <Row>
                                <Col sm={12}>
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="FrontDeveloper-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/cms-icon.svg" alt="" />
                                                </div>
                                                <p>CMS Development</p>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="backendDev-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/framework-icon.svg" alt="" />
                                                </div>
                                                <p>Framework Development</p>
                                            </div> 
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="cloudDev-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/js-framework-icon.svg" alt="" />
                                                </div>
                                                <p>Javascript Development</p>
                                            </div>   
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="mobility-tab">
                                            <div className="ITS-platform-head">  
                                                <div className="ITSP-head-img">
                                                    <img src="images/html-css-icon.svg" alt="" />
                                                </div>
                                                <p>HTML3 & CSS3</p>
                                            </div>  
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="FrontDeveloper-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">Our CMS Development system helps you to make the website content easier to manage. This web development service supports to offer relevant and high-quality content to our clients. We offer you customizable solutions and our experts remain concern to personalize your CMS and to make it unique according to your needs.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/wordpress-icon.png" alt="" /></div>
                                                        <p>Woocommerce</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/magento-icon.png" alt="" /></div>
                                                        <p>Magento</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/drupal-icon.png" alt="" /></div>
                                                        <p>Drupal</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/joomla-icon.png" alt="" /></div>
                                                        <p>Joomla</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/opencart-icon.png" alt="" /></div>
                                                        <p>Opencart</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/prestashop-icon.png" alt="" /></div>
                                                        <p>PrestaShop</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="backendDev-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">Framework development helps to extend HTML vocabulary to the web application. It offers set of resources and tools for software developers that further helps in building and managing web applications, web services and websites.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/cake-php.png" alt="" /></div>
                                                        <p>CakePHP</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/yii-icon.png" alt="" /></div>
                                                        <p>Yii</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/zend-icon.png" alt="" /></div>
                                                        <p>Zend</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/codeigniter-icon.png" alt="" /></div>
                                                        <p>Codeigniter</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/symfony-icon.png" alt="" /></div>
                                                        <p>Symfony</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/laravel-icon.png" alt="" /></div>
                                                        <p>Laravel</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="cloudDev-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">JavaScript is a language that is used in web pages to develop script. JavaScript is used bu the developers to produce dynamic and interactive web page that can offer you a platform to interact with the users and execute complex actions.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/reactjs-icon.png" alt="" /></div>
                                                        <p>ReactJS</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/angularjs-icon.png" alt="" /></div>
                                                        <p>AngularJS</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/nodejs-icon.png" alt="" /></div>
                                                        <p>Node.js</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/jQuery.png" alt="" /></div>
                                                        <p>jQuery</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="mobility-tab">
                                        <div className="itSolution-tab-area">
                                            <div className="itSolution-tab-heading">HTML 3.0 offers customised lists that support to create fine positioning control with entities. It also offers additional features of static banner area for corporate logos, customized navigation, etc. CSS3 is used to create and format content structure with HTML which remains responsible for font, colours, text alignments, graphics, etc and positions various elements with fixed, absolute, and relative values.</div>
                                            <div className="itSolution-tab-list">
                                                <ul>						
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/html5-icon.png" alt="" /></div>
                                                        <p>HTML5</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/css3-icon.png" alt="" /></div>
                                                        <p>CSS3</p>
                                                    </li>
                                                    <li>
                                                        <div className="itSolution-TL-img"><img src="images/bootstrap-icon.png" alt="" /></div>
                                                        <p>Bootstrap</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>	
                </div>
            </section>	

            <section className="whoWe-are-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left product-service-box p-0">
                                <h2>WHY CHOOSE US</h2>
                                <h3>Bringing new business solutions and ideas advanced digital</h3>
                                <p>Your website must be designed in a manner that it could keep you distinguish in the industry in which you are dealing. In this context, Ezzeex, top web Development Company can assist you to build digital presence to make you stand out from the competition.</p>
                                <ul>
                                <li><FiCheckCircle className="yellowCheck" /> Custom Web Development</li>
                                <li><FiCheckCircle className="yellowCheck" /> eCommerce Platform</li>
                                <li><FiCheckCircle className="yellowCheck" /> Analytics and Tracking Code</li>
                                <li><FiCheckCircle className="yellowCheck" /> Regular Website Maintenance</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right">
                                <img className="WWA-right-imgdefault" src="images/webDev-why-choose.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />

            <section className="our-service-area">
                <div className="container">
                    <div className="custom-heading">
                        <label>Our Web Development</label>
                        <div className="custom-heading-text">Wide Range of Web Solutions for All</div>
                        <p>Our experts are well-aware about the web development needs of different industries and we serve clients from multiple industries. So, we have the potential to offer services for different industries.</p>
                    </div>
                    
                    <div className="OS-box-area webDevelopment-OSBox">
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-1.svg" alt="" /></div>
                            <div className="OSB-box-heading">Web Application Development</div>
                            <div className="OSB-box-text">Ezzeex have experienced front-end developers and UX designers who are dedicated to craft attractive and appealing interface and design to offer quality experience to the users. We offer intuitive and scalable web-based software at affordable prices.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>
                    
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-2.svg" alt="" /></div>
                            <div className="OSB-box-heading">Mobile Application Development</div>
                            <div className="OSB-box-text">It’s true that poorly designed apps create low conversion hence we provide digital-first products that eliminate 90% usability issues and convert your target audience into customers and profit. </div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>
                    
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-3.svg" alt="" /></div>
                            <div className="OSB-box-heading">Enterprise Development</div>
                            <div className="OSB-box-text">Our team of App developers works towards developing different kinds of app such as education, medical, retail, travel, clothing, finance, etc. We are focused on not only iOS and Android but also on Cordova (PhoneGap) and HTML5.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>
                    
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-4.svg" alt="" /></div>
                            <div className="OSB-box-heading">Social Media Promotion</div>
                            <div className="OSB-box-text">We offer the services of Social Media Promotion to our clients while utilising social media strategy, influencer marketing, celebrity endorsement, social community management, etc. which helps you to leverage the power of the fastest growing and the most powerful marketing tool.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>
                    
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-5.svg" alt="" /></div>
                            <div className="OSB-box-heading">Support & Maintenance</div>
                            <div className="OSB-box-text">We offer support and maintenance to your Websites so that they can be updated according to the changing consumer needs and can remain robust. Our support team keeps your web presence fresh and timely.</div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>
                                
                    
                    
                        <div className="our-service-box">
                            <div className="OSB-box-img"><img src="images/webDev-service-6.svg" alt="" /></div>
                            <div className="OSB-box-heading">E-Commerce Web Solutions</div>
                            <div className="OSB-box-text">We help your web applications stay relevant by adapting and addressing changing business requirements.<br/>
                            We provide e-commerce solutions that remain relevant for you to adapt changing business requirements and achieve performance optimization. We include online storefront, shopping cart, multiple payment processing systems, inventory management tools, etc.
                            </div>
                            <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                        </div>
                    </div>
                    
                </div>
            </section>

            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />
            
        </>
    )
}   
export default WebDevelopmentArea;  