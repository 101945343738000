import React from "react";
import {NavLink} from 'react-router-dom'; 


const TermOfUseArea = () => {
    const handleClick = () => {
        window.scrollTo(0,0);
    }

    return (
        <>
            <section className="privacy-policy">	
                <div className="container">
                    <div className="privacy-policy-box">
                        <h1>Terms Of Use</h1>
                        
                        <h2>Copyright and Fair Usage</h2>
                        <ul>
                            <li>These Terms of Use (ToU) refer to individual using the Site as well as the Organization.</li>
                            
                            <li>If you do not accept these ToU, then you must not use or access this Site.</li>
                            
                            <li>Materials that are contained in this Site are for informational purposes only and no information is provided to be considered as a substitute for your independent investigation.</li>
                            
                            <li>Third-party website links are provided only for convenience and they do not imply any approval by EZZEEX INFOTECH PRIVATE LIMITED. </li>
                        </ul>
                        
                        <h2>Trademarks And Copyright</h2>
                        <p>EzzEEx InfoTech is the exclusive owner of EzzEEx Info Tech Pvt. Ltd. and all the material therein. All of the information on the website, including but not limited to logos, photos, graphics, audio, page headers, and video clips, is protected by EzzEEx Info Tech Pvt. Ltd.'s trademark and copyright ownership.</p>
                        
                        <p>All intellectual property rights and full ownership of any content obtained from the website are reserved by EzzEEx Info Tech Pvt. Ltd. Without the approval of EzzEEx Info Tech Pvt. Ltd., the content may not be used, changed, or duplicated.</p>
                        
                        <h2>Social Media House Rules</h2>
                        <p>We try to make using our social media platforms enjoyable and make sure that our online communities are encouraging, helpful, and inspiring. To this purpose, we retain the right to delete any posts that violate our community guidelines and to block anybody who frequently transgresses them. We will delete comments that: </p>
                        <ul>
                            <li>Contains offensive language or swear words.</li>
                            <li>Defame, abuse, harass, stalk, threaten, or otherwise infringe upon the legal rights of others will be removed.</li>
                            <li>Offensive in any way; including being racist, sexist, homophobic, sexually explicit, or abusive</li>
                            <li>Include harsh language or other expressions that might offend.</li>
                            <li>This includes creating, uploading, sharing, or otherwise spreading any false, deceptive, or otherwise illegal material or
            information.</li>
                            <li>Repeatedly publish the same message or be unconnected to EzzEEx Info Tech Pvt. Ltd.—this is known as "spam"—or
            are intended to look as though they have been posted by someone else.</li>
                        </ul>
                        <p>These guidelines may be changed as necessary, and we reserve the right to remove content for reasons not included in this list. Your consent to abide by these house rules as well as the terms and conditions of the platform by using our social media channels.Users' posts on our social media platforms may leave comments and other content, but EzzEEx Info Tech Pvt. Ltd. neither endorses nor verifies them as accurate.</p>
                        
                        <h2>User Agreement:</h2>
                        <ul>
                            <li>
                                <h5>Why should read this agreement</h5>
                                <p>The agreement is a legally binding contract between the user and our company. This agreement establishes rules and
            regulations that users must accept and abide by when using and accessing our website.</p>
                            </li>
                            <li>
                                <h5>The content covered by this agreement</h5>
                                <p>User Agreements can help outline the different rights of users and website owners, protect the interests and requirements of
            website owners, verify and control the legal obligations of website owners, and focus on dispute-handling procedures.
            Specific laws assist the company in compensation, help manage the meaning of various intellectual property rights and the
            acceptable use of the website, as well as other wide-ranging important matters.</p>
                            </li>
                            <li>
                                <h5>You accept this agreement</h5>
                                <p>Accepting the agreement means that you have confirmed that you have read and accepted the website disclosure and
            electronic declaration and that you agree to the terms and conditions set out in the agreement.</p>
                            </li>
                            <li>
                                <h5>Who we are and how to contact us</h5>
                                <p>EzzEEx InfoTech Pvt. Ltd. is a company with company number PTC073414 (applicable "EzzEEx Info Tech Pvt. Ltd.").  You can contact us by email, website, chat, and phone or Visit on <NavLink to="/contact" onClick={handleClick}>CONTACT US</NavLink></p>
                            </li>
                            <li>
                                <h5>Who can use our service</h5>
                                <p>The user's age must be 18 years or older.<br/>
                                The use and access of the users of EzzEEx Infotech Pvt. Ltd. must be legal and must not violate any law.</p>
                            </li>
                            <li>
                                <h5>Intellectual property</h5>
                                <p>The portal, our services, our website, and any associated and included intellectual property (including but not limited to
            copyrights, patents, database rights, trademarks, and the service mark) are owned by us, our affiliates, or third parties. We
            and third parties will own all rights, property, and interests in the portal, our services, and our website.</p>
                            </li>
                            <li>
                                <h5>Our responsibility for loss or damage</h5>
                                <p>EzzEEx Infotech Pvt. Ltd. or any of its agents, employees, or subcontractors will not be liable for any claim, loss or demand of any kind by you or any other party (regardless of such claims, losses or not), claim or damage is foreseeable, known or for other reasons due to your use of or related to the website or the information, content, or materials contained in the website.</p>
                            </li>
                            <li>
                                <h5>Our right to make changes</h5>
                                <p>We have the right to change these terms and conditions of use (including our privacy policy) at any time without notifying
            users. Any such changes will take effect immediately after being made public. After making such modifications, your
            continued use of services and this website means that you accept the updated terms.</p>
                            </li>
                            <li>
                                <h5>Complaint</h5>
                                <p>If you have any questions about us or our services, you can contact us through our customer complaint system: <NavLink to="/contact" onClick={handleClick}>CONTACT US</NavLink></p>
                            </li>
                        </ul>
                        
                        <h3>Refund and Cancellation Policy</h3>
                        <p>The refund policy is subject to the following requirements if you ask to cancel any order for any reason.</p>
                        <ul>
                            <li>You are entitled to a 50% refund if you ask to cancel an order after a writer has been assigned to it.</li>
                            <li>If you ask to cancel your purchase and a developer has not been assigned to it, you are entitled to a 90% refund.</li>
                            <li>If you seek an order cancellation due to missing order data, you are entitled to a 95% refund.</li>
                        </ul>
                        <p>Our top focus is to deliver the order on time.  Customers are urged to check the spam page, nevertheless. The customer will believe the delivery is delayed at the moment, even if the order was submitted on time for approval but was labelled spam.</p>
                        
                        <p>If you are unable to access any content or service, you can contact the web manager to be directed to an accessible alternative or to obtain the content in another form. </p>
                                    
                        <p>Contact the supportdesk from the following address: <NavLink to="/contact" onClick={handleClick}>CONTACT US</NavLink></p>
                    </div>
                </div>
            </section>   
        </>
    )
}   
export default TermOfUseArea;  