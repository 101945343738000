import React from 'react';
import CountUp from 'react-countup';


const CompanyCounterArea = () => {

    return (
        <>        
           <section className="company-progress-section">	
                <div className="container">
                    <div className="company-progressArea">
                        <div className="CPA-progress-inner">
                            <div className="CPA-progress-box">
                                <div className="CPA-progress-mid">
                                    <div className="CPA-progress-flex">
                                        <div className="CPA-progressBox-img">
                                            <img src="images/year-inBusiness-icon.svg" alt="" />
                                        </div>
                                        <div className="CPA-progressBox-content number_counter">
                                             <CountUp end={12} className='className="counter-value' />
                                            <p>YEARS IN <br/>BUSINESS</p>
                                        </div>					
                                    </div>
                                </div>
                            </div>
                            
                            <div className="CPA-progress-box">
                                <div className="CPA-progress-mid">
                                    <div className="CPA-progress-flex">
                                        <div className="CPA-progressBox-img">
                                            <img src="images/team-icon.svg" alt="" />
                                        </div>
                                        <div className="CPA-progressBox-content number_counter">
                                            <CountUp end={15} className='className="counter-value' />
                                            <p>TEAM <br/>MEMBERs</p>
                                        </div>					
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="CPA-progress-inner CPA-rpogress-three">
                            <div className="CPA-progress-box">
                                <div className="CPA-progress-mid">
                                    <div className="CPA-progress-flex">
                                        <div className="CPA-progressBox-img">
                                            <img src="images/happy-client-icon.svg" alt="" />
                                        </div>
                                        <div className="CPA-progressBox-content number_counter">
                                            <CountUp end={9829} className='className="counter-value' />
                                            <p>HAPPY CLIENT</p>
                                        </div>					
                                    </div>
                                </div>
                            </div>
                            
                            <div className="CPA-progress-box">
                                <div className="CPA-progress-mid">
                                    <div className="CPA-progress-flex">
                                        <div className="CPA-progressBox-img">
                                            <img src="images/successful-project-icon.svg" alt="" />
                                        </div>
                                        <div className="CPA-progressBox-content number_counter plus-counter">
                                            <CountUp end={12000} className='className="counter-value' />
                                            <p>SUCCESSFUL <br/>PROJECTS</p>
                                        </div>					
                                    </div>
                                </div>
                            </div>
                            
                            <div className="CPA-progress-box">
                                <div className="CPA-progress-mid">
                                    <div className="CPA-progress-flex">
                                        <div className="CPA-progressBox-img">
                                            <img src="images/countries-icon.svg" alt="" />
                                        </div>
                                        <div className="CPA-progressBox-content number_counter plus-counter">
                                            <CountUp end={20} className='className="counter-value' />
                                            <p>Countries</p>
                                        </div>					
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}   




export default CompanyCounterArea;   