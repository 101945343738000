import React, { useEffect } from "react";


import CompanyCounterSmallArea from './Common/companyCounterSmall'
import TestimonialArea from './Common/testimonial'

import {NavLink} from 'react-router-dom';  

import { FiCheckCircle } from "react-icons/fi";






const ItSolutionArea = () => {
    const handleClick = () => {
        window.scrollTo(0,0);
    }

    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Powerful digital solutions</div>
                                <h1>We transform your craziest ideas into perfect designs</h1>
                                <p>We are here to transform your craziest ideas into perfect designs as we know that anything is possible if there is smart team and proper equipment.</p>
                                
                                <div className="banner-btn-area">
                                    <a href="#Free_quote" className="custom-btn btn-primary">Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area">
                                <div className="it-solution-banner">
                                    <div className="it-solutionBanner-img">
                                        <img src="images/it-solution-banner.png" alt="" />
                                    </div>
                                    <div className="it-solutionBanner-tag">
                                    <img src="images/it-solution-one.svg" alt="" />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                
            <section className="solution-sec">	
                <div className="container">
                    <div className="solution-area">
                        <div className="solution-inner">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    <div className="solution-inner-heading">
                                        <div className="WWA-left">
                                            <h2>We do everything your website needs</h2>
                                            <h3>With our marketing and technology solutions, we will help you achieve your company’s goals.</h3>
                                            <p>Internet has created gold mine opportunity for the businesses and we are here to use it while harnessing its power for your business benefit. Our team will support you to create digital presence of your business while researching the industry in which you are dealing and discovering your potential market. We have diverse team members that offer us the potential to meet all type of business needs. Our writers are designers are dedicated to offer customized content and designs so that your particular and specified needs can be met effectively. We utilize continuous improvement and an Agile approach to technology to offer future-ready online business solutions to our clients.</p>
                                        </div>
                                    </div>	
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="solution-inner-box">
                                        <a href="">
                                            <div className="SIB-solution-img">
                                                <img src="images/solution-code.svg" alt="" />
                                            </div>
                                            <div className="SIB-solution-content">
                                                <div className="SIB-solutionHead">Web Development</div>
                                                <p>We transform your craziest ideas into perfect designs</p>
                                            </div>
                                            <div className="banner-btn-area">
                                                <NavLink to="/webDevelopment" onClick={handleClick} className="custom-btn">Learn More</NavLink>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="solution-inner-box">
                                        <a href="">
                                            <div className="SIB-solution-img">
                                                <img src="images/solution-app-development.svg" alt="" />
                                            </div>
                                            <div className="SIB-solution-content">
                                                <div className="SIB-solutionHead">Custom Software & App Development</div>
                                                <p>Our high-quality custom web software development solutions will add value to your business.</p>
                                            </div>
                                            <div className="banner-btn-area">
                                                <NavLink to="/customerSoftwareAppDevelopmentArea" onClick={handleClick} className="custom-btn">Learn More</NavLink>
                                            </div>
                                        </a>
                                    </div>	
                                </div>
                                
                                <div className="col-lg-6 col-md-12">
                                    <div className="solution-inner-box">
                                        <a href="">
                                            <div className="SIB-solution-img">
                                                <img src="images/solution-ui-ux.svg" alt="" />
                                            </div>
                                            <div className="SIB-solution-content">
                                                <div className="SIB-solutionHead">UI/UX Design </div>
                                                <p>We offer all kinds of your product’s UX/UI solutions that best fit your business goals.</p>
                                            </div>
                                            <div className="banner-btn-area">
                                                <NavLink to="/uiUxDesign" onClick={handleClick} className="custom-btn">Learn More</NavLink>
                                            </div>
                                        </a>
                                    </div>	
                                </div>
                            </div>
                            
                            
                            
                            
                            
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterSmallArea />

            <section className="solution-diffrent-sec">	
                <div className="solution-diffrent-inner">	
                    <div className="container">
                        <div className="custom-heading white-heading">
                            <label>It’s What We Do</label>
                            <div className="custom-heading-text">What makes us different?</div>
                            <p>High-quality web development services and 24*7 support system throughout the process with furnished solutions makes us stand distinguish in the industry.</p>
                        </div>
                    </div>
                </div>
                <div className="diffrentInner-BoxArea">
                    <div className="container">
                        <div className="what-do-inner">
                            <div className="what-do-box">
                                <div className="what-doBox-img"><img src="images/it-solution-icon-team.svg" alt="" /></div>
                                <h3>Our Team</h3>
                                <p>Our team members are highly skilled and experienced and upgrade themselves according to the changing market need.</p>
                            </div>	
                        
                        
                            <div className="what-do-box">
                                <div className="what-doBox-img"><img src="images/it-solution-icon-process.svg" alt="" /></div>
                                <h3>Our Processes</h3>
                                <p>We have easy and convenient process and we offer you assistance from first time contacting us to service delivery to taking your feedback regarding the services availed.</p>
                            </div>
                        
                        
                        
                            <div className="what-do-box">
                                <div className="what-doBox-img"><img src="images/it-solution-icon-technology.svg" alt="" /></div>
                                <h3>Our Technologies</h3>
                                <p>TOur team members are dedicated Digital Strategist and whenever any task is assigned to them they work closely while taking assistance of technology partners.</p>
                            </div>
                        
                        
                        
                            <div className="what-do-box">
                                <div className="what-doBox-img"><img src="images/it-solution-icon-pricing.svg" alt="" /></div>
                                <h3>Our Pricing</h3>
                                <p>We offer high-quality services at affordable price so you can rest assure for return on your investment as our team offers the value to every penny you spend on our service.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>	

            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="whoWe-are-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left product-service-box">
                                <h2>Who Are We?</h2>
                                <h3>We are a digital branding company that believes in the power of creative strategy with great design.</h3>
                                <p>We are passionate to assist our clients to attain their business goal through offering them the best possible results. We offer end-to-end digital branding services so that your brand’s strong reputation can be created.</p>
                                <ul>
                                <li><FiCheckCircle className="yellowCheck" /> Exceptional Academic Research and Writing</li>
                                <li><FiCheckCircle className="yellowCheck" /> Web Content / Brand / Blog / SEO content writing</li>
                                <li><FiCheckCircle className="yellowCheck" /> Online Presence/ CV-profile writing / Business Writing</li>
                                <li><FiCheckCircle className="yellowCheck" /> Programming assistance and Code writing</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="mask-img">
                                <img src="images/it-solution-mask.jpg" alt="" />				
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />


        </>
    )
}   
export default ItSolutionArea;  