import React from "react";



import TestimonialArea from './Common/testimonial'
import { FiCheckCircle } from "react-icons/fi";

const OutsourcingServicesArea = () => {
    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <div className="heading-label">Business Process Operations (BPO)</div>
                                <h1>Services For Business Continuity And Resiliency</h1>
                                <p>COVID-19 Pandemic has accelerated the demand of automation for almost every industry and business. Use of Technology is bringing fast change in the fundamental business nature. For creating leading position in the market, organizations have increased concern towards utilizing new age technology-led innovative business models. Change in business landscape and enhancement in customer expectations is increasing the need of availing Inbound &amp; Outbound Marketing Strategies, Process Outsourcing and Back Office Outsourcing.</p>
                                
                                <div className="banner-btn-area">
                                    <a href="#Free_quote" className="custom-btn btn-primary">Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-img-area text-align-right">
                                <img src="images/outsourcing-services-banner.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-box development-team-service mt100">	
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-right text-align-left">
                                <img className="WWA-right-imgdefault" src="images/outsourcing-services-work.svg" alt="" />
                            </div>	
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="WWA-left pl-3 outsourcing-supportContent">
                                <div className="custom-heading left-heading">
                                    <label>What is Lorem Ipsum?</label>
                                    <div className="custom-heading-text">Outstanding Outsourcing Support</div>
                                </div>
                                <p>We offer Process Outsourcing & Back Office Outsourcing services for your company which will help you to in profit maximization. We remain concern to offer scalable outsourcing customer service solutions to establish good customer relationship with the firm. We are a perfect business partner as we analyze your business in a detailed manner and then offer you cost-effective solutions.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="appDev-offer-area mt100">
                        <div className="appDev-offer-inner appDev-three">
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/hire-IT-Staff-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>What is Business Process Outsourcing (BPO)</h4>
                                    <p>Under Business Process Outsourcing (BPO), organizations hire third party to perform day-to-day tasks and handle operations. These services help organizations to create their business future-ready and accelerate the path towards intelligent operations. BPO allows them addressing vital ingredients such as talent management, data management, applied intelligence, etc. In Ezzeex, we know that there is no “one-size-fits-all” solution for the businesses. Hence, we design customized BPO services according to the personalized need of your business to create sustainable growth. BPO services will help you to deliver exceptional customer experiences and to produce sky rocketing outcomes.</p>
                                </div>
                            </div>
                            
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/outsourcing-services-useIt-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Why do we use it?</h4>
                                    <p>In the current market, the level of competition is severely increased which has created the demand of developing the strategies that can assist a brand to stand distinguish in the industry. BPO services help organizations to deal with everyday tasks and allow them to increase their focus to creativity, innovation and decision making. BPO service brings power of technology with human creativity to handle everyday task-based processes through external vendors and increase concern to more complex data-driven practices to serve the consumers in a better way.</p>
                                </div>
                            </div>
                            
                            <div className="appDev-offerBox">
                                <div className="appDev-OB-img"><img src="images/hire-dedicated-icon.svg" alt="" /></div>
                                <div className="appDev-OB-content">
                                    <h4>Where does it come from?</h4>
                                    <p>The trend of BPO has initiated from the Manufacturing industry where Manufacturers hire third-party vendors to manage their supply chains. Utilization of this strategy assists them to more skills, speed and cost efficiencies in their business processes as compare to the in-house team. Due to huge benefits of BPO was found in Manufacturing Industry, this trend was further used by the businesses of other industries too.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>	

            <section className="outsourcing-service-area">
                <div className="container">
                    <div className="custom-heading">
                        <label>Where can I get some?</label>
                        <div className="custom-heading-text">BPO and Automation Services</div>
                        <p>As a business operations partner, we merge our distinctive expertise to empower your modern operating models. In-depth industry analysis, IT automation and effective leadership enables us to help you in transforming your business operations.</p>
                    </div>
                </div>
                
                <div className="OSA-barArea-Sec">
                    <div className="OSA-barArea">
                        <div className="container">
                            <div className="OSA-bar">
                                <ul>
                                    <li className="active">
                                        <a href="#Inbound_Outbound">
                                            <img src="images/inbound-outbound-icon.svg" alt="" />
                                            <span>Inbound &<br/> Outbound</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#Process_Outsourcing">
                                            <img src="images/process-outsourcing-icon.svg" alt="" />
                                            <span>Process<br/> Outsourcing</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#Back_office_Outsourcing">
                                            <img src="images/back-office-outsourcing-icon.svg" alt="" />
                                            <span>Back office<br/> Outsourcing</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="container">
                        <div className="DIS-inner-area">
                            <div className="OS-serviceBox" id="Inbound_Outbound">
                                <div className="DIS-inner-box">
                                    <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="WWA-left product-service-box p-0">
                                            <div className="custom-heading left-heading">
                                                <label>BPO services</label>
                                                <div className="custom-heading-text">What is BPO for intelligent workflows?</div>
                                                <p>What is BPO for intelligent workflows? BPO allows businesses to outsource outside vendors to carry out those standard business functions which are not core to the business so that business can retain only core staff. It helps
                                                company to give focus on high-value activities such as researching new opportunities, developing strategies to grow the business, etc. whereas day-to-day Operations are managed by external vendors.</p>
                                                <ul>
                                                <li><i className="las la-check-circle"></i> Financial Services / Accounting</li>
                                                <li><i className="las la-check-circle"></i> Healthcare</li>
                                                <li><i className="las la-check-circle"></i> Human Resource Management / Customer Support</li>
                                                <li><i className="las la-check-circle"></i> Supply Chain Management</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        <div className="WWA-right">
                                            <img className="WWA-right-imgdefault" src="images/bpo-banner.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                </div>
                                
                                <div className="BPO-service-sec">
                                    <div className="BPOS-service-area">
                                        <div className="BPOS-service-box BPO-Width60">	
                                            <div className="BPOSB-box-heading">Customer Support</div>
                                            <p>We have experienced team who remain available to assist you round-the-clock and offer you dynamic support to resolve your customers’ queries and boost your sales. With our assistance, you can rest assured about Customer Relationship Management and can focus on your business growth opportunities.</p>	
                                        </div>
                                        
                                        <div className="BPOS-service-box BPO-Width40">	
                                            <div className="BPOSB-box-heading">Product Support</div>
                                            <p>Our Experts offer broad range of product support services to maximize excellent customer satisfaction. Our customized product support services remain highly assistive to meet specific needs of different kind of businesses.</p>	
                                        </div>
                                    </div>
                                    
                                    <div className="BPOS-service-area">
                                        <div className="BPOS-service-box">	
                                            <div className="BPOSB-box-heading">Technical Support</div>
                                            <p>Our Technical Support team helps our clients through post-purchase technical support to build long-lasting customer relationships. Technical experts deal with range of problems from simple queries to multiple tier support applications. Our feedback cycles help to continuously improve the performance of our professionals.</p>	
                                        </div>
                                        
                                        <div className="BPOS-service-box">	
                                            <div className="BPOSB-box-heading">Customer Survey</div>
                                            <p>According to the analysis of different perspectives of your business, we design unique customer survey that can enable you to analyze the level of your customer satisfaction. We will offer you in-depth feedback from the customers through final feedback report.</p>	
                                        </div>
                                        
                                        <div className="BPOS-service-box">	
                                            <div className="BPOSB-box-heading">Toll-free Service</div>
                                            <p>We also offer you the service of toll-free at highly affordable price. This service will enable your business to create loads of calls at least charges. This way your business will get cost benefits and can also develop the potential of remain connected with your customers to yield maximum results.</p>	
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="OS-serviceBox" id="Process_Outsourcing">
                                <div className="DIS-inner-box">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="WWA-right text-align-left">
                                                <img className="WWA-right-imgdefault" src="images/process-outsourcing-banner.svg" alt="" />
                                            </div>	
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            <div className="WWA-left product-service-box p-0">
                                                <div className="custom-heading left-heading">
                                                    <label>Process Outsourcing</label>
                                                    <div className="custom-heading-text">Back Office Outsourcing Services For Long-term Growth</div>
                                                </div>
                                                <p>Our Back office BPO service will help you to accomplish your non-client-facing services through our professional team. We offer variety of Back office BPO services that includes accounting, HR, IT, supply chain, internal communications, and many more.</p>

                                                <p>Our flexible business solutions and unique offerings will assist you to increase your business expansion while creating long run sustainability.</p>

                                                <p>As a business operations partner, we combine our distinctive expertise to empower your business operating models. It will help transforming your operations while stay relevant. It will flourish your business and maximize the chances of availing new opportunities.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="service-quick-sec mt100">
                                    <div className="service-quick-area d-flex">
                                        <div className="col-4 solution-inner-box">
                                            <a href="">
                                                <div className="SIB-solution-img">
                                                    <img src="images/solution-app-development.svg" alt="" />
                                                </div>
                                                <div className="SIB-solution-content">
                                                    <div className="SIB-solutionHead">Cognitive Automation</div>
                                                    <p>We use next-gen Artificial Intelligence, Machine Learning and Computer Vision techniques to provide you end to end enterprise automation service.</p>
                                                </div>
                                                <div className="banner-btn-area">
                                                    <div className="custom-btn">Learn More</div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-4 solution-inner-box">
                                            <a href="">
                                                <div className="SIB-solution-img">
                                                    <img src="images/solution-team-development.svg" alt="" />
                                                </div>
                                                <div className="SIB-solution-content">
                                                    <div className="SIB-solutionHead">Dedicated Development Team</div>
                                                    <p>Our team has expert personnel who are dedicated to offer you result oriented service.</p>
                                                </div>
                                                <div className="banner-btn-area">
                                                    <div className="custom-btn">Learn More</div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-4 solution-inner-box">
                                            <a href="">
                                                <div className="SIB-solution-img">
                                                    <img src="images/outsourcing-services-icon-support.svg" alt="" />
                                                </div>
                                                <div className="SIB-solution-content">
                                                    <div className="SIB-solutionHead">Automated Product Support</div>
                                                    <p>We focus on next-generation technologies and time-to-time innovate products and services according to the contemporary market need.</p>
                                                </div>
                                                <div className="banner-btn-area">
                                                    <div className="custom-btn">Learn More</div>
                                                </div>
                                            </a>
                                        </div>							
                                    </div>
                                    <div className="service-quick-area d-flex">
                                        <div className="col-4 solution-inner-box">
                                            <a href="">
                                                <div className="SIB-solution-img">
                                                    <img src="images/outsourcing-services-icon-business.svg" alt="" />
                                                </div>
                                                <div className="SIB-solution-content">
                                                    <div className="SIB-solutionHead">Future-Ready Business</div>
                                                    <p>We use modern operating model that will help you to increase the speed and efficiency of your business process.</p>
                                                </div>
                                                <div className="banner-btn-area">
                                                    <div className="custom-btn">Learn More</div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-4 solution-inner-box">
                                            <a href="">
                                                <div className="SIB-solution-img">
                                                    <img src="images/outsourcing-services-icon-businessValue.svg" alt="" />
                                                </div>
                                                <div className="SIB-solution-content">
                                                    <div className="SIB-solutionHead">Enhancing Business Value</div>
                                                    <p>We use tools and assets that will help you to decrease service disruption for our clients.</p>
                                                </div>
                                                <div className="banner-btn-area">
                                                    <div className="custom-btn">Learn More</div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-4 solution-inner-box">
                                            <a href="">
                                                <div className="SIB-solution-img">
                                                    <img src="images/outsourcing-services-icon-businessEfficiency.svg" alt="" />
                                                </div>
                                                <div className="SIB-solution-content">
                                                    <div className="SIB-solutionHead">Improving Business Efficiency</div>
                                                    <p>As we help you in accomplishing daily routine tasks of your business, so you will get more time to emphasize on new product development.</p>
                                                </div>
                                                <div className="banner-btn-area">
                                                    <div className="custom-btn">Learn More</div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="OS-serviceBox" id="Back_office_Outsourcing">
                                <div className="DIS-inner-box">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="WWA-left product-service-box p-0">
                                                <div className="custom-heading left-heading">
                                                    <label>Back office Outsourcing</label>
                                                    <div className="custom-heading-text">Accelerate Your Business after the Pandemic</div>
                                                    <p>For bold enterprises, there are turbulent times opportunities have created after the pandemic. There is an opportunity to leapfrog the competition while making strategic moves. We are here to collaborate with you to accelerate your business speed and enable you to deal uncertainty with confidence. We will remain there with you to anticipate what's next so that quick action can be taken by your business to maximize business growth in the situation of crisis.</p>
                                                    <ul>
                                                    <li><FiCheckCircle className="yellowCheck" /> Modern Operations</li>
                                                    <li><FiCheckCircle className="yellowCheck" /> Automation Services</li>
                                                    <li><FiCheckCircle className="yellowCheck" /> Accelerators & Enablers</li>
                                                    <li><FiCheckCircle className="yellowCheck" /> Transforming Operations</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            <div className="WWA-right">
                                                <img className="WWA-right-imgdefault" src="images/back-office-outsourcing-banner.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="what-do-inner mt-5">
                                        <div className="what-do-box">
                                            <div className="what-doBox-img"><img src="images/what-we-do-icon1.svg" alt="" /></div>
                                            <h3>Highly Experienced Staff</h3>
                                            <p>Our team members apply intelligence, deep industry experience, and IT skills to drive business value and growth.</p>
                                        </div>

                                        <div className="what-do-box">
                                            <div className="what-doBox-img"><img src="images/what-we-do-icon2.svg" alt="" /></div>
                                            <h3>Innovative Designs</h3>
                                            <p>We involve operational transformation in business process while involving technology innovation and agile talent to create future-ready intelligent operations.</p>
                                        </div>

                                        <div className="what-do-box">
                                            <div className="what-doBox-img"><img src="images/what-we-do-icon3.svg" alt="" /></div>
                                            <h3>Customized Strategies</h3>
                                            <p>Our Experts offer customized responses to your customers in a most efficient manner. We design operations strategies that can best fit your business need.</p>
                                        </div>

                                        <div className="what-do-box">
                                            <div className="what-doBox-img"><img src="images/what-we-do-icon4.svg" alt="" /></div>
                                            <h3>Result-Driven Solutions</h3>
                                            <p>We offer you result-driven solutions while leveraging cost benefits and connecting with your customers to boost your business with maximum results.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
            </section>	

            
            <section className="mini-quote-sec">
                <div className="container">
                    <div className="mini-quote-area">
                        <div className="mini-quote-box">
                            <div className="mini-quote-info text-right">
                                <div className="mini-quoteInfo-heading">Get In Touch:</div>
                                <div className="mini-quoteInfo-info">Biz@ezzeex.com</div>
                            </div>
                            
                            <div className="mini-quote-or">or</div>
                            
                            <div className="mini-quote-info">
                                <div className="mini-quoteInfo-heading">Call Us Via:</div>
                                <div className="mini-quoteInfo-info">+91 82229 62226</div>
                            </div>
                            
                            <div className="mini-quote-shape">
                                <img src="images/round-dots-black.svg" alt="" className="miniQuote-shape1" />
                                <img src="images/get-mini-touch-blueShape.svg" alt="" className="miniQuote-shape2" />
                                <img src="images/round-dots-orange.svg" alt="" className="miniQuote-shape3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />
            
        </>
    )
}   
export default OutsourcingServicesArea;  