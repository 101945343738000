import React from 'react';
import {NavLink} from 'react-router-dom';

import 'atropos/css'
import Atropos from 'atropos/react';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import CompanyCounterArea from './Common/companyCounter'
import TestimonialArea from './Common/testimonial'


import { FiCheckCircle } from "react-icons/fi";




const HomeComponets = () => {
    const handleClick = () => {
        window.scrollTo(0,0);
    }

    return (
        <>
            <section className="banner-area">	
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <div className="banner-content">
                                <div className="heading-label">India's Most Trusted</div>
                                <h1>Unifying Tech And Biz At Your Call!</h1>
                                <p>Achieve your milestones in no time with EzzEEx. We develop digital solutions that transform the face of technology globally. Our experience spans a list of diverse clientele.</p>
                                <p>As a well-established company in the IT field, we have mastered the art of creating optimal solutions, innovative technologies and hassle-free processes. With us, you can step into the market and establish your business effortlessly.</p>
                                <p>Get ready to spearhead through all your needs and establish your enterprise on the world stage!</p>
                                
                                <div className="banner-btn-area">
                                    <NavLink to="/aboutUs" onClick={handleClick} className="custom-btn btn-primary">Discover More</NavLink>
                                    <a href="#Free_quote" className="arrow-btn"><img src="images/btn-arrow.svg" alt="" /> Get a Free Quote</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-12 col-lg-6">
                            <div className="banner-img-area webBanner_home">
                                <div id="app">
                                    <Atropos className="my-atropos" shadowScale={0}>
                                        <img src="images/banner.svg" alt=""  data-atropos-offset="10" />
                                        <img src="images/banner-item-1.svg" className="bannerItem1" alt=""  data-atropos-offset="-5" />
                                        <img src="images/banner-item-1.svg" className="bannerItem2" alt=""  data-atropos-offset="-5" />
                                        <img src="images/banner-item-2.svg" className="bannerItem3" alt=""  data-atropos-offset="0" />
                                        <img src="images/banner-item-3.svg" className="bannerItem4" alt=""  data-atropos-offset="5" />
                                    </Atropos>
                                </div>
                            </div>
                            <div className="mobileBanner_home">
                                <img src="images/home-bannerMobile.svg" alt=""  />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="quick-view-area">
                <div className="container">
                    <div className="quick-view">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="quick-view-box">
                                    <div className="QVB-img">
                                        <img src="images/quick-service-icon1.svg" alt="" />	
                                    </div>
                                    <div className="QVB-text">
                                        Services that will scale up your organic growth.
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4 col-md-12">
                                <div className="quick-view-box">
                                    <div className="QVB-img">
                                        <img src="images/quick-service-icon2.svg" alt="" />	
                                    </div>
                                    <div className="QVB-text">
                                        Capitalize your goals using quality, quantity and effective planning
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4 col-md-12">
                                <div className="quick-view-box">
                                    <div className="QVB-img">
                                        <img src="images/quick-service-icon3.svg" alt="" />	
                                    </div>
                                    <div className="QVB-text">
                                        Results that will build trustable rapport.
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section className="welcome-website-area">
                <div className="container">
                    <div className="welcome-website">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="WW-content-area">
                                    <div className="WWCA-label">WELCOME TO OUR WEBSITE!</div>
                                    <div className="WWCA-heading">The spotlight light is yours because the <span>backend is ours!</span> </div>
                                </div>
                                <div className="WW-website-img">
                                    <img src="images/about-banner.svg" alt="" />
                                </div>
                            </div>
                            
                            <div className="col-lg-6 col-md-12">
                                <div className="WW-text-area">
                                    <p>At EzzEEx, we aim to deliver whatever you want. We offer a wide range of services to meet the diverse needs of our clients. Our expertise is open for large-scale as well as small-scale businesses equally.</p>
                                    <p>We remain highly focus to establish clear line of communication on both ends. It is our motive to create a service that ticks off all the requirements. Being a part of an ever-expanding domain, we understand the need for change in the right direction.</p>
                                    <div className="WWTA-quote mb-4">
                                        <label><img src="images/quote-yellow.svg" alt="" /></label>
                                        Entrust us with your goals and watch your business skyrocket.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-service-area">
                <div className="container">
                    <div className="custom-heading">
                        <label>OUR SERVICES</label>
                        <div className="custom-heading-text">Multi-dimensional services that deliver excellence</div>
                        <p>Browse through our expertly handled list of services that will help you navigate and achieve your goals. The technological ground is being updated daily, and we are here to help you grow with it.</p>
                    </div>
                    
                    <div className="OS-box-area">
                            
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/web-development-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">Web Development</div>
                                    <div className="OSB-box-text">Web development has found its place in the market and will keep growing exponentially in the upcoming years. A hand-picked team will work on delivering exactly what you want. Consider how the backend section is handled, and get an eye-grabbing frontend as a well-functioning combo</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                           
                            
                            
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/mobile-app-dev-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">App Development</div>
                                    <div className="OSB-box-text">Designing has become essential to attract the target audience towards your product and service. Another part is maintenance. Expect your stress to vanish when we burn the midnight oil for you! Finding solutions is our top priority. We work on the latest and existing tech simultaneously. Get in touch with us anytime for your software and mobile application issues.</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                           
                            
                            
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/digital-market-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">Full stack development</div>
                                    <div className="OSB-box-text">Technology is moving at a very fast pace. New ideas require the development of new products. Contact us, and we will assist you in completing the software application. Full-stack development is a game changer. With the front and backend components being developed in connection with each other, expect the connectivity of the components to be smooth while functioning. Also, what's better than having both the client and server sides happy?</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                            
                            
                            
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/ui-ux-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">UI/UX design</div>
                                    <div className="OSB-box-text">The user interface (UI) is where we will create a seamless experience for your audience. Seamless as a feature is guaranteed from our side. Convert them into leads with the help of our efficient user experience (UX)! All your positive aspects will be highlighted. Let us create the mind map of your website or mobile app for the end user to enjoy!</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                          
                            
                            
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/writing-and-editiong-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">Digital Marketing</div>
                                    <div className="OSB-box-text">Today, every business requires an online presence. Offline business is significantly influenced by your online presence. We will help you to craft the right digital marketing strategy. With our years of experience in the market, leverage your social media, online advertising, and email marketing to get the maximum output. Don't be shocked if you find your business skyrocketing in due time!</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                           
                                        
                           
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/maintenace-and-management-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">Online tutoring</div>
                                    <div className="OSB-box-text">One must keep developing new skills. Technology is changing rapidly. Online tutoring is here to help everyone. Get personalized tutorials delivered to your device. The tutors are well-learned and selected after a rigorous selection process to ensure quality service. Consider your time and money as an investment in us. Do you prefer live classes over recorded sessions? Or is it vice versa? No matter what it is, we will arrange it for you..</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                           
                            
                            
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/dedicated-dev-team-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">Content development</div>
                                    <div className="OSB-box-text">Our skilled and experienced writers understand your needs and requirements in depth. But what makes their material special? The content is crafted to entertain the existing audience and to invite potential customers who are currently purchasing from the competitor brands. We provide material for a variety of purposes, such as promoting a business, educating an audience, or entertaining viewers.</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                           
                            
                            
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/learning-and-internship-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">Editing and proofreading</div>
                                    <div className="OSB-box-text">We give your content a detailed overview to ensure there are no major faults or drawbacks. Our team of creative writers will revamp it. The content will be structured according to its purpose. Using the correct writing style and words, the reader will understand your message. Proofreading is a crucial step for us. Only what is required is put into the final draft.</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                           
                            
                           
                                <div className="our-service-box">
                                    <div className="OSB-box-img"><img src="images/testing-qa-service-icon.svg" alt="" /></div>
                                    <div className="OSB-box-heading">Outsourcing services</div>
                                    <div className="OSB-box-text">Outsourcing has become quite popular. And why wouldn't it be? It helps the individual or company focus on the core requirements. Delegate your work to hard-working professionals and organizations. Only dedicated and well-established firms make it to our list. Let us be your point of contact and handle lower-priority tasks for you.</div>
                                    <div className="OSB-arrow-hover"><img src="images/services-arrow-icon.svg" alt="" /></div>
                                </div>
                           
                            
                        
                        
                    </div>
                    
                </div>
            </section>

            <section className="work-process-section">
                <div className="container">
                    <div className="custom-heading">
                        <label>Our Work Process</label>
                        <div className="custom-heading-text">An in-depth view of our work life cycle</div>
                        <p>We keep our communication channels crystal clear to provide the best service possible. Our transparent workflow will help you understand the different approaches we take to provide an optimized result.</p>
                    </div>
                    
                    <div className="work-process-area">
                        <div className="WPA-follor-content">
                            <h5>We Follow</h5>
                            <h4>AGILE METHODOLOGY</h4>
                        </div>
                        
                        <div className="work-processBox WPB-one">
                            <div className="WPB-img"><img src="images/follow-step-icon1.svg" alt="" /></div>
                            <div className="WPB-text">
                                <div className="WPB-text-connectorImg"><img src="images/follow-step-connector1.svg" alt="" /></div>
                                <div className="WPB-text-count">1</div>
                                <div className="WPB-text-heading">Assessment</div>
                                <div className="WPB-text-text">The first step in our project life cycle is to assess the client's immediate needs.</div>
                            </div>
                        </div>
                        
                        <div className="work-processBox WPB-two">
                            <div className="WPB-img"><img src="images/follow-step-icon2.svg" alt="" /></div>
                            <div className="WPB-text">
                                <div className="WPB-text-connectorImg"><img src="images/follow-step-connector2.svg" alt="" /></div>
                                <div className="WPB-text-count">2</div>
                                <div className="WPB-text-heading">Strategy</div>
                                <div className="WPB-text-text">After assessing the client's technology needs, the next step is to develop a strategy to address those needs.</div>
                            </div>
                        </div>
                        
                        <div className="work-processBox WPB-three">
                            <div className="WPB-img"><img src="images/follow-step-icon3.svg" alt="" /></div>
                            <div className="WPB-text">
                                <div className="WPB-text-connectorImg"><img src="images/follow-step-connector3.svg" alt="" /></div>
                                <div className="WPB-text-count">3</div>
                                <div className="WPB-text-heading">Implementation</div>
                                <div className="WPB-text-text">The next step is to implement the IT services which include installing new hardware and software.</div>
                            </div>
                        </div>
                        
                        <div className="work-processBox WPB-four">
                            <div className="WPB-img"><img src="images/follow-step-icon4.svg" alt="" /></div>
                            <div className="WPB-text">
                                <div className="WPB-text-count">4</div>
                                <div className="WPB-text-heading">Maintenance and Support</div>
                                <div className="WPB-text-text">Here maintenance and support are ensured, this includes providing updates, analysis, consultation, and technical support to clients as needed.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CompanyCounterArea />

            <section className="product-services-sec">
                <div className="container">
                    <div className="custom-heading">
                        <label>Product / Services</label>
                        <div className="custom-heading-text">Professional curated services and support for clients</div>
                        <p>We believe in providing the best service at the right time as in today’s market, time is essential. Hence, efficiency with the right support will put you in the spotlight. Give the options and overview, because you name it we got it!</p>
                    </div>
                    
                    <Tab.Container id="left-tabs-example" defaultActiveKey="nav-home-tab">
                        <Row>
                            <Col lg={6} md={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="nav-home-tab">
                                    <div className="product-service-box">
                                        <h3>Software Development</h3>
                                        <p>It is based on the fundamentals of software design and development. Hence, we make it our top priority to provide supreme quality services involving creating and maintaining technical projects or products. We cater to a variety of clients and industries. We get upfront with the clients to understand their needs and create software solutions that are tailored to their specific requirements. solid understanding of programming languages, software development methodologies, and industry trends, as well as the ability to communicate effectively with clients, and manage development projects and timelines. With our hands-on experience in the field, we assure you guaranteed results.</p>
                                        <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> CUSTOM WEB APPLICATION DEVELOPMENT</li>
                                            <li><FiCheckCircle className="yellowCheck" /> NATIVE MOBILE APPLICATION DEVELOPMENT</li>
                                            <li><FiCheckCircle className="yellowCheck" /> ENTERPRISE SOFTWARE SOLUTIONS</li>
                                            <li><FiCheckCircle className="yellowCheck" /> SOFTWARE QA &amp; TESTING SERVICES</li>
                                        </ul>
                                    </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="nav-profile-tab">
                                        <div className="product-service-box">
                                            <h3>Research and writing</h3>
                                            <p>Creating content is easy but creating relevant content is not everyone’s cup of tea. However for us, it is our favourite. If we had to pick one section that would keep earning you capital then it is quality content and we are here to help you out with it! Our team of writers have a clear and precise view of what needs to be created. A detailed meeting helps in identifying the particular and accurate content demand for your business. They can be articles, blog posts, web content, brochures, e-books, white papers, and more. We believe in customization. So if you require copywriting, technical writing, editing, or proofreading services then you are covered.</p>
                                            <ul>
                                                <li><FiCheckCircle className="yellowCheck" /> Exceptional Academic Research and Writing </li>
                                                <li><FiCheckCircle className="yellowCheck" /> Web Content / Brand / Blog / SEO content writing </li>
                                                <li><FiCheckCircle className="yellowCheck" /> Online Presence/ cv-profile writing / Business Writing </li>
                                                <li><FiCheckCircle className="yellowCheck" /> Programming assistance and Technical writing</li>
                                            </ul>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="nav-contact-tab">
                                    <div className="product-service-box">
                                        <h3>On-Demand Teams</h3>
                                        <p>Providing outsourcing services involves working with a client to handle specific tasks or projects that are typically performed by the client's in-house staff. It is seen as a helpful addition as it gives companies the ability to focus on core developments and focus growth accordingly. Multiple companies are dedicated to handle lower-priority tasks and carry them out well. For any IT venture to grow, outsourcing has become important. Let us help you out in getting the services that involve us providing trustable outsourcing in software development, network administration, cybersecurity, etc. We only assign projects to individuals and companies with experience and proper knowledge of the domain</p>
                                        <ul>
                                            <li><FiCheckCircle className="yellowCheck" /> Language/time zone compatibility</li>
                                            <li><FiCheckCircle className="yellowCheck" /> 100+ Skills Available</li>
                                            <li><FiCheckCircle className="yellowCheck" /> 2 Weeks Risk-Free Trial</li>
                                            <li><FiCheckCircle className="yellowCheck" /> Effective Collaboration</li>
                                        </ul>
                                    </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="onlineTutoring-tab">
                                        <div className="product-service-box">
                                            <h3>Digital Marketing</h3>
                                            <p>When it comes to Digital Marketing Services, multiple modes of action are used for promoting a business or an organization. Our entire approach revolves around meeting the demands of our clients quickly and organically. The all-rounded approach includes managing SEO, and SEM. Using email marketing campaigns to get in touch with interested people and
                the ones that would turn out to be profitable for the growth. Other modes include pay-per-click, advertising, content marketing and many more. Typically this involves a combination of strategy, creativity, and analysis. We begin by working with a client to understand their business goals and target audience, and then creating a customized strategy to help them reach those goals.</p>
                                            <ul>
                                                <li><FiCheckCircle className="yellowCheck" /> Search Engine Optimization (SEO)</li>
                                                <li><FiCheckCircle className="yellowCheck" /> Social Media Marketing (SMO)</li>
                                                <li><FiCheckCircle className="yellowCheck" /> Online Reputation Management (ORM)</li>
                                                <li><FiCheckCircle className="yellowCheck" /> Pay Per Click (PPC) Management</li>
                                            </ul>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="softDesign-tab">
                                        <div className="product-service-box">
                                            <h3>Python Courses, Training &amp; Certifications</h3>
                                            <p>Python has proved its vitality to the world. Which is why it is slowly, being integrated into the technical backend quickly. Hence, professionals and amateurs must get trained in it.</p>
                                            <p>Our Python learning course is a comprehensive program that covers all aspects of the Python programming language, from the basics to more advanced topics. It is aimed at providing a solid foundation in Python programming concepts and techniques to individuals with little or no prior programming experience. <strong>Here are some of the key topics that a full Python learning course might cover:)</strong></p>
                                            <ul>
                                                <li><FiCheckCircle className="yellowCheck" /> The Python basics will include learning the syntax, data types, and basic programming concepts such as variables, loops, and control flow.</li>
                                                <li><FiCheckCircle className="yellowCheck" /> Object-Oriented Programming (OOP) includes understanding the principles of OOP and how to use classes and objects in Python.</li>
                                                <li><FiCheckCircle className="yellowCheck" /> Data structures are all about learning about lists, dictionaries, sets, and other data structures, and how to use them in Python.</li>
                                                <li><FiCheckCircle className="yellowCheck" /> You will learn how to use existing Python modules and libraries to perform various tasks, such as file I/O, data processing, and working with web data.</li>
                                                <li><FiCheckCircle className="yellowCheck" /> Also understand how to read and write files, and how to manipulate and process files using Python.</li>
                                                <li><FiCheckCircle className="yellowCheck" /> By the end, you will be able to handle errors and exceptions in Python and how to write robust and stable code. </li>
                                                <li><FiCheckCircle className="yellowCheck" /> The course will also touch up and delve into multithreading, networking, web scraping, machine learning, and data visualization.</li>
                                            </ul>
                                            <p>To give everything a perfect wrap-up, the course has been designed around creating a project! This will help in implementing all the features that have been mentioned above and make you industry ready!</p>
                                        </div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>

                            <Col lg={6} md={12}>
                                <div className="PSA-tab-box">
                                    <Nav variant="pills" className="flex-column">
                                        <div className="PSA-tab-flex"> 
                                            <Nav.Item>
                                                <Nav.Link eventKey="nav-home-tab">
                                                    <div className="PSA-tab-img">
                                                        <img src="images/product-services-1.svg" alt="" />
                                                    </div>
                                                    <span>Software <br/>Development</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="nav-profile-tab">
                                                    <div className="PSA-tab-img"><img src="images/product-services-2.svg" alt="" /></div>
                                                    <span>Research and <br/>writing</span> 
                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>
                                        <div className="PSA-tab-flex"> 
                                            <Nav.Item>
                                                <Nav.Link eventKey="nav-contact-tab">
                                                    <div className="PSA-tab-img"><img src="images/product-services-3.svg" alt="" /></div>
                                                    <span>On-Demand <br/>Teams</span>  
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="onlineTutoring-tab">
                                                    <div className="PSA-tab-img"><img src="images/product-services-4.svg" alt="" /></div>
                                                    <span>Digital <br/>Marketing</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>
                                        <div className="PSA-tab-flex PSA-tabflex-full"> 
                                            <Nav.Item>
                                                <Nav.Link eventKey="softDesign-tab">
                                                    <div className="PSA-tab-img"><img src="images/product-services-5.svg" alt="" /></div>
                                                    <span>Python Courses, Training & Certifications </span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </div>
                                    </Nav>
                                </div>
                            </Col>
                        </Row>
                        </Tab.Container>
                </div>
            </section>

            <section className="product-services-sec">
                <div className="container">
                    <div className="custom-heading">
                        <label>Case Study</label>
                        <div className="custom-heading-text">Services at the access point</div>
                        <p>Fulfil all your technical needs with just a single click! Success is just one click away! Have the industry experts work for you instantly.</p>
                    </div>
                </div>
                <div className="case-study">
                    <div className="container">
                        <div className="row">		
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-img">
                                    <img className="case-study-imgBG" src="images/student-cabin-imgBG.png" alt="" />
                                    <img src="images/student-cabin-img-.png" alt="" />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <div className="CSC-case-logo"><img src="images/student-cabin-logo.png" alt="" /></div>
                                    <h4>On-Demand, 1-to-1 Tutoring Anytime, Anywhere</h4>
                                    <p>Customize Virtual Tutoring Spaces with hight quality video/audio calls, real-time messaging & engagement tools including Breakout Rooms & an Interactive Whiteboard.</p>
                                    <ul>
                                        <li><FiCheckCircle className="yellowCheck" /> Online Tutoring</li>
                                        <li><FiCheckCircle className="yellowCheck" /> Enterprise Training</li>
                                        <li><FiCheckCircle className="yellowCheck" /> Best-in-ClassInstructors</li>
                                        <li><FiCheckCircle className="yellowCheck" /> Weekly Assignments</li>
                                    </ul>
                                    <div className="case-study-available">
                                        <ul>
                                            <li>
                                                <a href="CSA-available-box">
                                                    <div className="CSAAB-icon">
                                                        <img src="images/playstore-icon.svg" alt="" />
                                                    </div>
                                                    <div className="CSAAB-text">
                                                        <div className="CSAAB-available">GET IT ON</div>
                                                        <div className="CSAAB-store">Google Play</div>
                                                    </div>
                                                </a>
                                            </li>
                                            
                                            <li>
                                                <a href="CSA-available-box">
                                                    <div className="CSAAB-icon">
                                                        <img src="images/appstore-icon.svg" alt="" />
                                                    </div>
                                                    <div className="CSAAB-text">
                                                        <div className="CSAAB-available">AVAILABLE ON THE</div>
                                                        <div className="CSAAB-store">App Store</div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TestimonialArea />


        </>
    )
}   
export default HomeComponets;  